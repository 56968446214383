import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonRow,
} from "@ionic/react"
import { COLORS } from "../utils/constants"
import { arrowBackOutline } from "ionicons/icons"
import { Link, useHistory } from "react-router-dom"
import { useDropzone } from "react-dropzone"
import React, { useEffect, useMemo } from "react"
import { Button } from "react-bootstrap"
import axios from "axios"
import { ApiUrl } from "../utils/http/http"
import Swal from "sweetalert2"

const baseStyle = {
  width: 462,
  height: 149,
  border: "3.41667px dashed rgba(0, 0, 0, 0.2)",
  boxSizing: " border-box",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: 14,
  lineHeight: 24,
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  color: "#0087CA",
  marginBottom: 20,
  padding: 50,
}

const activeStyle = {
  borderColor: "#2196f3",
}

const acceptStyle = {
  borderColor: "#00e676",
}

const rejectStyle = {
  borderColor: "#ff1744",
}

const AddAlbum = () => {
  const history = useHistory()
  const [files, setFiles] = React.useState([])
  const [title, setTitle] = React.useState("")
  const [loading, setLoading] = React.useState(true)
  let userInfos = localStorage.getItem("userInfos")

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles])
    },
    [files]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png, image/jpg , image/gif, image/x-icon",
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const handleDeleteImage = (file) => {
    const newFile = [...files]
    setFiles(newFile.filter((i) => i !== file))
  }

  useEffect(
    () => () => {
      files.forEach(() => URL.revokeObjectURL(URL.createObjectURL.files))
    },
    [files]
  )

  const handlePictures = (e) => {
    setTitle(e.detail.value)
  }

  const sendImg = (e) => {
    e.preventDefault()
    const shop = userInfos.id_boutique
    const formData = new FormData()
    for (let file of files) {
      formData.append("pictures", file)
    }
    formData.append("title", title)
    axios
      .post(ApiUrl + "/albums/", shop, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        console.log("result", response.data)
        setLoading(false)
        Swal.fire({
          icon: "success",
          confirmButtonColor: "#1ee172",
          timer: 1000,
        })
        history.push("/dashboard/modeles")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const thumbs = files.map((file) => (
    <div key={file.name} style={{ display: "flex", flexDirection: "row" }}>
      <img
        name="pictures"
        style={{
          height: "auto",
          width: 300,
          margin: 7,
        }}
        src={URL.createObjectURL(file)}
        alt={file.name}
      />
      <button
        onClick={() => handleDeleteImage(file)}
        style={{
          height: 30,
          background: "red",
          color: "white",
          width: 40,
          fontWeight: 600,
          margin: 7,
        }}
      >
        -
      </button>
    </div>
  ))

  return (
    <IonContent
      style={{
        padding: 0,
        "--background": COLORS.gray,
      }}
    >
      <IonGrid
        style={{
          padding: "0 20px",
          height: "70vh",
          background: "#F4F4F4",
          marginTop: 70,
        }}
      >
        <IonRow>
          <IonCol
            style={{
              background: "#FFF",
              padding: "0 10px",
              borderBottom: "1px solid rgba(0,0,0,0.2)",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
              height: 42,
            }}
            sizeSm={12}
            sizemd={6}
            sizeLg={5}
            sizeXl={4}
            className="d-flex align-items-center mt-2"
          >
            <Link
              to="/dashboard/modeles"
              style={{ color: "#000", cursor: "pointer", position: "relative" }}
            >
              <IonIcon
                icon={arrowBackOutline}
                style={{
                  fontSize: "20px",
                  marginBottom: 0,
                  paddingBottom: 0,
                }}
              ></IonIcon>
            </Link>
          </IonCol>
        </IonRow>
        <IonRow
          style={{
            background: "#F4F4F4",
            marginTop: "10px",
            height: "70vh",
          }}
        >
          <IonCol
            style={{
              background: "#FFF",
              padding: "0 25px",
            }}
            sizeSm={12}
            sizemd={6}
            sizeLg={5}
            sizeXl={4}
            className="justify-content-start mt-3"
          >
            <h6>Créer un album</h6>
            <div className="col-12 p-0" style={{ width: 311 }}>
              <IonInput
                placeholder="Nom de l'album"
                type="text"
                id="title"
                name="title"
                value={title}
                onIonChange={(e) => handlePictures(e)}
                style={{
                  width: "100",
                  height: "49px",
                  border: "1px solid #C4C4C4",
                  borderRadius: "5px",
                  "--padding-start": "20px",
                  marginTop: "23px",
                }}
              />
              <Button
                className="btn"
                loading={loading}
                style={{
                  fontWeight: "bold",
                  fontSize: 14,
                  color: "#FFF",
                  width: "100%",
                  height: 50,
                  borderRadius: 5,
                  margin: "50px 0",
                  textTransform: "none",
                  backgroundColor: COLORS.redS,
                  "--background-hover": "#fff",
                }}
                onClick={(e) => sendImg(e)}
              >
                Enregistrer
              </Button>
            </div>
          </IonCol>
          <IonCol
            sizeSm={12}
            sizemd={6}
            sizeLg={7}
            sizeXl={8}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <div
                  style={{
                    cursor: "pointer",
                    width: "inherit",
                    height: "inherit",
                    left: 0,
                    position: "absolute",
                    right: 0,
                    margin: "auto",
                    display: "contents",
                  }}
                >
                  <svg
                    width="100%"
                    height="100"
                    viewBox="0 0 80 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    // style={{ boxShadow: "2px 2px 2px 1px  #A29E9E" }}
                  >
                    <g clipPath="url(#clip0_1088_432)">
                      <rect
                        width="79.3846"
                        height="64.5"
                        rx="6.20192"
                        fill="#777879"
                      />
                      <path
                        d="M23.8492 23.5815L0.9021 64.7255H80.2867L79.6665 23.5815L61.6809 8.79541L41.2146 37.7248L23.8492 23.5815Z"
                        fill="#F8F8F8"
                      />
                      <circle
                        cx="16.2378"
                        cy="14.2078"
                        r="4.51049"
                        fill="#3686D7"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1088_432">
                        <rect width="79.3846" height="64.5" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <p>Ajoutez ou glissez votre image dans ce carré !</p>
              <aside>{thumbs}</aside>
            </section>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default AddAlbum
