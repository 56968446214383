import {
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react"
import { Link } from "react-router-dom"
import { useState } from "react"

function ComptabiliteCard() {
  const [count, setCount] = useState([0])

  // useEffect(() => {
  //   axios({
  //     method: "GET",
  //     url: ApiUrl + "/expense",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       "Content-Type": "application/json",
  //     },
  //   }).then((res) => {
  //     const expenses = res.data.results
  //       .map((result) => result.price)
  //       .reduce((count, result) => result + count * result.price);
  //     console.log(expenses, "Voici le total");

  //     //   setCount(expenses);
  //   });
  // });

  return (
    <IonCard className="cartes-dashboard ion-justify-content-center ion-align-items-center">
      <Link to="/dashboard/comptabilite" className="lien-detail-commande">
        <IonCardContent className="d-flex ion-justify-content-between ion-align-items-start data">
          <svg
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0166 33.8015L20.8736 23.5898L29.8358 30.6299L37.5246 20.7065"
              stroke="#BC196C"
              strokeWidth="1.59265"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <ellipse
              cx="46.4864"
              cy="6.02599"
              rx="5.04576"
              ry="5.04577"
              stroke="#BC196C"
              strokeWidth="1.59265"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M33.1757 3.19031H14.0979C6.19286 3.19031 1.29126 8.7887 1.29126 16.6937V37.91C1.29126 45.815 6.09675 51.3894 14.0979 51.3894H36.6837C44.5887 51.3894 49.4903 45.815 49.4903 37.91V19.4329"
              stroke="#BC196C"
              strokeWidth="1.59265"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div>
            <IonCardSubtitle className="titre-carte">
              Comptabilité
            </IonCardSubtitle>
            <IonCardTitle className="title-card">
              <span className="frc-cfa">FCFA</span>
              23423
            </IonCardTitle>
          </div>
        </IonCardContent>
      </Link>
    </IonCard>
  )
}

export default ComptabiliteCard
