import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonRow,
  IonCardContent,
} from "@ionic/react"
import { useEffect, useState } from "react"
import { COLORS } from "../utils/constants"
import { useHistory } from "react-router-dom"
import { Modal } from "react-bootstrap"
import { Link } from "react-router-dom"
import { close } from "ionicons/icons"
import axios from "axios"
import { ApiUrl, baseBackendUrl } from "../utils/http/http"
import "./Modeles.css"
import ImageGridSkeleton from "../skeleton/GalerieImages"

const Modeles = () => {
  const History = useHistory()
  const [showModal, setshowModal] = useState(false)
  const [pictures, setPictures] = useState([])
  const [albums, setAlbums] = useState([])
  const [showPhoto, setShowPhoto] = useState(true)
  const [loading, setLoading] = useState(true)
  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  const handleImageChange = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      )
      setPictures((prevImages) => prevImages.concat(filesArray))
      Array.from(e.target.files).map((file) => URL.revokeObjectURL(file))
    }
  }

  const handleOpen = (index) => {
    setPictures(index)
    setshowModal(true)
  }

  const handleClose = () => {
    setshowModal(false)
  }

  useEffect(() => {
    fetchImage()
    fetchAlbums()
    showPhoto
      ? (document.title = "Tayeur-Modèles")
      : (document.title = "Tayeur-Album")
  }, [])

  const fetchImage = () => {
    axios
      .get(ApiUrl + "/albums/", config)
      .then((response) => {
         console.log("responseAlbum", response.data)
        setTimeout(() => {
          setLoading(false)
        }, 3000)
        setAlbums(response.data)
        setPictures(
          response.data.reduce((acc, album) => {
            return [...acc, ...album.pictures]
            
          }, [])
        )
      })
      .catch((error) => {
        console.log("error", error)
        setLoading(false)
      })
      
  }

  const fetchAlbums = () => {
    axios
      .get(ApiUrl + "/albums/" + userInfos.id_boutique + "/", config)
      .then((response) => {
        console.log("response", response.data)
        setTimeout(() => {
          setLoading(false)
        }, 5000)
        setAlbums(response.data)
        setPictures(
        //   response.data.reduce((acc, album) => {
        //     return [...acc, ...album.pictures]
        //   }, [])
        response.data
         )
      })
      .catch((error) => {
        console.log("error", error)
        setLoading(false)
      })
  }

  return (
    <IonContent
      style={{
        padding: 0,
        "--background": COLORS.gray,
      }}
    >
      <IonGrid
        style={{
          padding: "20px",
          marginTop: "20px",
        }}
      >
        <IonRow
          style={{
            background: "#FFF",
            padding: "0 30px",
            borderBottom: "1px solid rgba(0,0,0,0.2)",
          }}
        >
          <IonCol size-lg={4} size-md={4} size-sm={6} size={12}>
            <IonItem
              lines="none"
              button="true"
              onClick={() => setShowPhoto(true)}
              className={`tabs ${showPhoto && "active"}`}
            >
              <IonLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontSize: "14px",
                  lineHeight: "17.07px",
                }}
              >
                Photos
              </IonLabel>
            </IonItem>
          </IonCol>
          <IonCol size-lg={4} size-md={4} size-sm={6} size={12}>
            <IonItem
              lines="none"
              button="true"
              onClick={() => setShowPhoto(false)}
              className={`tabs ${!showPhoto && "active"}`}
            >
              <IonLabel
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontSize: "14px",
                  lineHeight: "17.07px",
                }}
                className="tabsLabel"
              >
                Album
              </IonLabel>
            </IonItem>
          </IonCol>
          <IonCol
            size-lg={4}
            size-md={4}
            size-sm={6}
            size={12}
            className="d-flex justify-content-center justify-content-sm-end mt-1"
          >
            <div className="div-parent">
              <button
                style={{
                  fontWeight: 500,
                  fontStyle: "normal",
                  fontSize: "14px",
                  lineHeight: "17.07px",
                  color: COLORS.redS,
                  width: "153px",
                  height: "37px",
                  border: "1px solid #E82828",
                  borderRadius: "5px",
                  background: "#FFF",
                  outline: "none",
                }}
                onClick={() => History.push("/dashboard/album/")}
              >
                Ajouter des photos
              </button>
              <input
                style={{
                  left: 0,
                  top: 0,
                  opacity: 0,
                  position: "absolute",
                  fontSize: "90px",
                }}
                type="file"
                name="upfile"
                onChange={handleImageChange}
              />
            </div>
          </IonCol>
        </IonRow>
        {loading && <ImageGridSkeleton />}
        {!loading && showPhoto && (
          <IonRow
            style={{
              background: "#FFF",
              padding: "25px",
              alignItem: "center",
            }}
            className="d-flex justify-content-start"
          >
            {pictures.map((image, index) => (
              <IonImg
                src={baseBackendUrl + image?.image}
                key={index}
                alt={baseBackendUrl + image?.image}
                className="imageShow m-2"
                onClick={() => handleOpen(index)}
                style={{
                  width: "300px",
                  height: "300px",
                }}
              />
            ))}
          </IonRow>
        )}

        {!showPhoto && (
          <IonRow
            style={{
              background: "#FFF",
              padding: "25px",
              alignItem: "center",
              height: "80vh",
            }}
            className="d-flex justify-content-start"
          >
            <IonCardContent
              style={{
                margin: 0,
                padding: 0,
              }}
            >
              <Link to="/dashboard/album/">
                <IonItem
                  lines="none"
                  style={{
                    margin: 0,
                    padding: 0,
                    "--inner-padding-end": 0,
                    width: "137.79px",
                    height: "200px",
                    background: "#DADADA",
                    cursor: "pointer",
                  }}
                  className="d-flex justify-content-center"
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      position: "absolute",
                      left: "25%",
                    }}
                  >
                    <path
                      d="M24 10V38"
                      stroke="#666666"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 24H38"
                      stroke="#666666"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </IonItem>
              </Link>
              <IonLabel
                style={{
                  fontWeight: "normal",
                  fontSize: "14px",
                  lineHeight: "17px",
                }}
              >
                <span>Créer un album</span>
              </IonLabel>
            </IonCardContent>

            {albums.map((album) => {
              const cover = album.pictures[0]
              return (
                <IonCardContent
                  key={album.id}
                  style={{
                    margin: 0,
                    padding: 0,
                  }}
                >
                  <IonItem
                    lines="none"
                    className=""
                    style={{
                      margin: 0,
                      padding: 0,
                      "--inner-padding-end": 0,
                    }}
                  >
                    <IonImg
                      src={baseBackendUrl + cover?.image}
                      style={{
                        width: 230,
                        height: 230,
                      }}
                    />
                  </IonItem>
                  <IonLabel
                    style={{
                      fontWeight: "normal",
                      fontSize: "14px",
                      lineHeight: "17.07px",
                    }}
                    className="pl-3"
                  >
                    {album.title}
                  </IonLabel>
                </IonCardContent>
              )
            })}
          </IonRow>
        )}
      </IonGrid>
      <Modal show={showModal} onHide={handleClose}>
        <div
          style={{
            position: "absolute",
            cursor: "pointer",
            top: "2px",
            right: "2px",
          }}
          onClick={handleClose}
        >
          <IonIcon icon={close} style={{ fontSize: "30px" }} color="danger" />
        </div>
        <IonImg src={baseBackendUrl + [pictures]} className="imgModale" />
      </Modal>
    </IonContent>
  )
}

export default Modeles
