import AddAlbum from "../pages/AddAlbum"
import AddClient from "../pages/AddClient"
import AddCommande from "../pages/AddCommande"
import Clients from "../pages/Clients"
import CommandeClient from "../pages/commande/CommandeClient"
import Commandes from "../pages/Commandes"
import ComptabilitePage from "../pages/comptabilite/ComptabilitePage"
import Dashboard from "../pages/dashboard/Dashboard"
import EditClient from "../pages/EditClient"
import Modeles from "../pages/Modeles"
import ParametrePage from "../pages/parametres/ParametrePage"
import Profile from "../pages/Profile"
import DetailClient from "../pages/commande/DetailClient"
import Stock from "../pages/stock/Stock"
import Employes from "../pages/employes/Employes"
import AllInfos from "../pages/employes/InfoEmploye/InfoEmployes"
import EditCommande from "../pages/commande/EditCommande"

const routes = [
  //! Dashboard route
  {
    path: "/dashboard",
    name: "dashboard",
    Component: Dashboard,
  },
  //! Commands routes
  {
    path: "/dashboard/commande",
    name: "commandes",
    Component: Commandes,
  },
  {
    path: "/dashboard/add-commande",
    name: "add-commande",
    Component: AddCommande,
  },
  {
    path: "/dashboard/edit-commande/:id",
    name: "",
    Component: EditCommande,
  },
  {
    path: "/dashboard/detail-commande/:id",
    name: "",
    Component: CommandeClient,
  },

  //!   Clients routes
  {
    path: "/dashboard/clients",
    name: "",
    Component: Clients,
  },
  {
    path: "/dashboard/add-client",
    name: "",
    Component: AddClient,
  },
  {
    path: "/dashboard/edit-client/:id",
    name: "",
    Component: EditClient,
  },
  {
    path: "/dashboard/detail-client/:id",
    name: "",
    Component: DetailClient,
  },

  //!   Stock routes
  {
    path: "/dashboard/stock",
    Component: Stock,
  },

  //!   Employes routes
  {
    path: "/dashboard/employes",
    Component: Employes,
  },
  {
    path: "/dashboard/employes/:id",
    Component: Employes,
  },
  //!  Info-employes routes
  {
    path: "/dashboard/informations-employes",
    Component: AllInfos,
  },
  {
    path: "/dashboard/informations-employes/:id",
    Component: AllInfos,
  },
  //!   Comptabilite routes
  {
    path: "/dashboard/comptabilite",
    Component: ComptabilitePage,
  },

  //!   Modeles routes
  {
    path: "/dashboard/modeles",
    name: "",
    Component: Modeles,
  },
  {
    path: "/dashboard/album",
    name: "",
    Component: AddAlbum,
  },

  //   User Parameters routes
  {
    path: "/dashboard/Profile",
    name: "",
    Component: Profile,
  },
  {
    path: "/dashboard/parametre-page",
    name: "",
    Component: ParametrePage,
  },
]

export default routes
