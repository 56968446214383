import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonLabel,
  IonRow,
} from "@ionic/react"
import { useState } from "react"
import { CloseButton, Form, Modal } from "react-bootstrap"
import { ValidateButton } from "../../shared/AppButton"
import "./stock.css"

const AddModele = () => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  return (
    <>
      <div>
        <IonButton
          style={{ border: "2px solid #E82828", borderRadius: 10 }}
          fill="clear"
          className="boutton"
          onClick={() => setShow(true)}
        >
          Ajouter modele
        </IonButton>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          style={{
            background: " #E82828",
          }}
        >
          <Modal.Title
            style={{
              fontSize: 18,
              color: "#FFFFFF",
            }}
          >
            Création modèle
          </Modal.Title>
          <CloseButton
            style={{
              color: "#FFF",
              borderRadius: "100%",
              border: "3px solid #FFF",
              padding: 0,
              width: 28,
              marginRight: 5,
              alignSelf: "center",
              opacity: 5,
            }}
            onClick={() => handleClose()}
          />
        </Modal.Header>
        <Modal.Body style={{ background: "#f0f0f0" }}>
          <Form.Group
            style={{
              background: "#FFFFFF",
              borderRadius: 5,
            }}
          >
            <IonGrid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IonRow className="d-flex flex-column ion-justify-content-start">
                <IonLabel>Modèle </IonLabel>
                <IonInput
                  placeholder="Nom modèle"
                  style={{
                    height: 45,
                    width: 400,
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                  }}
                ></IonInput>
              </IonRow>
            </IonGrid>
            <IonGrid className="d-flex ion-justify-content-between align-content-center flex-row">
              <IonCol>
                <IonLabel>Produit</IonLabel>
              </IonCol>
            </IonGrid>
            <IonGrid className="inputQuantite d-flex ion-justify-content-between align-content-center flex-row">
              <IonCol className="d-flex ion-justify-content-start flex-column">
                <select
                  style={{
                    width: 400,
                    height: 45,
                    overflow: "auto",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #C4C4C4",
                    borderRadius: 5,
                  }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Choisissez un tissu</option>
                  <option value="1">tissu </option>
                  <option value="1">tissu </option>
                  <option value="1">tissu </option>
                  <option value="1">tissu </option>
                </select>
              </IonCol>
              <IonCol
                className="QuatiteInput"
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <IonInput
                  placeholder="Quantité"
                  style={{
                    height: 45,
                    width: 300,
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                  }}
                ></IonInput>
              </IonCol>
            </IonGrid>
            <IonGrid className="inputQuantite d-flex ion-justify-content-between align-content-center flex-row">
              <IonCol className="d-flex ion-justify-content-start flex-column">
                <select
                  style={{
                    width: 400,
                    height: 45,
                    overflow: "auto",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #C4C4C4",
                    borderRadius: 5,
                  }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Choisissez un tissu</option>
                  <option value="1">tissu </option>
                  <option value="1">tissu </option>
                  <option value="1">tissu </option>
                  <option value="1">tissu </option>
                </select>
              </IonCol>
              <IonCol
                className="QuatiteInput"
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <IonInput
                  placeholder="Quantité"
                  style={{
                    height: 45,
                    width: 300,
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                  }}
                ></IonInput>
              </IonCol>
            </IonGrid>
            <IonGrid className="inputQuantite d-flex ion-justify-content-between align-content-center flex-row">
              <IonCol className="d-flex ion-justify-content-start flex-column">
                <select
                  style={{
                    width: 400,
                    height: 45,
                    overflow: "auto",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #C4C4C4",
                    borderRadius: 5,
                  }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Choisissez un tissu</option>
                  <option value="1">tissu </option>
                  <option value="1">tissu </option>
                  <option value="1">tissu </option>
                  <option value="1">tissu </option>
                </select>
              </IonCol>
              <IonCol
                className="QuatiteInput"
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <IonInput
                  placeholder="Quantité"
                  style={{
                    height: 45,
                    width: 300,
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                  }}
                ></IonInput>
              </IonCol>
            </IonGrid>
            <IonGrid className="d-flex ion-justify-content-between align-items-center flex-row">
              <IonCol>
                <IonLabel>Prix</IonLabel>
              </IonCol>
            </IonGrid>
            <IonGrid className="inputQuantite d-flex ion-justify-content-between align-items-center flex-row">
              <IonRow className="QuatiteInput d-flex ion-justify-content-end">
                <IonInput
                  placeholder="Prix"
                  style={{
                    height: 45,
                    width: "50%",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                  }}
                ></IonInput>
              </IonRow>
            </IonGrid>
            <IonGrid className="d-flex ion-justify-content-between align-items-center flex-row">
              <IonCol>
                <IonLabel>Nombre de modèle</IonLabel>
              </IonCol>
            </IonGrid>
            <IonGrid className="inputQuantite d-flex ion-justify-content-between align-content-between align-content-center  flex-row ">
              <IonRow className="QuatiteInput d-flex ion-justify-content-end">
                <IonInput
                  placeholder="Nombre"
                  style={{
                    height: 45,
                    width: "50%",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                  }}
                ></IonInput>
              </IonRow>
            </IonGrid>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ background: "#F0F0F0" }}>
          <IonGrid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IonButton>Enregistrer</IonButton>
          </IonGrid>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddModele
