import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonRow,
} from "@ionic/react"
import axios from "axios"
import { logOutOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import { ApiUrl } from "../../utils/http/http"
import AddEntree from "./ModalAddEntre"

function TableEntree() {
  const [dateDebut, setDateDebut] = useState("2012-12-15T13:47:20.789")
  const [dateFin, setDateFin] = useState("2012-12-15T13:47:20.789")
  const [entries, setEntries] = useState([])

  // Get entries expenses from database
  const getEntries = () => {
    axios({
      method: "GET",
      url: ApiUrl + "/expenses",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      console.log("Entries", res.data)
      const allEntries = res.data
      setEntries(allEntries)
    })
  }
  useEffect(() => {
    getEntries()
  }, [])

  // const getEntries = () => {
  //   return axios({
  //     method: "GET",
  //     url: ApiUrl + "/expense",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       "Content-Type": "application/json",
  //     },
  //   }).then((res) => {
  //     console.log("Entries", res.data)
  //     const allEntries = res.data
  //     setEntries(allEntries)
  //   })
  // }

  const columns = ["Numero facture", "Date", "Clients", "Montant"]

  return (
    <>
      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "40px 0",
        }}
      >
        <IonRow className="ion-align-items-center">
          <IonCol sizemd={6}>
            <IonButton fill="clear" className="boutton-export-csv">
              <IonIcon icon={logOutOutline} slot="start"></IonIcon>
              exporter
            </IonButton>
            <AddEntree />
          </IonCol>
          <IonCol sizemd={6} className="d-flex ion-justify-content-around">
            <div style={{ width: "40%" }}>
              <p className="date-paragraph">Date de début</p>
              <IonButton fill="clear" className="boutton-date-picker">
                <IonInput
                  type="date"
                  onIonChange={(e) => setDateDebut(e.detail.value)}
                ></IonInput>
              </IonButton>
            </div>
            <div style={{ width: "50%" }}>
              <p className="date-paragraph">Date de fin</p>
              <IonButton fill="clear" className="boutton-date-picker">
                <IonInput
                  type="date"
                  onIonChange={(e) => setDateFin(e.detail.value)}
                ></IonInput>
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "0",
        }}
      >
        <IonRow
          className="ion-align-items-center"
          style={{
            height: "70px",
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            paddingLeft: "30px",
          }}
        >
          {columns.map((column, index) => (
            <IonCol
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              key={index}
            >
              {column}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
        }}
        className="myContent"
      >
        {/* {entries.map((entry, index) => (
          <IonRow
            key={index}
            style={{
              padding: "15px 0 15px 30px",
              borderBottom: "1px solid #F5F5F5",
            }}
            className="ion-align-items-center"
          >
            <IonCol>{entry.numero_facture}</IonCol>
            <IonCol>{entry.date}</IonCol>
            <IonCol>{entry.clients}</IonCol>
            <IonCol>{entry.montant}</IonCol>
          </IonRow>
              ))} */}
              
        {entries.map((entry) => (
        <IonRow
          key={entry.id}
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>{entry.numero_facture}</IonCol>
          <IonCol>{entry.date}</IonCol>
          <IonCol>{entry.clients}</IonCol>
          <IonCol>{entry.montant}</IonCol>
        </IonRow>
      ))}
      </IonGrid>
    </>
  )
}

export default TableEntree
