const width = window.innerWidth
const height = window.innerWidth

export const DIMENSIONS = {
  width,
  height,
}

export const COLORS = {
  app: "#E72D32",
  app1: "#F4F8F7",
  orange: "#F2994A",
  redS: "#E82828",
  gray: "#F8F8F8",
  grayS: "#8E8EA1",
  grayD: "#777879",
  grayT: "#666666",
  greenP: "#086A53",
  black: "#5b5b5b",
}
