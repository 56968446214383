import React, { useEffect, useState } from "react"
import { IonButton, IonCol, IonIcon } from "@ionic/react"
import { useHistory } from "react-router-dom"
import { CloseButton, Col, Form, Image, Modal } from "react-bootstrap"
import { Toaster } from "react-hot-toast"
import userIcon from "../../images/users.svg"
import axios from "axios"
import { ApiUrl } from "../../utils/http/http"
import Swal from "sweetalert2"
import moment from "moment"

const AddEmploye = () => {
  const history = useHistory()
  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const [show, setShow] = useState(false)
  const dateDebut = useState("")
  const dateFin = useState("")
  const uploadedImage = React.useRef(null)
  const imageUploader = React.useRef(null)
  const [selectedPoste, setSelectedPoste] = useState([])
  const [selectedTypeContrat, setSelectedTypeContrat] = useState([])
  const token = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }
  const initData = {
    nom_complet: "",
    phone: "",
    cni: "",
    avatar: "",
    mail: "",
    mensualite: "",
    adress: "",
    date_debut: moment(dateDebut).format("YYYY-MM-DDTHH:mm:ss"),
    date_fin: moment(dateFin).format("YYYY-MM-DDTHH:mm:ss"),
    type_contrat: "",
    poste: "",
    shop: userInfos.id_boutique,
  }
  const [data, setData] = useState(initData)
  const [loading, setLoading] = useState(true)
  const typePoste = () => {
    axios
      .get(ApiUrl + "/postes/", token)
      .then((response) => {
        // console.log("reponse types de postes", response.data.data)
        setSelectedPoste(response.data.data)
      })
      .catch((err) => {
        console.log("err", err)
      })
  }

  const typeContrat = () => {
    axios
      .get(ApiUrl + "/typecontrats/", token)
      .then((response) => {
        // console.log("reponse types de  contrat", response.data.data)
        setSelectedTypeContrat(response.data.data)
      })
      .catch((err) => {
        console.log("err", err)
      })
  }

  useEffect(() => {
    typePoste()
    typeContrat()
  }, [])

  const onCloseModal = () => {
    setShow(false)
  }

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleImageUpload = (e) => {
    const [file] = e.target.files
    if (file) {
      uploadedImage.current.src = URL.createObjectURL(file)
      setData((old) => ({
        ...old,
        avatar: file,
      }))
    }
  }

  const sendEmploye = () => {
    data.date_debut = moment(data.date_debut).format("YYYY-MM-DDTHH:mm:ss")
    data.date_fin = moment(data.date_fin).format("YYYY-MM-DDTHH:mm:ss")
    const fd = new FormData()
    for (let key of Object.keys(data)) {
      fd.append(key, data[key])
    }
    axios
      .post(ApiUrl + "/employes/", fd, token, {})
      .then((response) => {
        setLoading(false)
        setShow(false)
        Swal.fire({
          icon: "success",
          timer: 1000,
          confirmButtonColor: "#1ee172",
        })
        history.push("/dashboard/employes/")
      })
      .catch((error) => {
        console.log(error)
        Swal.fire({
          icon: "error",
          title: "Erreur s'est produite",
          timer: 3000,
          confirmButtonColor: "red",
        })
        setLoading(true)
      })
  }

  return (
    <>
      <IonCol lg={12} sizemd={6} className="d-flex ion-justify-content-end">
        <IonButton
          style={{ border: "2px solid #E82828", borderRadius: 10 }}
          fill="clear"
          className="boutton"
          onClick={() => setShow(true)}
        >
          Ajouter un employé
        </IonButton>
      </IonCol>
      <Modal
        size="md"
        show={show}
        onHide={() => setShow(false)}
        animation={false}
      >
        <Modal.Header
          style={{
            background: " #E82828",
          }}
        >
          <IonIcon
            slot="start"
            icon={userIcon}
            style={{
              fontSize: "24px",
              alignSelf: "center",
              marginRight: 5,
            }}
            className="modalUserIcon"
          ></IonIcon>
          <Modal.Title
            style={{
              fontSize: 18,
              color: "#FFFFFF",
            }}
          >
            Ajouter un employe
          </Modal.Title>
          <CloseButton
            style={{
              color: "#FFF",
              borderRadius: "100%",
              border: "3px solid #FFF",
              padding: 0,
              width: 28,
              marginRight: 5,
              alignSelf: "center",
              opacity: 5,
            }}
            onClick={onCloseModal}
          />
        </Modal.Header>
        <Modal.Body
          className="p-1"
          style={{
            backgroundColor: "#F0F0F0",
          }}
        >
          <Form.Group sizemd={12} size={12}>
            <Form.Row>
              <Form.Group
                as={Col}
                style={{
                  background: "#FFFFFF",
                  margin: 10,
                  borderRadius: 5,
                  padding: 20,
                  height: "fit-content",
                }}
              >
                <Form.Group>
                  <IonCol className="d-flex ion-justify-content-center ion-align-items-center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        type="file"
                        id="avatar"
                        name="avatar"
                        accept="image/*"
                        onChange={handleImageUpload}
                        ref={imageUploader}
                        style={{
                          display: "none",
                        }}
                      />
                      <div
                        style={{
                          height: "auto",
                          width: 150,
                          border: "1px solid lightgray",
                          borderRadius: 100,
                        }}
                        onClick={() => imageUploader.current.click()}
                      >
                        <Image
                          ref={uploadedImage}
                          src="https://avatars.dicebear.com/v2/avataaars/112a794662513310b1044d95b6889cff.svg"
                          style={{
                            width: 150,
                            height: "auto",
                            borderRadius: 100,
                            cursor: "pointer",
                          }}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </IonCol>
                </Form.Group>
                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Nom complet
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="text"
                      id="nom_complet"
                      name="nom_complet"
                      placeholder="Tayeur"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="labelModal">Poste</Form.Label>
                    <Form.Group
                      className="form-control"
                      placeholder="Poste"
                      name="poste"
                      id="poste"
                      onChange={handleInputChange}
                      multiple={false}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        border: "1px solid #797979",
                      }}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Téléphone
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="number"
                      placeholder="Téléphone"
                      id="phone"
                      name="phone"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      CNI
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      minLength={13}
                      maxLength={15}
                      type="number"
                      placeholder="CNI"
                      id="cni"
                      name="cni"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label className="labelModal">
                      Type de contrat
                    </Form.Label>
                    <Form.Group
                      className="form-control"
                      placeholder="Type de contrat"
                      name="type_contrat"
                      id="type_contrat"
                      multiple={false}
                      onChange={handleInputChange}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        border: "1px solid #797979",
                      }}
                      
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="labelModal">Mensualité </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="text"
                      placeholder="Mensualité"
                      name="mensualite"
                      id="mensualite"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Adresse e-mail
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="email"
                      placeholder="tayeur@gmail.com"
                      id="mail"
                      name="mail"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="labelModal">Adresse </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="text"
                      placeholder="Adresse"
                      name="adress"
                      id="adress"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Date début
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="date"
                      name="date_debut"
                      id="date_debut"
                      placeholder="Date début"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Date fin
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="date"
                      name="date_fin"
                      id="date_fin"
                      placeholder="Date fin"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Form.Row>
              </Form.Group>
            </Form.Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#F0F0F0",
          }}
          className="d-flex justify-content-center"
        >
          <IonButton loader={loading} onClick={() => sendEmploye()}>
            Enregistrer
          </IonButton>
          <Toaster />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddEmploye
