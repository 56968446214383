import React, { useEffect, useState } from "react"

import { Link, useHistory } from "react-router-dom"
import { callOutline, lockClosedOutline } from "ionicons/icons"
import { IonCol, IonContent, IonRow } from "@ionic/react"
import axios from "axios"
import { COLORS } from "../../utils/constants"
import appName from "../../images/appName.png"
import Input from "../../shared/Input"
import { useFormData } from "../../shared/Form"
import { AppButton2 } from "../../shared/AppButton"
import { ApiUrl } from "../../utils/http/http"
import toast, { Toaster } from "react-hot-toast"
import "../../app.css"

const Login = (props) => {
  const history = useHistory()
  useEffect(() => {
    document.title = "Tayeur-Connexion"
  })

  const initialState = {
    phone: "",
    password: "",
    token: "",
    status: 0,
  }
  const [data, setData] = useFormData(initialState)
  const [loading, setLoading] = React.useState(false)

  let [errors, setErrors] = useState({})

  const [PhoneHasError, setPhoneHasError] = useState(false)
  const [PasswordHasError, setPasswordHasError] = useState(false)

  const handleChange = (e) => {
    setData(e.target.id, e.target.value)
  }

  const onValidateForm = (inputData) => {
    var isValidForm = true
    let errors = {}

    if (!inputData["phone"]) {
      isValidForm = false
      setPhoneHasError(true)
      errors["phone"] = "Ce champ est requis"
      setTimeout(() => {
        setPhoneHasError(false)
      }, 5000)
    }

    if (!inputData["password"]) {
      isValidForm = false
      setPasswordHasError(true)
      errors["password"] = "Veillez verifier votre mot de passe"
      setTimeout(() => {
        setPasswordHasError(false)
      }, 5000)
    }

    setErrors(errors)
    return isValidForm
  }

  const handleSubmit = () => {
    if (onValidateForm(data)) {
      setLoading(true)
      axios
        .post(ApiUrl + "/login/", data)
        .then((response) => {
          setLoading(false)
          localStorage.setItem("token", response.data.token.token)
          localStorage.setItem("userInfos", JSON.stringify(response.data))
          setTimeout(() => {
            setLoading(false)
            history.push("/dashboard")
          }, 2000)
          toast.success("Connexion en cours...")
        })
        .catch((error) => {
          console.log(error)
          toast.error(
            "Veillez verifier vos identifiants. Ou contactez un administrateur."
          )
        })
    }
  }

  return (
    <IonContent
      style={{
        backGround: COLORS.app,
        padding: 0,
      }}
    >
      <IonRow>
        <IonCol
          size={12}
          sizeMd={6}
          className="loginLeft d-flex  align-items-center justify-content-center"
        >
          <img src={appName} alt="Tayeur" />
        </IonCol>
        <IonCol
          size={12}
          sizeMd={6}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <h1
            style={{
              size: "24px",
              lineHeight: "29.6px",
              textTransform: "uppercase",
              fontWeight: 600,
              textAlign: "center",
            }}
            className="mb-3"
          >
            Connectez-vous
          </h1>
          <div className="d-block mt-5">
            <Input
              icon={callOutline}
              placeholder="Telephone"
              name="phone"
              type="text"
              value={data.phone}
              onIonChange={handleChange}
              id="phone"
            />
            {PhoneHasError && (
              <div className="input-error-feedback">{errors.phone}</div>
            )}
            <Input
              icon={lockClosedOutline}
              placeholder="Mot de passe"
              name="password"
              type="password"
              value={data.password}
              onIonChange={handleChange}
              id="password"
            />
            {PasswordHasError && (
              <div className="input-error-feedback">{errors.password}</div>
            )}
          </div>
          <div className="loginLeftContain">
            <Link
              to="forgot-password"
              className="text-right d-block w-full"
              style={{ color: "#000" }}
            >
              Mot de passe oublié?
            </Link>
            <AppButton2
              title="Connexion"
              onClick={handleSubmit}
              // loading={loading}
            />

            <Toaster />
            <div
              className="d-flex justify-content-end mt-4"
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "17.",
              }}
            >
              <span className="mr-1">Vous n’avez pas de compte?</span>
              <Link to="register" className="" style={{ color: COLORS.orange }}>
                S'inscrire
              </Link>
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonContent>
  )
}

export default Login
