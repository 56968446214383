import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRow,
} from "@ionic/react"
import { createOutline, trashOutline } from "ionicons/icons"
import { Button } from "react-bootstrap"
import { COLORS } from "../../utils/constants"

const Produit = () => {
  return (
    <>
      <IonGrid
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IonRow
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Robe duchesse x 4</IonCol>
          <IonCol>12 000</IonCol>
          <IonCol className="d-flex">
            <div className="col pl-0">
              <div className="cta-icon" style={{ cursor: "pointer" }}>
                <IonIcon
                  icon={createOutline}
                  style={{
                    color: COLORS.grayD,
                    fontSize: "24px",
                  }}
                  className="ionIcon"
                />
              </div>
              <span
                className="tableLink"
                style={{
                  color: COLORS.grayD,
                }}
              >
                Modifier
              </span>
            </div>

            <div className="col pl-0">
              <div className="cta-icon" style={{ cursor: "pointer" }}>
                <IonIcon
                  icon={trashOutline}
                  style={{
                    color: COLORS.redS,
                    fontSize: "24px",
                    opacity: 5,
                  }}
                  className="ionIcon"
                />
              </div>
              <span
                className="tableLink"
                style={{
                  color: COLORS.redS,
                }}
              >
                Supprimer
              </span>
            </div>
          </IonCol>
        </IonRow>
        <IonRow
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Robe duchesse x 4</IonCol>
          <IonCol>12 000</IonCol>
          <IonCol className="d-flex">
            <div className="col pl-0">
              <div className="cta-icon" style={{ cursor: "pointer" }}>
                <IonIcon
                  icon={createOutline}
                  style={{
                    color: COLORS.grayD,
                    fontSize: "24px",
                  }}
                  className="ionIcon"
                />
              </div>
              <span
                className="tableLink"
                style={{
                  color: COLORS.grayD,
                }}
              >
                Modifier
              </span>
            </div>

            <div className="col pl-0">
              <div className="cta-icon" style={{ cursor: "pointer" }}>
                <IonIcon
                  icon={trashOutline}
                  style={{
                    color: COLORS.redS,
                    fontSize: "24px",
                    opacity: 5,
                  }}
                  className="ionIcon"
                />
              </div>
              <span
                className="tableLink"
                style={{
                  color: COLORS.redS,
                }}
              >
                Supprimer
              </span>
            </div>
          </IonCol>
        </IonRow>
        <IonRow
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Robe duchesse x 4</IonCol>
          <IonCol>12 000</IonCol>
          <IonCol className="d-flex">
            <div className="col pl-0">
              <div className="cta-icon" style={{ cursor: "pointer" }}>
                <IonIcon
                  icon={createOutline}
                  style={{
                    color: COLORS.grayD,
                    fontSize: "24px",
                  }}
                  className="ionIcon"
                />
              </div>
              <span
                className="tableLink"
                style={{
                  color: COLORS.grayD,
                }}
              >
                Modifier
              </span>
            </div>

            <div className="col pl-0">
              <div className="cta-icon" style={{ cursor: "pointer" }}>
                <IonIcon
                  icon={trashOutline}
                  style={{
                    color: COLORS.redS,
                    fontSize: "24px",
                    opacity: 5,
                  }}
                  className="ionIcon"
                />
              </div>
              <span
                className="tableLink"
                style={{
                  color: COLORS.redS,
                }}
              >
                Supprimer
              </span>
            </div>
          </IonCol>
        </IonRow>
        <IonRow
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Robe duchesse x 4</IonCol>
          <IonCol>12 000</IonCol>
          <IonCol className="d-flex">
            <div className="col pl-0">
              <div className="cta-icon" style={{ cursor: "pointer" }}>
                <IonIcon
                  icon={createOutline}
                  style={{
                    color: COLORS.grayD,
                    fontSize: "24px",
                  }}
                  className="ionIcon"
                />
              </div>
              <span
                className="tableLink"
                style={{
                  color: COLORS.grayD,
                }}
              >
                Modifier
              </span>
            </div>

            <div className="col pl-0">
              <div className="cta-icon" style={{ cursor: "pointer" }}>
                <IonIcon
                  icon={trashOutline}
                  style={{
                    color: COLORS.redS,
                    fontSize: "24px",
                    opacity: 5,
                  }}
                  className="ionIcon"
                />
              </div>
              <span
                className="tableLink"
                style={{
                  color: COLORS.redS,
                }}
              >
                Supprimer
              </span>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow>
          <IonCol
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Total: 24 000
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
          }}
        >
          <IonLabel>
            Montant versé
            <IonInput
              style={{
                height: 45,
                width: 125,
                margin: 5,
                borderRadius: 5,
                border: "1px solid #C4C4C4",
              }}
            />
          </IonLabel>
          <IonLabel>
            Restant
            <IonInput
              style={{
                height: 45,
                margin: 5,

                width: 125,
                borderRadius: 5,
                border: "1px solid #C4C4C4",
              }}
            />
          </IonLabel>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow
          style={{
            display: "flex",
            alignItems: "end",
            justifyContent: "end",
          }}
        >
          <IonButton
            style={{
              border: "2px solid #E82828",
              borderRadius: 10,
              width: 125,
              margin: 5,
            }}
            fill="clear"
            className="boutton"
          >
            facture
          </IonButton>
          <IonButton
            style={{
              border: "2px solid #E82828",
              borderRadius: 10,
              width: 125,
              margin: 5,
            }}
            fill="clear"
            className="boutton"
          >
            Enregistrer
          </IonButton>
        </IonRow>
      </IonGrid>
    </>
  )
}
export default Produit
