import { Button, CloseButton, Form, Modal, ModalFooter } from "react-bootstrap"
import { IonItem, IonLabel, IonRow } from "@ionic/react"
import React from "react"

const MesureClient = ({ client }) => {
  const [show, setShow] = React.useState(false)

  const onClose = () => {
    setShow(false)
  }

  return (
    <>
      <Button
        className="ion-justify-content-end"
        style={{
          backgroundColor: "#fff",
          color: "#000000",
          border: "3px solid #f2f2f2",
          fontWeight: 500,
        }}
        onClick={() => setShow(true)}
      >
        Mesures
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header>
          <Modal.Title
            id="example-custom-modal-styling-title"
            style={{ color: "#979797", fontSize: 16, fontWeight: "bold" }}
          >
            mesures{" "}
          </Modal.Title>
          <CloseButton
            style={{
              color: "#E72D32",
              borderRadius: 100,
              border: "3px solid #E72D32",
              padding: 0,
              width: 28,
              marginRight: 5,
              alignSelf: "center",
              opacity: 5,
            }}
            onClick={onClose}
          />
        </Modal.Header>
        <Modal.Body>
          <Form.Group sizemd={12} size={12}>
            <Form.Group
              sizemd={12}
              size={12}
              className="justify-content-between align-center"
              style={{ margin: 5 }}
            >
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Cou</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.cou}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Epaule</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.epaule}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Manches</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.manches}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Ceinture</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.ceinture}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Tour de bras</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.tour_bras}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Tour de hanches</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.hanche}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Tour de cuisse</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.cuisse}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Tour de haut</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.longueur_haut}
                </IonItem>
              </IonRow>
            </Form.Group>
            <Form.Group
              sizeMd={12}
              size={12}
              className="justify-content-between align-end "
              style={{ margin: 5 }}
            >
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Longueur bas</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.longueur_bas}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Longueur boubou</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.longueur_boubou}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Longueur jupe</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.longueur_jupe}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Poitrine</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.poitrine}
                </IonItem>
              </IonRow>
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Taille</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.taille}
                </IonItem>
              </IonRow>{" "}
              <IonRow className="ion-justify-content-between mb-2 align-items-center">
                <IonLabel>Robe</IonLabel>
                <IonItem
                  style={{
                    backgroundColor: "#F7F7F7",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                >
                  {client.longueur_robe}
                </IonItem>
              </IonRow>
            </Form.Group>
          </Form.Group>
        </Modal.Body>
        <ModalFooter
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <Button className="btn btn-danger">Enregistrer</Button> */}
        </ModalFooter>
      </Modal>
    </>
  )
}

export default MesureClient
