import { IonButton, IonCol, IonInput, IonLabel, IonRow } from "@ionic/react"
import axios from "axios"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ApiUrl } from "../../utils/http/http"

function ProfileParam() {
  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const [entreprise, setEntreprise] = useState([])
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  useEffect(() => {
    document.title = "Tayeur-Entreprise-Infos"
    onGetEntreprise()
  }, [])

  const onGetEntreprise = () => {
    axios
      .get(`${ApiUrl}/entreprises/` + userInfos.id_boutique, config)
      .then((response) => {
        console.log(response.data.data)
        setEntreprise(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <IonRow>
      <IonCol
        sizemd={12}
        size={12}
        className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center"
      >
        <div>
          <IonLabel>Nom Complet:</IonLabel>
        </div>
        <div>
          <IonInput
            placeholder="Name"
            className="profile-input"
            value={userInfos.nom_complet}
            style={{
              fontWeight: "bold",
              fontSize: 16,
              color: "gray",
            }}
            readonly
          ></IonInput>
        </div>
      </IonCol>
      <IonCol
        sizemd={12}
        size={12}
        className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center"
      >
        <div>
          <IonLabel>Email:</IonLabel>
        </div>
        <div>
          <IonInput
            placeholder="Enter Email"
            className="profile-input"
            value={userInfos.email}
            style={{
              fontWeight: "bold",
              fontSize: 16,
              color: "gray",
            }}
            readonly
          ></IonInput>
        </div>
      </IonCol>
      <IonCol
        sizemd={12}
        size={12}
        className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center"
      >
        <div>
          <IonLabel>Téléphone:</IonLabel>
        </div>
        <div>
          <IonInput
            type="number"
            value={userInfos.phone}
            placeholder={userInfos.phone}
            className="profile-input"
            style={{
              fontWeight: "bold",
              fontSize: 16,
              color: "gray",
            }}
            readonly
          ></IonInput>
        </div>
      </IonCol>
      <IonCol sizemd={12} size={12} className="d-flex justify-content-start">
        <Link fill="clear" className="boutton-modifier-password">
          +Modifier le mot de passe
        </Link>
      </IonCol>
      <IonCol
        sizemd={12}
        size={12}
        className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center"
      >
        <div>
          <IonLabel>Mot de passe actuel:</IonLabel>
        </div>
        <div>
          <IonInput
            type="password"
            show-hide-input
            placeholder="password"
            className="profile-input"
          ></IonInput>
        </div>
      </IonCol>
      <IonCol
        sizemd={12}
        size={12}
        className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center"
      >
        <div>
          <IonLabel>Nouveau de passe actuel:</IonLabel>
        </div>
        <div>
          <IonInput
            type="password"
            show-hide-input
            placeholder="password"
            className="profile-input"
          ></IonInput>
        </div>
      </IonCol>
      <IonCol
        sizemd={12}
        size={12}
        className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center"
      >
        <div>
          <IonLabel>Confirmer:</IonLabel>
        </div>
        <div>
          <IonInput
            type="password"
            show-hide-input
            placeholder="password"
            className="profile-input"
          ></IonInput>
        </div>
      </IonCol>
      <IonCol sizemd={12} size={12} className="d-flex justify-content-center">
        <IonButton fill="clear" className="boutton-modifier">
          Modifier
        </IonButton>
      </IonCol>
    </IonRow>
  )
}

export default ProfileParam
