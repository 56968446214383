import { useEffect, useState } from "react"
import {
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react"
import { Link } from "react-router-dom"
import axios from "axios"
import { ApiUrl } from "../../utils/http/http"

const CommandeCard = () => {
  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const [count, setCount] = useState(0)

  useEffect(() => {
    axios({
      method: "GET",
      url: ApiUrl + "/commandes/" + userInfos.id_boutique,
      count,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const commands = res.data.count
      setCount(commands)
    })
  })

  return (
    <IonCard className="cartes-dashboard ion-justify-content-center ion-align-items-center">
      <Link to="/dashboard/commande" className="lien-detail-commande">
        <IonCardContent className="d-flex ion-justify-content-between ion-align-items-start data">
          <svg
            width="58"
            height="58"
            viewBox="0 0 58 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M53.9088 30.7931L57.4731 12.2594C57.6669 11.2569 57.4413 10.3037 56.84 9.57625C56.2394 8.84812 55.3456 8.4475 54.325 8.4475H15.3188C14.9738 8.4475 14.6938 8.7275 14.6938 9.0725C14.6938 9.4175 14.9738 9.6975 15.3188 9.6975H54.325C54.9669 9.6975 55.5175 9.93687 55.8763 10.3719C56.2356 10.8069 56.3663 11.3931 56.2463 12.0231L52.6819 30.5575C52.4138 31.9462 50.9938 33.1194 49.58 33.1194H15.7613L9.18688 0.66687H1.08438C0.739381 0.66687 0.459381 0.94687 0.459381 1.29187C0.459381 1.63687 0.739381 1.91687 1.08438 1.91687H8.16501L16.0225 40.6431C16.0581 42.2131 17.3463 43.4787 18.9244 43.4787H56.8019C57.1475 43.4787 57.4269 43.1994 57.4269 42.8537C57.4269 42.5081 57.1475 42.2287 56.8019 42.2287H18.9244C18.0131 42.2287 17.2719 41.4875 17.2719 40.5756L16.0144 34.3681H49.58C51.5881 34.3694 53.53 32.7644 53.9088 30.7931Z"
              fill="#2BACE2"
            />
            <path
              d="M24.3269 47.3518C21.575 47.3518 19.3362 49.5906 19.3362 52.3418C19.3362 55.0937 21.575 57.3324 24.3269 57.3324C27.0787 57.3324 29.3175 55.0937 29.3175 52.3418C29.3175 49.5906 27.0787 47.3518 24.3269 47.3518ZM24.3269 56.0837C22.2644 56.0837 20.5862 54.4049 20.5862 52.3424C20.5862 50.2799 22.2644 48.6024 24.3269 48.6024C26.3894 48.6024 28.0675 50.2812 28.0675 52.3424C28.0675 54.4049 26.3894 56.0837 24.3269 56.0837Z"
              fill="#2BACE2"
            />
            <path
              d="M47.3925 47.3518C44.64 47.3518 42.4019 49.5906 42.4019 52.3418C42.4019 55.0937 44.64 57.3324 47.3925 57.3324C50.1438 57.3324 52.3825 55.0937 52.3825 52.3418C52.3825 49.5906 50.1438 47.3518 47.3925 47.3518ZM47.3925 56.0837C45.3294 56.0837 43.6519 54.4049 43.6519 52.3424C43.6519 50.2799 45.3306 48.6024 47.3925 48.6024C49.4556 48.6024 51.1325 50.2812 51.1325 52.3424C51.1325 54.4049 49.4544 56.0837 47.3925 56.0837Z"
              fill="#2BACE2"
            />
          </svg>
          <div>
            <IonCardSubtitle className="titre-carte">Commandes</IonCardSubtitle>
            <IonCardTitle className="title-card">{count}</IonCardTitle>
          </div>
        </IonCardContent>
      </Link>
    </IonCard>
  )
}

export default CommandeCard
