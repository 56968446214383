import {
  IonButton,
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonSearchbar,
} from "@ionic/react"
import { Button } from "react-bootstrap"
import AddModele from "./ModeleModal"
import "./stock.css"

function Confection() {
  const columns = ["Modèles", "Prix ", "Nombre", "Total ", "Actions"]

  return (
    <>
      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "40px 0",
        }}
      >
        <IonRow className="ion-align-items-center">
          <IonCol
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <IonLabel
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                margin: 10,
              }}
            >
              Filtre
            </IonLabel>
            <IonSearchbar
              value=""
              showCancelButton="never"
              placeholder="Produit"
              style={{
                "--border-radius": "27.67px",
                "--box-shadow": "none",
                "--background": "#F7F7F7",
                marginBottom: "5px",
                width: "50%",
              }}
            ></IonSearchbar>
          </IonCol>

          <IonCol sizemd={6} className="d-flex ion-justify-content-around">
            <AddModele />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "0",
        }}
      >
        <IonRow
          className="ion-align-items-center"
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
            position: "sticky",
            background: "#F8F8F8",
          }}
        >
          {columns.map((column, index) => (
            <IonCol
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              key={index}
            >
              {column}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
        }}
        className="myContent"
      >
        {/* {.map((entry, index) => ( */}
        <IonRow
          key={""}
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Robe soie</IonCol>
          <IonCol>15 000 f</IonCol>
          <IonCol>20</IonCol>
          <IonCol>300 000 f</IonCol>
          <IonCol>
            <Button className="bouttonStatut">Confectionné</Button>
          </IonCol>
        </IonRow>
        <IonRow
          key={""}
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Robe soie</IonCol>
          <IonCol>15 000 f</IonCol>
          <IonCol>20</IonCol>
          <IonCol>300 000 f</IonCol>
          <IonCol>
            <Button className="bouttonStatut">Confectionné</Button>
          </IonCol>
        </IonRow>{" "}
        <IonRow
          key={""}
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Robe soie</IonCol>
          <IonCol>15 000 f</IonCol>
          <IonCol>20</IonCol>
          <IonCol>300 000 f</IonCol>
          <IonCol>
            <Button className="bouttonStatut">Confectionné</Button>
          </IonCol>
        </IonRow>
        {/* ))} */}
      </IonGrid>
    </>
  )
}

export default Confection
