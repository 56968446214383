import axios from "axios"
import { AuthState } from "../userStorage"

//  export const ApiUrl = "https://recette.fewnu.api.withvolkeno.com/tayeur"
// export const baseBackendUrl = "https://recette.fewnu.api.withvolkeno.com"
  export const ApiUrl = "https://api.tayeur.fewnu.app/tayeur"
// export const ApiUrl = "http://127.0.0.1:8000/tayeur"
export const baseBackendUrl = "http://127.0.0.1:8000"

const apiEndPoint = ApiUrl + ""

export function getUrl(url) {
  return apiEndPoint + url
}

// axios.interceptors.request.use((val) => {
//   console.log(val.method, val.url, val.data)
//   return val
// })

export async function get(url) {
  const options = await getOptions()
  return axios.get(getUrl(url), options).then((res) => res.data)
}

export async function post(url, data, options) {
  const opt = await getOptions(options)
  return axios.post(getUrl(url), data, opt).then((res) => res.data)
}

async function deleteItem(url, options = {}) {
  const opt = await getOptions(options)
  return axios.delete(getUrl(url), opt).then((res) => res.data)
}

async function put(url, data, options = {}) {
  const opt = await getOptions(options)
  return axios.put(getUrl(url), data, opt).then((res) => res.data)
}
async function getOptions(options = {}) {
  if (!options) options = {}
  const token = await AuthState.token()
  if (token) {
    options = Object.assign(options, {
      headers: {
        ...options.headers,
        Authorization: `bearer ${token}`,
      },
    })
  }
  return options
}

function upload(data, route, onUploadProgress, method = "post") {
  const config = {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      )
      onUploadProgress(percentCompleted)
    },
    headers: {
      "Content-Type": "application/json",
    },
  }
  if (method === "post") return post(route, data, config)

  return put(route, data, config)
}

export function makeid(length) {
  let result = ""
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export default {
  get,
  put,
  post,
  upload,
  delete: deleteItem,
}
