import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSearchbar,
} from "@ionic/react"
import { createOutline } from "ionicons/icons"
import { COLORS } from "../../utils/constants"

function Produit() {
  const columns = [
    "Produit",
    "Prix d'achat",
    "Quantité",
    "Total P.A",
    "Actions",
  ]

  return (
    <>
      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "40px 0",
        }}
      >
        <IonRow className="ion-align-items-center">
          <IonCol
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <IonLabel
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                margin: 10,
              }}
            >
              Filtre
            </IonLabel>
            <IonSearchbar
              value=""
              showCancelButton="never"
              placeholder="Produit"
              style={{
                "--border-radius": "27.67px",
                "--box-shadow": "none",
                "--background": "#F7F7F7",
                marginBottom: "5px",
                width: "50%",
              }}
            ></IonSearchbar>
          </IonCol>

          <IonCol sizemd={6} className="d-flex ion-justify-content-around">
            <IonButton
              style={{ border: "2px solid #E82828", borderRadius: 10 }}
              fill="clear"
              className="boutton"
            >
              Approvisionner produit{" "}
            </IonButton>
            <IonButton
              style={{ border: "2px solid #E82828", borderRadius: 10 }}
              fill="clear"
              className="boutton"
            >
              Ajouter produit
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "0",
        }}
      >
        <IonRow
          className="ion-align-items-center"
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
            position: "sticky",
            background: "#F8F8F8",
          }}
        >
          {columns.map((column, index) => (
            <IonCol
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              key={index}
            >
              {column}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
        }}
        className="myContent"
      >
        {/* {.map((entry, index) => ( */}
        <IonRow
          key={""}
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Soie duchesse</IonCol>
          <IonCol>3 000 f</IonCol>
          <IonCol>10 m</IonCol>
          <IonCol>30 000 f</IonCol>
          <div className="col-2 pl-0">
            <div
              className="cta-icon"
              style={{ cursor: "pointer" }}
              // onClick={() =>
              //   History.push()
              // }
            >
              <IonIcon
                icon={createOutline}
                style={{
                  color: COLORS.grayD,
                  fontSize: "24px",
                }}
                className="ionIcon"
              />
            </div>
            <span
              className="tableLink"
              style={{
                color: COLORS.grayD,
              }}
            >
              Modifier
            </span>
          </div>
        </IonRow>{" "}
        <IonRow
          key={""}
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Soie duchesse</IonCol>
          <IonCol>3 000 f</IonCol>
          <IonCol>10 m</IonCol>
          <IonCol>30 000 f</IonCol>
          <div className="col-2 pl-0">
            <div className="cta-icon" style={{ cursor: "pointer" }}>
              <IonIcon
                icon={createOutline}
                style={{
                  color: COLORS.grayD,
                  fontSize: "24px",
                }}
                className="ionIcon"
              />
            </div>
            <span
              className="tableLink"
              style={{
                color: COLORS.grayD,
              }}
            >
              Modifier
            </span>
          </div>
        </IonRow>
        <IonRow
          key={""}
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Soie duchesse</IonCol>
          <IonCol>3 000 f</IonCol>
          <IonCol>10 m</IonCol>
          <IonCol>30 000 f</IonCol>
          <div className="col-2 pl-0">
            <div className="cta-icon" style={{ cursor: "pointer" }}>
              <IonIcon
                icon={createOutline}
                style={{
                  color: COLORS.grayD,
                  fontSize: "24px",
                }}
                className="ionIcon"
              />
            </div>
            <span
              className="tableLink"
              style={{
                color: COLORS.grayD,
              }}
            >
              Modifier
            </span>
          </div>
        </IonRow>
        {/* ))} */}
      </IonGrid>
    </>
  )
}

export default Produit
