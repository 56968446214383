import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react"
import { arrowBackOutline } from "ionicons/icons"
import { COLORS } from "../../utils/constants"
import "./commande.css"
import { Link, useParams } from "react-router-dom"
import React, { useEffect } from "react"
import { ApiUrl } from "../../utils/http/http"
import axios from "axios"

function DetailClient() {
  const [detail, setDetail] = React.useState({})
  const params = useParams()
  const { id } = params

  useEffect(() => {
    getClientInfo()
  }, [id])

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  const getClientInfo = () => {
    axios
      .get(`${ApiUrl}/client/` + id + "/", config)
      .then((res) => {
        console.log("Response detail", res.data)
        setDetail(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <IonContent
      style={{
        padding: "",
        "--background": COLORS.gray,
      }}
    >
      <IonRow
        style={{
          paddingLeft: "20px",
          marginTop: "20px",
          marginBottom: 0,
          paddingBottom: 0,
          position: "relative",
        }}
      >
        <Link
          to="/dashboard/clients"
          style={{
            color: "#000",
            cursor: "pointer",
            position: "relative",
          }}
        >
          <IonIcon
            icon={arrowBackOutline}
            style={{
              fontSize: "20px",
              marginBottom: 0,
              paddingBottom: 0,
            }}
            className="iconpointer"
          ></IonIcon>
        </Link>
        <IonGrid
          style={{
            padding: "20px 0",
            margin: "20px 30px 0 20px",
          }}
        >
          <h6
            style={{
              fontWeight: "normal",
              color: "#666666",
              fontSize: "14px",
              fontFamily: "Montserrat",
            }}
          >
            Liste des clients {""}
            <span
              style={{
                fontWeight: "normal",
                color: "#E72D32",
                fontSize: "14px",
                fontFamily: "Montserrat",
              }}
            >
              / Détail client
            </span>
          </h6>
        </IonGrid>
      </IonRow>
      <IonRow
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          marginBottom: "15px",
          paddingBottom: 0,
        }}
      >
        <div className="detailCommandeGrid">
          <IonGrid
            className="infoClientGrid"
            style={{
              borderRadius: 10,
              background: "#FEFEFE",
              boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
            }}
          >
            <IonItem
              style={{
                color: "#666666",
              }}
              className=" mb-3"
            >
              <b> Informations du client</b>
            </IonItem>
            <IonRow className="ion-align-items-center ;ion-justify-content-start">
              <IonCol className="contactClient">
                <IonRow className="clientRowItem ion-justify-content-between mb-2 align-items-center">
                  <IonLabel>Nom Complet</IonLabel>
                  <h6>{detail?.nom_complet}</h6>
                  <IonLabel>Pays</IonLabel>
                  <h6> {detail?.pays}  Senegal</h6>
                </IonRow>
                <IonRow className="clientRowItem ion-justify-content-between mb-2 align-items-center">
                  <IonLabel>Téléphone</IonLabel>
                  <h6> {detail?.phone} </h6>
                  <IonLabel>Ville</IonLabel>
                  <h6> {detail?.ville} Dakar</h6>
                </IonRow>
                <IonRow className="clientRowItem ion-justify-content-between mb-2 align-items-center">
                  <IonLabel>Adresse</IonLabel>
                  <h6> {detail?.adress}</h6>
                  <IonLabel>Sexe</IonLabel>
                  <h6> {detail?.autre} </h6>
                </IonRow>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonGrid
            className="infoMesureGrid"
            style={{
              marginTop: 20,
              borderRadius: 10,
              background: "#FEFEFE",
              boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
              alignItems: "center",
            }}
          >
            <IonItem
              style={{
                color: "#666666",
              }}
              className=" mb-3"
            >
              <b> Mesures du client</b>
            </IonItem>

            <IonRow
              id="gridInfo"
              className="ion-align-items-center ;ion-justify-content-start"
            >
              <IonCol
                style={{
                  padding: "10px",
                  background: "#FFF",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <div className="tourItems" style={{ width: "100%" }}>
                  <IonItem className="ion-justify-content-between">
                    <IonLabel>Cou</IonLabel>
                    <IonItem className="text-right">{detail.cou}</IonItem>
                  </IonItem>
                  <IonItem className="ion-justify-content-between">
                    <IonLabel>Epaule</IonLabel>
                    <IonLabel className="text-right">{detail.epaule}</IonLabel>
                  </IonItem>
                  <IonItem className="ion-justify-content-between">
                    <IonLabel>Manches</IonLabel>
                    <IonLabel className="text-right">{detail.manches}</IonLabel>
                  </IonItem>
                  <IonItem className="ion-justify-content-between">
                    <IonLabel>Ceinture</IonLabel>
                    <IonLabel className="text-right">
                      {detail.ceinture}
                    </IonLabel>
                  </IonItem>
                  <IonItem className="ion-justify-content-between">
                    <IonLabel>Tour de bras</IonLabel>
                    <IonLabel className="text-right">
                      {detail.tour_bras}
                    </IonLabel>
                  </IonItem>
                  <IonItem className="ion-justify-content-between">
                    <IonLabel>Tour de hanches</IonLabel>
                    <IonLabel className="text-right">{detail.hanche}</IonLabel>
                  </IonItem>
                  <IonItem className="ion-justify-content-between">
                    <IonLabel>Tour de cuisse</IonLabel>
                    <IonLabel className="text-right">{detail.cuisse}</IonLabel>
                  </IonItem>
                  <IonItem className="ion-justify-content-between">
                    <IonLabel>Longueur Haut</IonLabel>
                    <IonLabel className="text-right">
                      {detail.longueur_haut}
                    </IonLabel>
                  </IonItem>
                </div>
                <div className="longueurItems" style={{ width: "100%" }}>
                  <IonItem className="clientItemMesure ion-justify-content-between">
                    <IonLabel>Longueur Bas</IonLabel>
                    <IonLabel className="text-right">
                      {detail.longueur_bas}
                    </IonLabel>
                  </IonItem>
                  {detail.sexe === "homme" && (
                    <IonItem className="clientItemMesure ion-justify-content-between">
                      <IonLabel>Longueur Boubou</IonLabel>
                      <IonLabel className="text-right">
                        {detail.longueur_boubou}
                      </IonLabel>
                    </IonItem>
                  )}
                  {detail.sexe === "femme" && (
                    <>
                      <IonItem className="clientItemMesure ion-justify-content-between">
                        <IonLabel>Longueur Jupe</IonLabel>
                        <IonLabel className="text-right">
                          {detail.longueur_jupe}
                        </IonLabel>
                      </IonItem>
                      <IonItem className="clientItemMesure ion-justify-content-between">
                        <IonLabel>Longueur Robe</IonLabel>
                        <IonLabel className="text-right">
                          {detail.longueur_robe}
                        </IonLabel>
                      </IonItem>
                    </>
                  )}
                  <IonItem className="clientItemMesure ion-justify-content-between">
                    <IonLabel>Poitrine</IonLabel>
                    <IonLabel className="text-right">
                      {detail.poitrine}
                    </IonLabel>
                  </IonItem>
                  <IonItem className="clientItemMesure ion-justify-content-between">
                    <IonLabel>Tour de Taille</IonLabel>
                    <IonLabel className="text-right">{detail.taille}</IonLabel>
                  </IonItem>
                  <IonItem className="clientItemMesure ion-justify-content-between">
                    <IonLabel>Longueur Bras</IonLabel>
                    <IonLabel className="text-right">
                      {detail.longueur_bras}
                    </IonLabel>
                  </IonItem>
                  <IonItem className="clientItemMesure ion-justify-content-between">
                    <IonLabel>Longueur Robe</IonLabel>
                    <IonLabel className="text-right">
                      {detail.longueur_robe}
                    </IonLabel>
                  </IonItem>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonRow>
    </IonContent>
  )
}

export default DetailClient
