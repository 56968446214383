import {
  IonButton,
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonRow,
  IonInput,
} from "@ionic/react"
import { calendarClearOutline, logOutOutline } from "ionicons/icons"
import React, { useState } from "react"

function BilanPage() {
  const [dateDebut, setDateDebut] = useState("2012-12-15T13:47:20.789")
  const [dateFin, setDateFin] = useState("2012-12-15T13:47:20.789")

  const columns = ["Date", "Entrées", "Dépenses", "Benefices"]

  return (
    <>
      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "40px 0",
        }}
      >
        <IonRow className="ion-align-items-center">
          <IonCol sizemd={6}>
            <IonButton fill="clear" className="boutton-export-csv">
              <IonIcon icon={logOutOutline} slot="start"></IonIcon>
              Export CSV
            </IonButton>
          </IonCol>

          <IonCol sizemd={6} className="d-flex ion-justify-content-around">
            <div style={{ width: "40%" }}>
              <p className="date-paragraph">Date de début</p>
              <IonButton fill="clear" className="boutton-date-picker">
                <IonInput
                  type="date"
                  onIonChange={(e) => setDateDebut(e.detail.value)}
                ></IonInput>
              </IonButton>
            </div>
            <div style={{ width: "50%" }}>
              <p className="date-paragraph">Date de fin</p>
              <IonButton fill="clear" className="boutton-date-picker">
                <IonInput
                  type="date"
                  onIonChange={(e) => setDateFin(e.detail.value)}
                ></IonInput>
              </IonButton>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "0",
        }}
      >
        <IonRow
          className="ion-align-items-center"
          style={{
            height: "70px",
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            paddingLeft: "30px",
          }}
        >
          {columns.map((column, index) => (
            <IonCol
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              key={index}
            >
              {column}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
        }}
        className="myContent"
      ></IonGrid>
    </>
  )
}

export default BilanPage
