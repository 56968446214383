import {
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonText,
  IonImg,
} from "@ionic/react"
import editIon from "../images/edit.png"
import React from "react"
import { COLORS } from "../utils/constants"
import logo from "../images/logo.png"
import profil from "../images/profile.png"
import { Image } from "react-bootstrap"
import { AppButton2 } from "../shared/AppButton"
import { useDimension } from "./Intro"

const Profile = ({ edit }) => {
  const { width, height } = useDimension()
  const rounded = width * 0.1
  return (
    <IonContent className="ion-padding" style={{ "--background": "#E5E5E5" }}>
      <IonGrid>
        <IonRow>
          <IonCol sizemd={12} sizeLg={2}></IonCol>
          <IonCol sizeXs={12} sizeLg={8}>
            <div
              className="ion-padding"
              style={{
                background: "#FFFFFF",
                "box-shadow":
                  "2.66318px 6.65796px 6.65796px 2.66318px rgba(0, 0, 0, 0.05)",
                "border-radius": "7%",
                minHeight: height * 0.3,
                overflow: "hidden",
                position: "relative",
                width: "100%",
              }}
            >
              <div
                style={{
                  width: rounded,
                  height: rounded,
                  position: "absolute",
                  top: -(rounded / 3),
                  right: -rounded / 3,
                  borderRadius: rounded / 2,
                  backgroundColor: "#00627C",
                }}
              />
              <IonItem lines="none">
                <Image src={logo} />
              </IonItem>
              <IonItem lines="none">
                <IonText
                  style={{
                    fontWeight: "bold",
                    fontSize: "2vh",
                    marginTop: 20,
                    color: COLORS.app1,
                  }}
                >
                  Cosmetique Rassoul
                </IonText>
              </IonItem>
              <IonRow>
                <IonCol sizeXs={12} sizemd={6}>
                  <IonItem
                    style={{
                      borderRadius: 20,
                      "box-shadow":
                        "2.66318px 6.65796px 6.65796px 2.66318px rgba(0, 0, 0, 0.05)",
                      "border-radius": "7%",
                    }}
                    lines={"none"}
                  >
                    <IonImg
                      src={profil}
                      style={{ width: "80%", height: "auto" }}
                    />
                    {edit && (
                      <div
                        style={{
                          position: "absolute",
                          bottom: 20,
                          right: 20,
                        }}
                      >
                        <EditIcon />
                      </div>
                    )}
                  </IonItem>
                </IonCol>
                <IonCol sizeSm={12} sizemd={6}>
                  <IonItem lines="none">
                    <IonText
                      style={{
                        fontWeight: "bold",
                        fontSize: "2vh",
                        marginTop: 20,
                        color: COLORS.app1,
                      }}
                    >
                      {edit
                        ? "Modifier les informations personnelles"
                        : "Information personnelle"}
                    </IonText>
                  </IonItem>
                  <Row label={"Prénom: "} edit={edit} value="Babacar" />
                  <Row label={"Nom:"} edit={edit} value="Mbengue" />
                  <Row label={"N°: "} edit={edit} value="+221771087277" />
                  <Row
                    label={"Adresse:"}
                    edit={edit}
                    value="Liberté 6 Extension"
                  />
                </IonCol>
              </IonRow>
            </div>
            {edit && (
              <IonRow className="ion-justify-content-end">
                <IonCol sizeSm={12} sizemd={4} sizeLg={3} sizeXl={2}>
                  <AppButton2
                    //   loading={loading}
                    bgColor={COLORS.app1}
                    title={"Editer"}
                    //   onClick={onSubmit}
                  />
                </IonCol>
              </IonRow>
            )}
          </IonCol>
          <IonCol sizemd={12} sizeLg={2}></IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default Profile

function Row({ label, value, edit }) {
  return (
    <IonItem lines={"none"} style={{ marginTop: "5vh" }}>
      <IonText
        style={{
          fontWeight: "bold",
          fontSize: "2vh",
        }}
      >
        {label}
      </IonText>
      <IonText
        style={{
          fontWeight: "bold",
          marginLeft: "10%",
          fontSize: "2vh",
          color: COLORS.app1,
        }}
      >
        {value}
      </IonText>
      {edit && <EditIcon style={{ marginLeft: "15%" }} />}
    </IonItem>
  )
}

export function EditIcon({ onCLick, style }) {
  return (
    <IonImg
      onClick={onCLick}
      src={editIon}
      style={{ width: 24, height: 24, cursor: "pointer", ...style }}
    />
  )
}
