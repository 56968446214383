import { IonButton, IonCol, IonInput, IonLabel, IonRow } from "@ionic/react"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { ApiUrl } from "../../utils/http/http"

const EntrepriseParam = () => {
  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  const onGetEntreprise = () => {
    axios
      .get(ApiUrl + "/entreprises/" + userInfos.id_boutique, config)
      .then((response) => {
        console.log("Response", response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    onGetEntreprise()
  }, [])

  return (
    <IonRow>
      <IonCol
        sizemd={12}
        size={12}
        className="d-flex justify-content-between align-items-center"
      >
        <IonInput className="drop-zone">
          <p>
            + Ajouter le logo de mon entreprise a mes factures et couriers
            électroniques
          </p>
        </IonInput>
      </IonCol>
      <IonCol sizemd={12} size={12}>
        <IonLabel>Nom de l'entreprise</IonLabel>
        <IonInput
          className="entreprise-input"
          placeholder={userInfos.id_boutique}
          value={userInfos?.nom_boutique}
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: "gray",
          }}
        ></IonInput>
      </IonCol>
      <IonCol sizemd={12} size={12}>
        <IonLabel>Courrier électronique</IonLabel>
        <IonInput
          className="entreprise-input"
          placeholder="kebson@gmail.com"
          value={userInfos?.email}
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: "gray",
          }}
        ></IonInput>
      </IonCol>
      <IonCol sizemd={12} size={12}>
        <IonLabel>Adresse, ville, téléphone, pays</IonLabel>
        <IonInput
          className="entreprise-input"
          placeholder="Ville"
          value={userInfos?.ville}
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: "gray",
          }}
        ></IonInput>
        <IonInput
          className="entreprise-input"
          placeholder="pays"
          value={userInfos?.pays}
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: "gray",
          }}
        ></IonInput>
        <IonInput
          type="number"
          className="entreprise-input"
          placeholder="telephone"
          value={userInfos?.phone}
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: "gray",
          }}
        ></IonInput>
        <IonInput
          className="entreprise-input"
          placeholder="addresse"
          value={userInfos?.adress}
          style={{
            fontWeight: "bold",
            fontSize: 16,
            color: "gray",
          }}
        ></IonInput>
      </IonCol>
      <IonCol sizemd={12} size={12} className="d-flex justify-content-center">
        <IonButton fill="clear" className="boutton-modifier">
          Modifier
        </IonButton>
      </IonCol>
    </IonRow>
  )
}

export default EntrepriseParam
