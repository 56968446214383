import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react"
import { useState } from "react"
import Vente from "./Vente"
import Confection from "./Confection"
import Produit from "./Produit"

function Stock() {
  const [registerActive, setRegisterActive] = useState(true)

  const handleValue = () => {
    if (registerActive) {
      return "Produits"
    } else if (registerActive === false) {
      return "Confection"
    } else if (registerActive === null) {
      return "Ventes"
    }
  }

  const handleAffichagecomptable = () => {
    if (registerActive) {
      return <Produit />
    } else if (registerActive === false) {
      return <Confection />
    } else {
      return <Vente />
    }
  }

  return (
    <IonContent>
      <IonGrid className="main-page">
        <IonRow>
          <IonCol sizeMd={6}>
            <IonSegment value={handleValue}>
              <IonSegmentButton
                value="produit"
                //   className="boutton-segment-stock"
                onClick={() => {
                  setRegisterActive(true)
                }}
                className={`boutton-segment-stock ${
                  registerActive && "cstm-btn-stock"
                }`}
              >
                Tous les produits
              </IonSegmentButton>
              <IonSegmentButton
                value="confection"
                // className="boutton-segment-stock"
                className={`boutton-segment-stock ${
                  registerActive === false && "cstm-btn-stock"
                }`}
                onClick={() => {
                  setRegisterActive(false)
                }}
              >
                En confection
              </IonSegmentButton>
              <IonSegmentButton
                value="vente"
                // className="boutton-segment-stock"
                className={`boutton-segment-stock ${
                  registerActive === null && "cstm-btn-stock"
                }`}
                onClick={() => {
                  setRegisterActive(null)
                }}
              >
                Prêts à vendre
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>{handleAffichagecomptable()}</IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default Stock
