import { useState } from "react"
import { IonButton, IonIcon } from "@ionic/react"
import { useHistory } from "react-router-dom"
import { CloseButton, Col, Form, Modal } from "react-bootstrap"
import { Toaster } from "react-hot-toast"
import userIcon from "../../images/users.svg"
import axios from "axios"
import { ApiUrl } from "../../utils/http/http"
import Swal from "sweetalert2"
import moment from "moment"
import { createOutline } from "ionicons/icons"
import { COLORS } from "../../utils/constants"

const EditEmploye = ({ employe }) => {
  const history = useHistory()
  const [show, setShow] = useState(false)
  const [data, setData] = useState(employe)
  const [loading, setLoading] = useState(true)
  //   const dateDebut = useState("")
  //   const dateFin = useState("")
  const token = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  const initData = {
    nom_complet: data.nom_complet,
    phone: data.phone,
    cni: data.cni,
    mail: data.mail,
    mensualite: data.mensualite,
    adress: data.adress,
    date_debut: data.date_debut,
    date_fin: data.date_fin,
    type_contrat: data.type_contrat,
    poste: data.poste,
  }

  const onCloseModal = () => {
    setShow(false)
  }

  const handleInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  //Edit Employe request method
  const editEmploye = () => {
    data.date_debut = moment(data.date_debut).format("YYYY-MM-DDTHH:mm:ss")
    data.date_fin = moment(data.date_fin).format("YYYY-MM-DDTHH:mm:ss")
    data.shop = data?.shop?.id
    axios
      .put(ApiUrl + "/employe/" + employe.id + "/", data, token)
      .then(async (response) => {
        console.log("response put", response)
        if (response.status === 200) {
          Swal.fire({
            title: "L'employé est modifié avec succès.",
            icon: "success",
            iconColor: "#2ECC71",
            showCancelButton: false,
            confirmButtonColor: "#2ECC71",
            cancelButtonColor: "#2ECC71",
            confirmButtonText: "OK",
            showConfirmButton: true,
            allowOutsideClick: false,
            timer: 1000,
          }).then(async (result) => {
            if (result.isConfirmed) {
              history.push("/dashboard/employes/")
              setData(initData)
              setTimeout(() => {
                setLoading(false)
              }, 3000)
            }
          })
        }
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title:
            "Une erreur est survenue lors de la modification des éléments.",
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
    setShow(false)
  }

  return (
    <>
      <div
        className="cta-icon"
        style={{ cursor: "pointer" }}
        onClick={() => setShow(true)}
      >
        <IonIcon
          icon={createOutline}
          style={{
            color: COLORS.grayD,
            fontSize: "24px",
          }}
          className="ionIcon"
        />
      </div>
      <span
        className="tableLink"
        style={{
          color: COLORS.grayD,
        }}
      >
        Modifier
      </span>
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header
          style={{
            background: " #E82828",
          }}
        >
          <IonIcon
            slot="start"
            icon={userIcon}
            style={{
              fontSize: "24px",
              alignSelf: "center",
              marginRight: 5,
            }}
            className="modalUserIcon"
          ></IonIcon>
          <Modal.Title
            style={{
              fontSize: 18,
              color: "#FFFFFF",
            }}
          >
            Modifications
          </Modal.Title>
          <CloseButton
            style={{
              color: "#FFF",
              borderRadius: "100%",
              border: "3px solid #FFF",
              padding: 0,
              width: 28,
              marginRight: 5,
              alignSelf: "center",
              opacity: 5,
            }}
            onClick={onCloseModal}
          />
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#F0F0F0",
          }}
        >
          <Form.Group sizemd={12} size={12}>
            <Form.Row>
              <Form.Group
                as={Col}
                style={{
                  background: "#FFFFFF",
                  margin: 10,
                  borderRadius: 5,
                  padding: 20,
                  height: "fit-content",
                }}
              >
                <h2
                  style={{
                    color: "#979797",
                    fontSize: 16,
                    fontWeight: "bold",
                    lineHeight: 2,
                  }}
                >
                  Informations de l'employé
                </h2>
                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Nom complet
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="text"
                      id="nom_complet"
                      name="nom_complet"
                      defaultValue={employe?.nom_complet}
                      placeholder="Tayeur"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Adresse e-mail
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="email"
                      placeholder="tayeur@gmail.com"
                      id="mail"
                      defaultValue={employe?.mail}
                      name="mail"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Téléphone
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="number"
                      placeholder="Téléphone"
                      defaultValue={employe?.phone}
                      id="phone"
                      name="phone"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      CNI
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      minLength={13}
                      maxLength={15}
                      type="number"
                      placeholder="CNI"
                      id="cni"
                      name="cni"
                      defaultValue={employe?.cni}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label className="labelModal">
                      Type de contrat{" "}
                    </Form.Label>
                    <select
                      className="form-control"
                      placeholder="Type de contrat"
                      name="type_contrat"
                      id="type_contrat"
                      multiple={false}
                      onChange={handleInputChange}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        border: "1px solid #797979",
                      }}
                    >
                      <option defaultValue="CDI"> CDI</option>
                      <option defaultValue="CDD">CDD</option>
                      <option defaultValue="Stage"> Stage</option>
                      <option defaultValue="Prestation"> Prestation</option>
                    </select>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="labelModal">Poste </Form.Label>
                    <select
                      className="form-control"
                      placeholder="Poste"
                      name="poste"
                      id="poste"
                      defaultValue={employe?.poste}
                      onChange={handleInputChange}
                      multiple={false}
                      onIonChange={handleInputChange}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        border: "1px solid #797979",
                      }}
                    >
                      <option defaultValue="CDI"> CDI</option>
                      <option defaultValue="CDD">CDD</option>
                      <option defaultValue="Stage"> Stage</option>
                      <option defaultValue="Prestation"> Prestation</option>
                    </select>
                  </Form.Group>
                </Form.Row>

                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label className="labelModal">Mensualité </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="text"
                      placeholder="Mensualité"
                      name="mensualite"
                      id="mensualite"
                      defaultValue={employe?.mensualite}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="labelModal">Adresse </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="text"
                      placeholder="Adresse"
                      name="adress"
                      id="adress"
                      defaultValue={employe?.adress}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Date début
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="date"
                      name="date_debut"
                      id="date_debut"
                      defaultValue={moment(employe?.date_debut).format(
                        "YYYY-MM-DD"
                      )}
                      placeholder="Date début"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Date fin
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="date"
                      name="date_fin"
                      id="date_fin"
                      value={moment(employe?.date_fin).format("YYYY-MM-DD")}
                      placeholder="Date fin"
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Form.Row>
              </Form.Group>
            </Form.Row>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer
          style={{
            backgroundColor: "#F0F0F0",
          }}
          className="d-flex justify-content-center"
        >
          <IonButton loader={loading} onClick={editEmploye}>
            Modifier
          </IonButton>
          <Toaster />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditEmploye
