import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonRow,
} from "@ionic/react"
import { arrowBackOutline, receiptOutline, trashOutline } from "ionicons/icons"
import "./commande.css"
import DetailCommande from "./DetailCommande"
import DetailPaiment from "./DetailPaiment"
import { Link } from "react-router-dom"
import axios from "axios"
import { ApiUrl } from "../../utils/http/http"

function CommandeClient() {
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }
  const archiveCmd = (id) => {
    axios
      .delete(ApiUrl + "/commandes/" + id + "/", config)
      .then((response) => {
        // handle success
        // console.log(response)
      })
      .catch((error) => {
        // handle error
        console.log(error)
      })
  }

  const factureMake = () => {
    console.log("first")
  }
  return (
    <IonContent>
      <IonGrid className="main-page">
        <IonRow>
          <IonCol>
            <Link to="/dashboard/commande/" className="lien-detail-commande">
              <IonButton fill="clear" color="dark" className="button-retour">
                <IonIcon slot="icon-only" icon={arrowBackOutline} />
              </IonButton>
            </Link>
            <IonGrid
              style={{
                padding: "20px 0",
                margin: "20px 30px 0 20px",
              }}
            >
              <h6
                style={{
                  fontWeight: "normal",
                  color: "#666666",
                  fontSize: "14px",
                  fontFamily: "Montserrat",
                }}
              >
                Home {""}
                <span
                  style={{
                    fontWeight: "normal",
                    color: "#E72D32",
                    fontSize: "14px",
                    fontFamily: "Montserrat",
                  }}
                >
                  / Détails commande
                </span>
              </h6>
            </IonGrid>
            <IonCard className="carte-detail-commande">
              <IonCardContent>
                <DetailCommande />
                <DetailPaiment />
                {/* <IonRow>
                  <IonCol className="ion-text-end my-5">
                    <IonButton
                      color="danger"
                      className="archive-btn"
                      onClick={() => archiveCmd()}
                    >
                      <IonIcon slot="start" icon={trashOutline} />
                      Archiver
                    </IonButton>
                    <IonButton
                      color="medium"
                      className="facture-btn"
                      onClick={() => alert("facture")}
                    >
                      <IonIcon slot="start" icon={receiptOutline} />
                      Facture
                    </IonButton>
                  </IonCol>
                </IonRow> */}
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default CommandeClient
