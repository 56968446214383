import { IonContent, IonGrid, IonRow, IonCol } from "@ionic/react"
import "../Dashboard.css"
import CommandeCard from "./CommandeCard"
import ClientCard from "./ClientCard"
import ComptabiliteCard from "./ComptabiliteCard"
import WeeklyCommandes from "./WeeklyCommandes"
import NextWeekCommande from "./NextWeekCommande"
import MonthlyCommande from "./MonthlyCommande"
import { COLORS } from "../../utils/constants"
import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import axios from "axios"
import { ApiUrl } from "../../utils/http/http"

const Dashboard = () => {
  const history = useHistory()

  useEffect(() => {
    getShopList()
    window.addEventListener("popstate", () => {
      history.go(1)
    })
  })

  const getShopList = () => {
    axios({
      method: "GET",
      url: ApiUrl + "/entreprises",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const allShop = res.data.data.map((item) => item.nom_boutique)
      if (allShop === "") {
      }
    })
  }

  return (
    <IonContent
      style={{
        padding: 0,
        "--background": COLORS.gray,
      }}
    >
      <IonGrid
        style={{
          margin: "30px 0 10px 0",
        }}
      >
        <IonRow>
          <IonCol size={12} sizeMd={4}>
            <CommandeCard />
          </IonCol>
          <IonCol size={12} sizeMd={4}>
            <ClientCard />
          </IonCol>
          <IonCol size={12} sizeMd={4}>
            <ComptabiliteCard />
          </IonCol>
        </IonRow>
      </IonGrid>

      {/* :::::::::::::::::::::::: Planning des commandes ::::::::::::::::::::::: */}

      <IonGrid
        style={{
          padding: "10px 0",
          margin: "0 20px",
          background: "#FEFEFE",
          boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
        }}
      >
        <h5
          style={{ fontWeight: 500 }}
          className="ion-text-center planning-title"
        >
          Liste des commandes à livrer
        </h5>
        <IonGrid
          style={{
            padding: "0 10px",
          }}
        >
          <IonRow>
            <IonCol
              style={{
                margin: "10px",
                background: "#FFFFFF",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <WeeklyCommandes />
            </IonCol>
            <IonCol
              style={{
                margin: "10px",
                background: "#FFFFFF",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <NextWeekCommande />
            </IonCol>
            <IonCol
              style={{
                margin: "10px",
                background: "#FFFFFF",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                border: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              <MonthlyCommande />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonGrid>
    </IonContent>
  )
}

export default Dashboard
