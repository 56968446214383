import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSearchbar,
} from "@ionic/react"
import { archiveOutline, eye } from "ionicons/icons"
import { Image } from "react-bootstrap"
import { COLORS } from "../../utils/constants"
import AddEmploye from "./ModalAddEmploye"
import Paiement from "./ModalPaiementEmploye"
import { useHistory } from "react-router-dom"
import React, { useEffect } from "react"
import { ApiUrl } from "../../utils/http/http"
import axios from "axios"
import TableLoader from "../../skeleton/ItemSkeleton"
import EditEmploye from "./ModalEditEmploye"
import Swal from "sweetalert2"

function AllEmployes() {
  const History = useHistory()
  const [employes, setEmployes] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }
  const getEmployes = () => {
    setLoading(true)
    axios
      .get(ApiUrl + "/employes/", config, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        setLoading(false)
        setEmployes(response.data.data)
        console.log("les",employes)
      })
      .catch((error) => {
        console.log("Error lors du chargement des données", error)
        setLoading(false)
      })
  }

  useEffect(() => {
    const timer = setTimeout(() => getEmployes(), 1000)
    return () => clearTimeout(timer)
  }, [])

  const onArchive = (id) => {
    axios
      .delete(ApiUrl + "/employe/" + id + "/", config)
      .then((response) => {
        console.log("response employe id ", response)
      })
      .catch((error) => console.log("erreur", error))
  }
  const handleRemove = (id) => {
    Swal.fire({
      title: "Vous êtes sûr de vouloir archiver cet employé ?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: `Non`,
      confirmButtonColor: "#2ECC71",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      iconColor: "#F1C40F",
      showConfirmButton: true,
      allowOutsideClick: false,
      timer: 5000,
    }).then((result) => {
      if (result.isConfirmed) {
        setEmployes(employes.filter((item) => item.id !== id))
        onArchive(id)
      }
    })
  }

  const columns = ["Nom", "Spécialité", "Numero", "Adresse", "Actions"]

  return (
    <>
      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "40px 0",
        }}
      >
        <IonRow className="ion-align-items-center">
          <IonCol
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <IonLabel
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                margin: 10,
              }}
            >
              Filtre
            </IonLabel>
            <IonSearchbar
              value=""
              showCancelButton="never"
              placeholder="Produit"
              style={{
                "--border-radius": "27.67px",
                "--box-shadow": "none",
                "--background": "#F7F7F7",
                marginBottom: "5px",
                width: "50%",
              }}
            ></IonSearchbar>
          </IonCol>
          <AddEmploye />
        </IonRow>
      </IonGrid>
      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "0",
        }}
      >
        <IonRow
          className="ion-align-items-center"
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
            position: "sticky",
            background: "#F8F8F8",
          }}
        >
          {columns.map((column, index) => (
            <IonCol
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              key={index}
            >
              {column}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      {loading && <TableLoader />}
      {!loading && (
        <IonGrid
          style={{
            margin: "0 10px",
            background: "#FFFFFF",
          }}
          className="myContent"
        >
          {employes.map((item) => (
            <IonRow
              key={item.id}
              style={{
                padding: "15px 0 15px 30px",
                borderBottom: "1px solid #F5F5F5",
              }}
              className="ion-align-items-center"
            >
              <IonAvatar slot="start">
                <Image src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y" />
              </IonAvatar>
              <IonCol>{item?.nom_complet}</IonCol>
              <IonCol>{item?.poste}</IonCol>
              <IonCol>{item?.phone}</IonCol>
              <IonCol>{item?.adress}</IonCol>

              <div
                className="col-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <div
                  className="cta-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    History.push("/dashboard/informations-employes/" + item?.id)
                  }
                >
                  <IonIcon
                    icon={eye}
                    style={{
                      color: COLORS.redS,
                      fontSize: "24px",
                    }}
                    className="ionIcon"
                  />
                </div>
                <span
                  className="tableLink"
                  style={{
                    color: COLORS.redS,
                  }}
                >
                  Details
                </span>
                <EditEmploye employe={item} />
                <Paiement />
                <div
                  className="cta-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRemove(item?.id)}
                >
                  <IonIcon
                    icon={archiveOutline}
                    style={{
                      color: COLORS.greenP,
                      fontSize: "24px",
                    }}
                    className="ionIcon"
                  />
                </div>
                <div
                  className="tableLink"
                  style={{
                    color: COLORS.greenP,
                  }}
                >
                  Archive
                </div>
              </div>
            </IonRow>
          ))}
        </IonGrid>
      )}
    </>
  )
}

export default AllEmployes
