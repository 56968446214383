import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react"
import React, { useEffect, useState } from "react"
import { Image } from "react-bootstrap"
import Historique from "./HistoPaiement"
import { useParams } from "react-router-dom"
import Perso from "./InfoPerso"
import axios from "axios"
import { ApiUrl, baseBackendUrl } from "../../../utils/http/http"

const AllInfos = () => {
  const [registerActive, setRegisterActive] = useState(true)
  const [info, setInfo] = React.useState({})
  const params = useParams()
  const { id } = params
  const getInfoEmploye = () => {
    const token = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
    axios
      .get(ApiUrl + "/employe/" + id + "/", token)
      .then((response) => {
        console.log("responseDb", response.data)
        setInfo(response.data)
      })
      .catch((err) => {
        console.log("erro", err)
      })
  }
  useEffect(() => {
    getInfoEmploye()
  }, [id])

  const handleValue = () => {
    if (registerActive) {
      return "InfoPerso"
    } else if (registerActive === null) {
      return "HistoPaiement"
    }
  }

  const handleAffichagecomptable = () => {
    if (registerActive) {
      return <Perso info={info} />
    } else {
      return <Historique info={info} />
    }
  }

  return (
    <>
      <IonContent
        style={{
          background: "#E5E5E5",
        }}
      >
        <IonGrid
          style={{
            borderRadius: 10,
            background: "#FFF",
            boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
          }}
        >
          <IonRow>
            <IonCol
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Image
                style={{ width: 150, height: "auto", borderRadius: 100 }}
                src={
                  info?.avatar
                    ? baseBackendUrl + info?.avatar
                    : " https://ui-avatars.com/api/?name=Info-Perso"
                }
              />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizemd={5}>
              <IonSegment value={handleValue}>
                <IonSegmentButton
                  value="InfoPerso"
                  onClick={() => {
                    setRegisterActive(true)
                  }}
                  className={`boutton-segment-stock ${
                    registerActive && "cstm-btn-stock"
                  }`}
                >
                  Informations personnelles
                </IonSegmentButton>
                <IonSegmentButton
                  value="HistoPaiement"
                  className={`boutton-segment-stock ${
                    registerActive === null && "cstm-btn-stock"
                  }`}
                  onClick={() => {
                    setRegisterActive(null)
                  }}
                >
                  Paiement
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>{handleAffichagecomptable()}</IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  )
}

export default AllInfos
