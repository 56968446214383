import React, { useCallback, useEffect, useState } from "react"

import { useHistory } from "react-router-dom"
import { mailOutline } from "ionicons/icons"
import { IonCol, IonContent, IonRow } from "@ionic/react"

import { COLORS } from "../../utils/constants"
import appName from "../../images/appName.png"
import Input from "../../shared/Input"
import { useFormData, useFormValidation } from "../../shared/Form"
import { AppButton2 } from "../../shared/AppButton"
import axios from "axios"
import { ApiUrl } from "../../utils/http/http"
import toast from "react-hot-toast"

const ForgotPassword = () => {
  const history = useHistory()
  useEffect(() => {
    document.title = "Tayeur-Modifier votre mot de passe"
  })

  const initialState = {
    email: "",
  }
  const [data, setData] = useFormData(initialState)
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  const [errors, validate] = useFormValidation({}, (yup) => {
    return {
      email: yup
        .string()
        .email("Email Invalide")
        .required("Email Obligatoire")
        .label("Email"),
    }
  })

  const handleSubmit = useCallback(() => {
    axios(
      {
        method: "POST",
        url: ApiUrl + "/changepassword/", 
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data,
      },
      validate(data)
    )
      .then((res) => {
        setLoading(true)
        console.log(
          "la réinitialisation du mot de passe a été envoyé dans votre mail"
        )
        toast.success("le mail a été envoyé avec succès !!!")
        setTimeout(() => {
          setLoading(false)
          history.push("/")
        }, 2000)
      })
      .catch((ex) => null)
  }, [data, history, validate])

  return (
    <IonContent
      style={{
        backGround: COLORS.app,
        padding: 0,
      }}
    >
      <IonRow>
        <IonCol
          size={12}
          sizemd={6}
          className="loginLeft d-flex  align-items-center justify-content-center"
        >
          <img src={appName} alt="Tayeur" />
        </IonCol>
        <IonCol
          size={12}
          sizemd={6}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <h1
            style={{
              size: "24px",
              lineHeight: "29.6px",
              fontWeight: 600,
              textAlign: "center",
            }}
            className="mb-3"
          >
            Modifier votre mot de passe
          </h1>
          <div className="d-block mt-5">
            <Input
              icon={mailOutline}
              placeholder="Email"
              type="email"
              error={errors.email}
              value={data.email}
              onIonChange={handleChange}
              id="email"
            />
          </div>
          <div className="loginLeftContain">
            <AppButton2
              title="Envoyer"
              onClick={handleSubmit}
              loading={loading}
            />
          </div>
        </IonCol>
      </IonRow>
    </IonContent>
  )
}

export default ForgotPassword
