import { IonCol, IonGrid, IonItem, IonRow } from "@ionic/react"
import moment from "moment"
const Perso = ({ info }) => {
  return (
    <>
      <IonGrid
        style={{
          borderRadius: 10,
          background: "#FEFEFE",
          boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
        }}
      >
        <IonRow>
          <IonCol
            style={{
              borderBottom: " 5px solid ##C4C4C4",
            }}
            size={12}
            sizeSm={6}
            sizemd={6}
            sizeLg={12}
            sizeXl={12}
          >
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>Nom complet</IonItem>
              </IonCol>
              <IonCol>
                <IonItem>{info?.nom_complet}</IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>Téléphone</IonItem>
              </IonCol>
              <IonCol>
                <IonItem>{info?.phone}</IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>Numero CNI</IonItem>
              </IonCol>
              <IonCol>
                <IonItem>{info?.cni}</IonItem>
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={12} sizeXl={12}>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>Adresse</IonItem>
              </IonCol>
              <IonCol>
                <IonItem>{info?.adress}</IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>Adresse e-mail</IonItem>
              </IonCol>
              <IonCol>
                <IonItem>{info?.mail}</IonItem>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          borderRadius: 10,
          background: "#FEFEFE",
          boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
          marginTop: 20,
          alignItems: "center",
        }}
      >
        <IonRow>
          <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={12} sizeXl={12}>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>Poste ou Spécialité</IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <b>{info?.poste?.libelle}</b>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>Type de contrat</IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <b>{info?.type_contrat?.libelle}</b>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>Mensualité</IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <b>{info?.mensualite}</b>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={12} sizeXl={12}>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>Date de debut</IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <b>{moment(info?.date_debut).format("DD-MM-YYYY ")} </b>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>Date de fin</IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <b>{moment(info?.date_fin).format("DD-MM-YYYY")}</b>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  )
}
export default Perso
