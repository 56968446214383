import { useState } from "react"
import { IonCol, IonRow } from "@ionic/react"
import { Button, Form, Modal } from "react-bootstrap"
import ButtonI from "../../shared/Button"
import { ApiUrl } from "../../utils/http/http"
import axios from "axios"
import moment from "moment"
import Swal from "sweetalert2"

const AddEntree = () => {
  //   let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
    const dateCreat = useState("")
    const initData = {
      libelle: "",
      prix: "",
      created_at: moment(dateCreat).format("YYYY-MM-DDTHH:mm:ss"),
      // shop: userInfos.id_boutique,
    }
    const [data, setData] = useState(initData)
  
    const token = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }

    const handleInputChange = (e) => {
      setData({ ...data, [e.target.name]: e.target.value })
    }

    const expends = () => {
      data.created_at = moment(data.created_at).format("YYYY-MM-DDTHH:mm:ss")
      axios
        .post(ApiUrl + "/expenses/", data, token)
        .then((response) => {
          console.log("data", response)
          Swal.fire({
            icon: "success",
            timer: 1000,
            confirmButtonColor: "#1ee172",
          })
        })
        .catch((error) => {
          console.log(error)
          Swal.fire({
            icon: "error",
            title: "Erreur s'est produite",
            timer: 3000,
            confirmButtonColor: "red",
          })
        })
      setShow(false)
    }

  return (
    <>
      <IonCol className="boutton-add">
        <ButtonI label="Ajouter une entrée" onClick={() => setShow(true)} />
      </IonCol>
      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Body className="p-5">
          <IonRow className="d-flex ion-justify-content-end">
            <Form.Control
              className="col-xs-6 col-md-6"
              style={{
                borderRadius: "5px",
                background: "#FFF",
                outline: "none",
                marginBottom: 10,
                height: "50px",
                border: "1px solid #797979",
              }}
              type="date"
              name="created_at"
              id="created_at"
            />
          </IonRow>
          <Form.Label className="labelModal">Désignation </Form.Label>
          <Form.Control
            style={{
              borderRadius: "5px",
              background: "#FFF",
              outline: "none",
              marginBottom: 10,
              height: "50px",
              border: "1px solid #797979",
            }}
            type="text"
            placeholder="Désignation"
            name="libelle"
            id="libelle"
          />
          <Form.Label className="labelModal">Prix </Form.Label>
          <Form.Control
            style={{
              borderRadius: "5px",
              background: "#FFF",
              outline: "none",
              marginBottom: 10,
              height: "50px",
              border: "1px solid #797979",
            }}
            type="number"
            placeholder="Prix"
            name="prix"
            id="prix"
          />
        </Modal.Body>
        <Modal.Footer className="d-flex ion-justify-content-center">
          <Button variant="danger" onClick={() => expends()} >Valider</Button>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddEntree
