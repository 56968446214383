import {
  IonCol,
  IonContent,
  IonIcon,
  IonRadio,
  IonRow,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonListHeader,
} from "@ionic/react"
import { arrowBackOutline } from "ionicons/icons"
import React, { useEffect, useState } from "react"
import axios from "axios"
import AppButton from "../../src/shared/AppButton"
import Input from "../shared/Input"
import { useFormData, useFormValidation } from "../../src/shared/Form"
import { COLORS } from "../utils/constants"
import { Link, useHistory } from "react-router-dom"
import { ApiUrl } from "../../src/utils/http/http"
import toast from "react-hot-toast"
// import { Connect } from "react-redux";

const AddClient = (props) => {
  const history = useHistory()
  const initialState = {
    nom_complet: "",
    phone: "",
    poitrine: "",
    autre: "",
    epaule: "",
    longueur_manche: "",
    cou: "",
    ceinture: "",
    tour_bras: "",
    hanche: "",
    cuisse: "",
    longueur_haut: "",
    longueur_pantalon: "",
    longueur_boubou: "",
    longueur_jupe: "",
    longueur_poitrine: "",
    taille: "",
    bretelle: "",
    longueur_robe: "",
    shop: 0,
  }

  useEffect(() => {
    document.title = "Tayeur-Ajout d'un client"
    localStorage.getItem("token")
    localStorage.getItem("id")
  }, [])

  const [data, setData] = useFormData(initialState)
  const [loading, setLoading] = React.useState(false)

  // const AddClient = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //  console.log(AddClient);

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value })
    // console.log(e.target.value)
  }

  const [errors, validate] = useFormValidation({}, (yup) => {
    return {
      nom_complet: yup
        .string()
        .required("Prénom et Nom Obligatoire *")
        .label("Nom Complet"),
      phone: yup
        .string()
        .min(9, "trop court!")
        .max(15, "trop long!")
        .required("Numero de Telephone Obligatoire *")
        .label("Telephone"),
    }
  })

  const [status, setStatus] = useState(0)

  const statusCheck = (status) => {
    setStatus(status)
  }

  const [disabled, setDisabled] = useState(0)

  const handleSubmit = React.useCallback(
    (users) => {
      const shop = users.id
      axios(
        {
          method: "POST",
          url: ApiUrl + "/clients/" + shop,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data,
        },
        validate(data)
      )
        .then(() => {
          setLoading(true)
          setTimeout(() => {
            setLoading(false)
            history.push("/dashboard/clients")
          }, 2000)
          toast.success("Bravo ajout client réussi")
        })
        .catch((ex) => {
          console.log(ex)
          toast.error("Echec de l'ajout du client !!!")
        })
    },
    [data]
  )

  return (
    <IonContent
      style={{
        padding: "",
        "--background": COLORS.gray,
      }}
    >
      <IonRow
        style={{
          paddingLeft: "30px",
          marginTop: "20px",
          marginBottom: 0,
          paddingBottom: 0,
          position: "relative",
        }}
      >
        <Link
          to="/dashboard/clients"
          style={{ color: "#000", cursor: "pointer", position: "relative" }}
        >
          <IonIcon
            icon={arrowBackOutline}
            style={{
              fontSize: "30px",
              marginBottom: 0,
              paddingBottom: 0,
            }}
            className="iconpointer"
          ></IonIcon>
        </Link>
      </IonRow>
      <IonRow style={{ margin: 0 }}>
        <IonCol size={12} style={{ padding: "30px", margin: 0 }}>
          <div className="px-4 py-1" style={{ backgroundColor: "white" }}>
            <h4 className="h4 row">Informations Clients</h4>
            <div className="bar row">
              <div className="col-12 col-md-6 p-0 pr-3">
                <Input
                  label="Nom et Prénom:"
                  type="text"
                  error={errors.nom_complet}
                  id="nom_complet"
                  name="nom_complet"
                  value={data.nom_complet}
                  onIonChange={handleChange}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />
                <Input
                  label="Numero de téléphone:"
                  type="number"
                  error={errors.phone}
                  id="phone"
                  name="phone"
                  value={data.phone}
                  onIonChange={handleChange}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />
                <IonRadioGroup
                  value={data.autre}
                  onIonChange={handleChange}
                  id="autre"
                  name="autre"
                  // error={errors.sexe}
                >
                  <IonRow>
                    <IonCol size={12} sizeSm={3}>
                      <IonListHeader>
                        <IonLabel
                          style={{
                            fontWeight: 500,
                            fontStyle: "normal",
                            fontSize: "14px",
                            lineHeight: "17.07px",
                          }}
                        >
                          Sexe:
                        </IonLabel>
                      </IonListHeader>
                    </IonCol>
                    <IonCol size={6} sizeSm={4}>
                      <IonItem
                        lines="none"
                        style={{
                          padding: 0,
                        }}
                      >
                        <IonLabel
                          style={{
                            fontWeight: 500,
                            fontStyle: "normal",
                            fontSize: "14px",
                            lineHeight: "17.07px",
                          }}
                        >
                          H
                        </IonLabel>
                        <IonRadio
                          slot="start"
                          value="homme"
                          checked={status === 1}
                          onClick={(e) => {
                            statusCheck(1)
                            setDisabled(1)
                          }}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol size={6} sizeSm={4}>
                      <IonItem lines="none">
                        <IonLabel
                          style={{
                            fontWeight: 500,
                            fontStyle: "normal",
                            fontSize: "14px",
                            lineHeight: "17.07px",
                          }}
                        >
                          F
                        </IonLabel>
                        <IonRadio
                          slot="start"
                          value="femme"
                          checked={status === 2}
                          onClick={(e) => {
                            statusCheck(2)
                            setDisabled(1)
                          }}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonRadioGroup>
              </div>
            </div>
            <div className="">
              <h4 className="h4 row">Mesure du Client</h4>
              <div className="bar row ">
                <div className="col-12 col-md-6 p-0 pr-3">
                  <Input
                    label="Epaule:"
                    type="number"
                    min="0"
                    id="epaule"
                    name="epaule"
                    value={data.epaule}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <Input
                    label="Poitrine:"
                    type="number"
                    min="0"
                    id="poitrine"
                    name="poitrine"
                    value={data.poitrine}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <Input
                    label="Manche:"
                    type="number"
                    min="0"
                    id="longueur_manche"
                    name="longueur_manche"
                    value={data.longueur_manche}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <Input
                    label="Cou:"
                    type="number"
                    min="0"
                    id="cou"
                    name="cou"
                    value={data.cou}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <Input
                    label="Ceinture:"
                    type="number"
                    min="0"
                    id="ceinture"
                    name="ceinture"
                    value={data.ceinture}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <Input
                    label="Tours de bras:"
                    type="number"
                    min="0"
                    id="tour_bras"
                    name="tour_bras"
                    value={data.tour_bras}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <Input
                    label="Tours de hanches:"
                    type="number"
                    min="0"
                    id="hanche"
                    name="hanche"
                    value={data.hanche}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <Input
                    label="Tours de cuisses:"
                    type="number"
                    min="0"
                    id="cuisse"
                    name="cuisse"
                    value={data.cuisse}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <Input
                    label="Longueur haut:"
                    type="number"
                    min="0"
                    id="longueur_haut"
                    name="longueur_haut"
                    value={data.longueur_haut}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />

                  <Input
                    label="Longueur bas:"
                    type="number"
                    min="0"
                    id="longueur_pantalon"
                    name="longueur_pantalon"
                    value={data.longueur_pantalon}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <Input
                    label="Longueur boubou:"
                    type="number"
                    min="0"
                    id="longueur_boubou"
                    name="longueur_boubou"
                    value={data.longueur_boubou}
                    onIonChange={handleChange}
                    disabled={disabled === 0}
                    className={disabled === 0 ? "inputActive" : "flex-fill"}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  {status === 1 && null}
                  {status === 2 && (
                    <div>
                      <Input
                        label="Longueur jupe:"
                        type="number"
                        min="0"
                        id="longueur_jupe"
                        name="longueur_jupe"
                        value={data.longueur_jupe}
                        onIonChange={handleChange}
                        style={{
                          height: "50px",
                          borderRadius: "5px",
                          background: "#FFF",
                          outline: "none",
                          border: "1px solid #797979",
                        }}
                      />
                      <Input
                        label="Poitrine:"
                        type="number"
                        min="0"
                        id="longueur_poitrine"
                        name="longueur_poitrine"
                        value={data.longueur_poitrine}
                        onIonChange={handleChange}
                        style={{
                          height: "50px",
                          borderRadius: "5px",
                          background: "#FFF",
                          outline: "none",
                          border: "1px solid #797979",
                        }}
                      />
                      <Input
                        label="Tour de taille:"
                        type="number"
                        min="0"
                        id="taille"
                        name="taille"
                        value={data.taille}
                        onIonChange={handleChange}
                        style={{
                          height: "50px",
                          borderRadius: "5px",
                          background: "#FFF",
                          outline: "none",
                          border: "1px solid #797979",
                        }}
                      />
                      <Input
                        label="Longueur bras:"
                        type="number"
                        min="0"
                        id="bretelle"
                        name="bretelle"
                        value={data.bretelle}
                        onIonChange={handleChange}
                        style={{
                          height: "50px",
                          borderRadius: "5px",
                          background: "#FFF",
                          outline: "none",
                          border: "1px solid #797979",
                        }}
                      />
                      <Input
                        label="Longueur robe:"
                        type="number"
                        min="0"
                        id="longueur_robe"
                        name="longueur_robe"
                        value={data.longueur_robe}
                        onIonChange={handleChange}
                        style={{
                          height: "50px",
                          borderRadius: "5px",
                          background: "#FFF",
                          outline: "none",
                          border: "1px solid #797979",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <IonRow className="ion-align-items-center">
              <IonCol
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                size={12}
                sizeSm={6}
                sizemd={6}
                sizeLg={6}
                sizeXl={6}
              >
                <AppButton
                  loading={loading}
                  onClick={handleSubmit}
                  title="Valider"
                />
              </IonCol>
            </IonRow>
          </div>
        </IonCol>
      </IonRow>
    </IonContent>
  )
}

export default AddClient
