import { IonButton } from "@ionic/react"

const ButtonI = ({ label, onClick }) => {
  return (
    <button
      strong="true"
      style={{
        textTransform: "none",
        width: "176px",
        height: "42px",
        fontSize: "12px",
        lineHeight: "17px",
        marginLeft: "10px",
        borderRadius: "5px",
        fontWeight: 600,
        border: "2px solid #E82828",
      }}
      onClick={onClick}
      className="buttoni mb-2 mb-md-0"
    >
      {label}
    </button>
  )
}

export const AddButton = ({ title, style, bgColor = "#E72D32" }) => {
  return <IonButton style={style}>{title}</IonButton>
}

export default ButtonI
