import {
  IonAvatar,
  IonButton,
  IonCol,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRow,
  IonSearchbar,
} from "@ionic/react"
import {
  archiveOutline,
  calendarClearOutline,
  cartOutline,
  createOutline,
} from "ionicons/icons"
import { Button, Image } from "react-bootstrap"
import { COLORS } from "../../utils/constants"

function PaiementEmployes() {
  const columns = [
    "Photo",
    "Date",
    "Nom",
    "Spécialité",
    "Statut",
    "Montant",
    "Actions",
  ]

  return (
    <>
      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "40px 0",
        }}
      >
        <IonRow className="ion-align-items-center">
          <IonCol
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <IonLabel
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                margin: 10,
              }}
            >
              Annee
            </IonLabel>
            <div>
              <IonButton
                style={{
                  height: 40,
                  width: 140,
                  backgroundColor: "#F7F7F7",
                  borderRadius: 10,
                }}
                fill="clear"
                className="boutton-date-picker"
              >
                <select
                  style={{
                    width: 142,
                    height: 45,
                    overflow: "auto",
                    backgroundColor: "#F7F7F7",
                    borderRadius: 10,
                  }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>Choisissez l'annee</option>
                  <option value="1">2022</option>
                </select>{" "}
              </IonButton>
            </div>
            <IonLabel
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                margin: 10,
              }}
            >
              Mois
            </IonLabel>{" "}
            <IonButton
              style={{
                height: 40,
                width: 140,
                backgroundColor: "#F7F7F7",
                borderRadius: 10,
              }}
              fill="clear"
              className="boutton-date-picker"
            >
              <select
                style={{
                  width: 142,
                  height: 45,
                  overflow: "auto",
                  backgroundColor: "#F7F7F7",
                  borderRadius: 10,
                }}
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>Choisissez le mois</option>
                <option value="1">Janvier</option>
              </select>{" "}
            </IonButton>
          </IonCol>

          <IonCol lg={12} sizemd={4} className="d-flex ion-justify-content-end">
            <IonButton
              style={{ border: "2px solid #E82828", borderRadius: 10 }}
              fill="clear"
              className="boutton"
            >
              Export to excel
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow className="ion-align-items-center">
          <IonCol
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <IonLabel
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                margin: 10,
              }}
            >
              Filtre
            </IonLabel>
            <IonSearchbar
              value=""
              showCancelButton="never"
              placeholder="Produit"
              style={{
                "--border-radius": "27.67px",
                "--box-shadow": "none",
                "--background": "#F7F7F7",
                marginBottom: "5px",
                width: "25%",
              }}
            ></IonSearchbar>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "0",
        }}
      >
        <IonRow
          className="ion-align-items-center"
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
            position: "sticky",
            background: "#F8F8F8",
          }}
        >
          {columns.map((column, index) => (
            <IonCol
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              key={index}
            >
              {column}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
        }}
        className="myContent"
      >
        <IonRow
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>
            <Image
              style={{ width: 50, heigth: "auto" }}
              src="https://avatars.dicebear.com/v2/avataaars/112a794662513310b1044d95b6889cff.svg"
            />
          </IonCol>
          <IonCol>01/01/2020</IonCol>
          <IonCol>Modou Fall</IonCol>
          <IonCol>Tayeur</IonCol>
          <IonCol>
            <Button className="btn btn-success">Payé</Button>
          </IonCol>
          <IonCol>500 000 f</IonCol>
          <div
            className="col-2 pl-0"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <div className="cta-icon" style={{ cursor: "pointer" }}>
              <IonIcon
                icon={createOutline}
                style={{
                  color: COLORS.grayD,
                  fontSize: "24px",
                }}
                className="ionIcon"
              />
            </div>
            <span
              className="tableLink"
              style={{
                color: COLORS.grayD,
              }}
            >
              Modifier
            </span>{" "}
            <div className="cta-icon" style={{ cursor: "pointer" }}>
              <IonIcon
                icon={archiveOutline}
                style={{
                  color: COLORS.greenP,
                  fontSize: "24px",
                }}
                className="ionIcon"
              />
            </div>
            <div
              className="tableLink"
              style={{
                color: COLORS.greenP,
              }}
            >
              Archive
            </div>
          </div>
        </IonRow>
      </IonGrid>
    </>
  )
}

export default PaiementEmployes
