import { IonButton, IonCol, IonGrid, IonIcon, IonItem } from "@ionic/react"
import { arrowBackOutline, cartOutline } from "ionicons/icons"
import { useState } from "react"
import { CloseButton, Modal } from "react-bootstrap"
import { COLORS } from "../../utils/constants"
import Produit from "./productPanier"
import { Link } from "react-router-dom"
import userIcon from "../../images/users.svg"

const Panier = () => {
  const [show, setShow] = useState(false)

  return (
    <>
      <div className="cart align-items-end ">
        <div
          className="cta-icon"
          style={{
            cursor: "pointer",
          }}
          onClick={() => setShow(true)}
        >
          <IonIcon
            icon={cartOutline}
            style={{
              color: COLORS.redS,
              fontSize: 50,
              opacity: 5,
            }}
            className="ionIcon"
          />{" "}
        </div>
        <span
          className="tableLink"
          style={{
            color: COLORS.redS,
          }}
        >
          Panier
        </span>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <IonIcon
            slot="start"
            icon={userIcon}
            style={{
              fontSize: "24px",
              alignSelf: "center",
              marginRight: 5,
            }}
            className="modalUserIcon"
          ></IonIcon>{" "}
          <IonIcon
            slot="start"
            icon={userIcon}
            style={{
              fontSize: "24px",
              alignSelf: "center",
              marginRight: 5,
            }}
            className="modalUserIcon"
          ></IonIcon>
          <Modal.Title id="example-custom-modal-styling-title">
            <Link to="/dashboard" className="lien-detail-commande">
              <IonButton fill="clear" color="dark" className="button-retour">
                <IonIcon slot="icon-only" icon={arrowBackOutline} />
              </IonButton>
            </Link>
          </Modal.Title>
          <CloseButton
            style={{
              color: "#FFF",
              borderRadius: "100%",
              border: "3px solid #FFF",
              padding: 0,
              width: 28,
              marginRight: 5,
              alignSelf: "center",
              opacity: 5,
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <IonGrid
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <IonCol
              style={{
                margin: 20,
                display: "flex",
                justifyContent: "start",
              }}
            >
              <select
                style={{
                  width: 142,
                  height: 45,
                  overflow: "auto",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #C4C4C4",
                  borderRadius: 5,
                }}
                className="form-select"
                aria-label="Default select example"
              >
                <option selected>Choisissez un client</option>
                <option value="1">Adja</option>
                <option value="1">Adja</option>
                <option value="1">Adja</option>
                <option value="1">Adja</option>
                <option value="1">Adja</option>
              </select>
            </IonCol>
            <IonCol
              style={{
                margin: 20,
                display: "flex",
                justifyContent: "end",
              }}
            >
              <IonItem>Fature 004</IonItem>
            </IonCol>
          </IonGrid>

          <Produit />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Panier
