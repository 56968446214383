import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react"
import { addOutline } from "ionicons/icons"
import { Toaster } from "react-hot-toast"
import AppButton from "../../shared/AppButton"
import Input from "../../shared/Input"
import { COLORS } from "../../utils/constants"
import { useParams } from "react-router-dom"
import "./EditCommande.css"
import React, { useEffect } from "react"
import axios from "axios"
import { ApiUrl, baseBackendUrl } from "../../utils/http/http"
import Modals from "../../components/Modal"
import { Button, Image } from "react-bootstrap"
import moment from "moment"
import Swal from "sweetalert2"
import { useHistory } from "react-router-dom"

const EditCommande = () => {
  const history = useHistory()
  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const params = useParams()
  const { id } = params
  const [detail, setDetail] = React.useState({})
  // const [showModal, setShowModal] = React.useState(false)
  const [dataEx, setDataEx] = React.useState([])
  const [modelEx, setModelEx] = React.useState([])
  const [tissus, setTissus] = React.useState([
    {
      tissu: "",
      prix: "",
      images: [],
    },
  ])
  const [taxes, setTaxes] = React.useState({
    reduction: "",
    tva: 0,
  })
  const [global, setGlobal] = React.useState({
    restant: 0,
    montant: 0,
    montant_after_reduction: 0,
    net_payer: 0,
  })
  const [paiement, setPaiement] = React.useState(0)
  const [dateLivraion, setDateLivraion] = React.useState("")
  const [showModal, setShowModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [clients, setClients] = React.useState([])
  const [client, setClient] = React.useState("")

  useEffect(() => {
    fetchCommandes();
  }, [id]);
  
  useEffect(() => {
    fetchClients();
  }, []);
  
  const handleClose = () => {
    setShowModal((show) => !show);
  };

  const handleRemove = (index) => {
    if (index > 0) {
      const newTissus = [...tissus]
      Swal.fire({
        title: "Vous êtes sûr de vouloir supprimer cet élément ?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: `Annuler`,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.isConfirmed) {
          setTissus(newTissus.filter((t, i) => i !== index))
        }
      })
    }
  }

  const onDeleteDataEx = (id) => {
    axios
    .delete(`${ApiUrl}/detail_commande/${id}/`)
      .then((response) => {
        console.log(response)
      })
      .catch((error) => console.log(error))
  }

  const removeDataGrid = (ind) => {
    const newData = [...dataEx]
    Swal.fire({
      title: "Vous êtes sûr de vouloir supprimer cet élément ?",
      icon: "warning",
      iconColor: "#F1C40F",
      showCancelButton: true,
      cancelButtonText: `Non`,
      confirmButtonColor: "#2ECC71",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
    }).then((result) => {
      if (result.isConfirmed) {
        if (ind !== -1) {
          const id = newData[ind].id
          setDataEx(newData.filter((t, i) => i !== ind));
          onDeleteDataEx(id)
        }
      }
    });
  };

  const handleTissuChange = (e, tissu) => {
    let newTissu;
    if (e.target.name === "images") {
      // console.log("image", e.target.files)
      newTissu = { ...tissu, images: [...tissu.images, e.target.files[0]] };
    } else {
      newTissu = { ...tissu, [e.target.name]: e.detail.value };
    }
    const index = tissus.indexOf(tissu);
    const updatedTissus = [...tissus];
    updatedTissus[index] = newTissu;
    setTissus(updatedTissus);
  };
  

  const handleDeleteImage = (image, tissu) => {
    const newTissu = {
      ...tissu,
      images: tissu.images.filter((i) => i !== image),
    };
    const index = tissus.indexOf(tissu);
    const updatedTissus = [...tissus];
    updatedTissus[index] = newTissu;
    setTissus(updatedTissus);
  };

 const handleTaxesChange = (e) => {
  const newTaxes = { ...taxes, [e.target.name]: e.detail.value };
  setTaxes(newTaxes);
};


const handleNewInput = () => {
  const dynamics = {
    tissu: "",
    prix: "",
    images: [],
  };
  setTissus((prevTissus) => [...prevTissus, dynamics]);
};

  const config3 = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  
  const fetchCommandes = () => {
    axios
      .get(`${ApiUrl}/commande/` + id + "/", config3)
      .then(async (response) => {
        console.log("first", response.data)
        setDetail(response.data)
        setGlobal({
          restant: response.data.restant,
          montant: response.data.montant,
          montant_after_reduction: response.data.montant_after_reduction,
          net_payer: response.data.net_payer,
        })
        setTaxes({
          reduction: response.data.reduction,
          tva: response.data.tva,
        })
        setDataEx(response.data.commande_detail_cmd)
        setModelEx(response.data.commande_modele)
        setClient(response.data.client.id)
        setDateLivraion(
          moment(response.data.date_livraison).format("YYYY-MM-DD")
        )
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const fetchClients = () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
    axios
      .get(`${ApiUrl}/clients/` + userInfos.id_boutique + "/", config)
      .then((response) => {
        setClients(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const editCommande = () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
    const config2 = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    }
    const initialData = {
      client: client,
      shop: userInfos.id_boutique,
      restant: global.restant,
      montant: global.montant,
      reduction: global.reduction,
      montant_after_reduction: global.montant_after_reduction,
      net_payer: global.net_payer,
      tva: taxes.tva,
      net_financier: paiement,
      commande_detail_cmd: tissus.map((t) => ({
        prix: t.prix,
        tissu: t.tissu,
      })),
      commande_modele: [
        // {
        //   image: "",
        //   index: "",
        // },
      ],
      date_livraison: moment(dateLivraion).format("YYYY-MM-DDTHH:mm:ss"),
    }
    setLoading(true)
    console.log("id", id)
    axios
      .put(ApiUrl + "/commande/" + id + "/", initialData, config)
      // .put(`${ApiUrl}/commande/${id}/`, initialData, config)
      .then(async (response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "Commande modifiée avec succès.",
            icon: "success",
            iconColor: "#2ECC71",
            showCancelButton: false,
            confirmButtonColor: "#2ECC71",
            cancelButtonColor: "#2ECC71",
            confirmButtonText: "OK",
            showConfirmButton: true,
            allowOutsideClick: false,
            timer: 1000,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // console.log("commande", response.data)
              const commandeDetail = response.data.commande_detail_cmd
              for (let i = 0; i < commandeDetail.length; i++) {
                const item = commandeDetail[i]
                const images = tissus[i].images
                for (let img of images) {
                  const fdata = new FormData()
                  fdata.append("image", img)
                  fdata.append("detail_cmd", item.id)
                  fdata.append("commandes", response.data.id)
                  await axios.post(ApiUrl + "/modeles/", fdata, config2)
                }
              }
              setLoading(false)
              setDetail(initialData)
            }
          })
          history.push("/dashboard/commande/")
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title:error.message,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  useEffect(() => {
    const pf = (n) => {
      return parseFloat(n) || 0
    }
    const montant_global_ex = dataEx.reduce((acc, current) => {
      return acc + pf(current.prix)
    }, 0)
    const montant_global = tissus.reduce((acc, current) => {
      return acc + pf(current.prix)
    }, montant_global_ex)

    const montant_after_reduction = montant_global - pf(taxes.reduction)
    const montant_ttc = montant_after_reduction * (1 + pf(taxes.tva) / 100)
    const restant = pf(paiement) - montant_ttc

    setGlobal({
      montant: montant_global.toFixed(0),
      montant_after_reduction: montant_after_reduction.toFixed(0),
      net_payer: montant_ttc.toFixed(0),
      restant: (-restant).toFixed(0),
    })
  }, [tissus, taxes, paiement, dataEx])

  return (
    <IonContent
      style={{
        padding: 0,
        "--background": COLORS.gray,
      }}
    >
      <IonGrid
        style={{
          padding: "20px 0",
          margin: "20px 30px 0 20px",
        }}
      >
        <h6
          style={{
            fontWeight: "normal",
            color: "#666666",
            fontSize: "14px",
            fontFamily: "Montserrat",
          }}
        >
          Liste des commandes
          <span
            style={{
              fontWeight: "normal",
              color: "#E72D32",
              fontSize: "14px",
              fontFamily: "Montserrat",
            }}
          >
            {""} / Modification commande
          </span>
        </h6>
      </IonGrid>
      <IonGrid
        style={{
          padding: "20px 0",
          margin: "20px 30px 0 20px",
          background: "#FEFEFE",
          boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
          width: "100%",
        }}
      >
        <h5
          style={{
            color: "#666666",
            paddingLeft: "20px",
          }}
        >
          Client
        </h5>
        <IonGrid
          style={{
            position: "relative",
            margin: 20,
            borderRadius: 10,
            background: "#FFFFFF",
            boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
          }}
        >
          <IonRow className="ion-align-items-center" id="selectOption">
            <label
              style={{
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "17.07px",
                marginLeft: "100px",
                alignSelf: "center",
              }}
              htmlFor="client"
            >
              Nom Client
            </label>
            <IonCol
              className="ion-align-items-center"
              size={12}
              sizeSm={12}
              sizemd={12}
              sizeXs={12}
              sizeLg={12}
              sizeXl={8}
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <IonSelect
                name="client"
                id="client"
                multiple={false}
                value={client}
                onIonChange={(e) => setClient(e.detail.value)}
                placeholder="Selectionner un client"
                style={{
                  height: "50px",
                  width: "408px",
                  marginLeft: "40px",
                  borderRadius: "5px",
                  background: "#FFF",
                  outline: "none",
                  border: "1px solid #797979",
                }}
              >
                {clients.map((cli) => (
                  <IonSelectOption key={cli.id} value={cli.id}>
                    {cli.nom_complet}
                  </IonSelectOption>
                ))}
              </IonSelect>
              <IonIcon
                icon={addOutline}
                style={{
                  color: "#FFF",
                  fontSize: 40,
                  fontWeight: "normal",
                  paddingLeft: 10,
                  alignSelf: "center",
                  cursor: "pointer",
                  backgroundColor: "#E82828",
                  alignItems: "center",
                  borderRadius: 5,
                  margin: 10,
                  padding: 5,
                }}
                onClick={() => handleClose()}
              ></IonIcon>
            </IonCol>
            <Modals
              onAddClient={(client) => {
                setClients([...clients, client])
                setClient(client.id)
              }}
              showModal={showModal}
              handleClose={handleClose}
            />
          </IonRow>
        </IonGrid>

        {/* Section Donnees existantes */}

        <h5
          style={{
            color: "#666666",
            paddingLeft: 20,
          }}
        >
          Données existantes
        </h5>
        {dataEx.map((item, ind) => (
          <IonGrid
            key={ind}
            style={{
              paddingTop: 20,
              margin: 20,
              borderRadius: 10,
              background: "#FEFEFE",
              boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
            }}
          >
            <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={6} sizeXl={6}>
              <Button
                type="button"
                className="btn btn-danger dflex "
                onClick={() => removeDataGrid(ind)}
              >
                <span style={{ fontWeight: "bold" }}>-</span>
              </Button>
              <IonRow className="ion-align-items-center">
                <Input
                  label="Nom du tissu"
                  type="text"
                  placeholder="Nom du tissu"
                  name="tissu"
                  value={item?.tissu}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />
                <Input
                  label="Prix  confection "
                  min="0"
                  type="number"
                  name="prix"
                  placeholder="Prix  confection"
                  value={item?.prix}
                  style={{
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />
              </IonRow>
              <IonRow className="ion-justify-content-center mb-2 align-items-center">
                {modelEx?.map(
                  (img, index) =>
                    img?.detail_cmd === item.id && (
                      <Image
                        key="index"
                        style={{ width: 100 }}
                        src={`${baseBackendUrl}${img?.image}`}
                      />
                    )
                )}
                <IonRow
                  className="image-picker"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: 10,
                  }}
                >
                  <label
                    className="label-input"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Importez une image
                    <br />
                    <input
                      type="file"
                      name="images"
                      multiple
                      accept="image/png , image/jpeg, image/webp"
                    />
                  </label>
                </IonRow>
              </IonRow>
            </IonCol>
          </IonGrid>
        ))}
        {/* Section Donnees  tissus */}
        <h5
          style={{
            color: "#666666",
            paddingLeft: 20,
          }}
        >
          Tissus
        </h5>
        <IonGrid
          style={{
            paddingTop: 20,
            margin: 20,
            borderRadius: 10,
            background: "#FEFEFE",
            boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
            alignItems: "center",
          }}
        >
          {tissus.map((tissu, index) => {
            return (
              <IonRow
                className="ion-align-items-center ;ion-justify-content-start"
                key={index}
              >
                <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={6} sizeXl={6}>
                  {index > 1 && index === tissus.length - 1 && (
                    <Button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => {
                        handleRemove(index)
                      }}
                    >
                      <span style={{ fontWeight: "bold" }}>-</span>
                    </Button>
                  )}
                  <Input
                    label={`Nom du tissu ${index + 1} `}
                    type="text"
                    placeholder="Nom du tissu"
                    name="tissu"
                    value={tissu.tissu}
                    onIonChange={(e) => handleTissuChange(e, tissu)}
                    style={{
                      height: "50px",
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <Input
                    label="Prix"
                    min="0"
                    type="number"
                    name="prix"
                    placeholder="Prix"
                    onIonChange={(e) => handleTissuChange(e, tissu)}
                    value={tissu.prix}
                    style={{
                      borderRadius: "5px",
                      background: "#FFF",
                      outline: "none",
                      border: "1px solid #797979",
                    }}
                  />
                  <IonRow
                    className="image-picker"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <label className="label-input">
                      Importez une image
                      <br />
                      <input
                        type="file"
                        name="images"
                        multiple
                        accept="image/png , image/jpeg, image/webp"
                        onChange={(e) => handleTissuChange(e, tissu)}
                      />
                    </label>
                    <br />
                    <div className="images">
                      {tissu.images.map((image, index) => {
                        return (
                          <div key={index} className="image">
                            <img
                              src={URL.createObjectURL(image)}
                              height="200"
                              alt="upload"
                            />
                            <button
                              onClick={() => handleDeleteImage(image, tissu)}
                            >
                              supprimez l'image
                            </button>
                            <p>{index + 1}</p>
                          </div>
                        )
                      })}
                    </div>
                  </IonRow>
                  <IonRow>
                    <Button
                      type="button"
                      style={{
                        backgroundColor: "#C4C4C4",
                        color: "#000000",
                        border: "none",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        marginRight: 5,
                        textDecoration: "none",
                      }}
                      onClick={handleNewInput}
                      addIcon={index === tissus.length - 1}
                    >
                      <span style={{ fontWeight: "bold" }}>+</span>
                    </Button>
                    {index > 0 && index === tissus.length - 1 && (
                      <Button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          handleRemove(index)
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>-</span>
                      </Button>
                    )}
                  </IonRow>
                </IonCol>
              </IonRow>
            )
          })}
        </IonGrid>
        {/* Section Taxes */}
        <h4
          style={{
            color: "#666666",
            paddingLeft: "20px",
          }}
        >
          Taxes
        </h4>
        <IonGrid
          style={{
            position: "relative",
            margin: 20,
            borderRadius: 10,
            background: "#FFFFFF",
            boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
          }}
        >
          <IonRow className="ion-align-items-center">
            <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={6} sizeXl={6}>
              <Input
                label="Montant global"
                min="0"
                type="number"
                id="montant"
                placeholder={global.montant}
                name="montant"
                value={global.montant}
                readonly
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  background: "#FFF",
                  outline: "none",
                  border: "1px solid #797979",
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center">
            <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={6} sizeXl={6}>
              <Input
                label="Réduction"
                min="0"
                type="number"
                id="reduction"
                placeholder={taxes.reduction}
                name="reduction"
                value={taxes.reduction}
                onIonChange={handleTaxesChange}
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  background: "#FFF",
                  outline: "none",
                  border: "1px solid #797979",
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center">
            <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={6} sizeXl={6}>
              <Input
                label="Montant après réduction "
                min="0"
                type="number"
                placeholder={global.montant_after_reduction}
                id="montant_after_reduction"
                name="montant_after_reduction"
                value={global.montant_after_reduction}
                readonly
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  background: "#FFF",
                  outline: "none",
                  border: "1px solid #797979",
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center">
            <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={6} sizeXl={6}>
              <Input
                label=" TVA (Optionelle)"
                min="0"
                type="number"
                placeholder={taxes.tva}
                id="tva "
                name="tva"
                value={taxes.tva}
                onIonChange={handleTaxesChange}
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  background: "#FFF",
                  outline: "none",
                  border: "1px solid #797979",
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center">
            <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={6} sizeXl={6}>
              <Input
                label="Montant TTC "
                min="0"
                type="number"
                placeholder={global.net_payer}
                id="net_payer "
                name="net_payer "
                value={global.net_payer}
                readonly
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  background: "#FFF",
                  outline: "none",
                  border: "1px solid #797979",
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
        {/* Section paiement */}
        <h4
          style={{
            color: "#666666",
            paddingLeft: "20px",
          }}
        >
          Paiement
        </h4>
        <IonGrid
          style={{
            position: "relative",
            margin: 20,
            borderRadius: 10,
            background: "#FFFFFF",
            boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
          }}
        >
          <IonRow className="ion-align-items-center">
            <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={6} sizeXl={6}>
              <Input
                label="Montant payé"
                min="0"
                type="number"
                id="net_financier"
                placeholder={paiement}
                name="net_financier"
                value={paiement}
                onIonChange={(e) => setPaiement(e.detail.value)}
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  background: "#FFF",
                  outline: "none",
                  border: "1px solid #797979",
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center">
            <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={6} sizeXl={6}>
              <Input
                label="Restant"
                min="0"
                type="number"
                placeholder={global.restant}
                id="restant"
                name="restant"
                value={global.restant}
                readonly
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  background: "#FFF",
                  outline: "none",
                  border: "1px solid #797979",
                }}
              />
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center">
            <IonCol size={12} sizeSm={6} sizemd={6} sizeLg={6} sizeXl={6}>
              <Input
                label="Date de livraison"
                min="0"
                type="date"
                id="date_livraison"
                name="date_livraison"
                value={dateLivraion}
                onChange={(e) => setDateLivraion(e.detail.value)}
                style={{
                  height: "50px",
                  borderRadius: "5px",
                  background: "#FFF",
                  outline: "none",
                  border: "1px solid #797979",
                }}
              />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonRow className="ion-align-items-center">
          <IonCol
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            size={12}
            sizeSm={6}
            sizemd={6}
            sizeLg={6}
            sizeXl={6}
          >
            <AppButton
              title="Modifier"
              // loading={loading}
              onClick={editCommande}
            />
            <Toaster />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default EditCommande
