import React, { useEffect } from "react"
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react"
import { logOutOutline } from "ionicons/icons"
import moment from "moment"
import AddDepense from "./ModalAddDepense"
import TableLoader from "../../skeleton/ItemSkeleton"

const TableDepense = ({ depenses }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);

  React.useEffect(() => {
    const pf = (n) => {
      return parseFloat(n) || 0;
    };
    let sum = 0;
    depenses.forEach(({ prix }) => {
      sum += pf(prix);
    });
    setCount(sum);
  }, [depenses]);

  const columns = ["Date", "Désignation", "Montant"];

  return (
    <>
      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "40px 0",
        }}
      >
        <IonRow className="ion-align-items-center ion-justify-content-space-between">
          <IonCol
            style={{
              padding: "0 30px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <IonButton fill="clear" className="boutton-export-csv">
              <IonIcon icon={logOutOutline} slot="start"></IonIcon>
              Export CSV
            </IonButton>
            <AddDepense />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "0",
        }}
      >
        <IonRow
          className="ion-align-items-center"
          style={{
            height: "70px",
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
            paddingLeft: "50px",
          }}
        >
          {columns.map((column, index) => (
            <IonCol
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              key={index}
            >
              {column}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
        }}
        className="myContent"
      >
        {isLoading && <TableLoader />}
        {depenses.map((item) => (
          <IonRow
            className="d-flex ion-align-items-center"
            key={item?.id}
            style={{
              padding: "10px 0 10px 50px",
              borderBottom: "1px solid #F5F5F5",
            }}
          >
            <IonCol>
              {moment(item?.created_at).format("DD - MM - YYYY")}
            </IonCol>
            <IonCol>{item?.libelle}</IonCol>
            <IonCol style={{ fontWeight: "600" }}>{item?.prix} FCFA</IonCol>
          </IonRow>
        ))}

        <IonRow
          style={{
            borderTop: "1px solid #D9D9D9",
            borderBottom: "1px solid #D9D9D9",
            padding: "40px 0",
          }}
        >
          <IonCol></IonCol>
          <IonCol size="7" className="ion-text-center">
            <IonCol style={{ fontWeight: "600" }}>Total:</IonCol>
            <IonCol style={{ fontWeight: "600" }}>{count} FCFA</IonCol>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default TableDepense;

