import { IonCol, IonGrid, IonItem, IonRow } from "@ionic/react"

const Historique = () => {
  return (
    <>
      <IonGrid
        style={{
          borderRadius: 10,
          background: "#FEFEFE",
          boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
        }}
      >
        <IonRow>
          <IonCol
            style={{
              borderBottom: " 5px solid ##C4C4C4",
            }}
            size={12}
            sizeSm={6}
            sizemd={6}
            sizeLg={12}
            sizeXl={12}
          >
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>
                  <b> Date</b>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <b>Montant</b>
                </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>
                  <b>Mois</b>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>01/02/2021 </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>20 000 f </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>Janvier </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>01/02/2021 </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>20 000 f </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>Janvier </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-between mt-3 mb-3 ">
              <IonCol>
                <IonItem>01/02/2021 </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>20 000 f </IonItem>
              </IonCol>
              <IonCol>
                <IonItem>Janvier </IonItem>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  )
}
export default Historique
