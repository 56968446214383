import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react"
import React, { useEffect, useState } from "react"
import AllEmployes from "./AllEmployes"
import PaiementEmployes from "./PaiementEmployes"
import { ApiUrl } from "../../utils/http/http"
import axios from "axios"
function Employes() {
  const [registerActive, setRegisterActive] = useState(true)
  const [count, setCount] = React.useState(0)

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }
  const getEmployes = () => {
    axios
      .get(ApiUrl + "/employes/", config, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        // console.log("resultData", response.data.count)
        setCount(response.data.count)
      })
      .catch((error) => {
        console.log("Error lors du chargement des donnees", error)
      })
  }

  useEffect(() => {
    getEmployes()
  }, [])

  const handleValue = () => {
    if (registerActive) {
      return "allEmployes"
    } else if (registerActive === null) {
      return "paiementEmployes"
    }
  }

  const handleAffichagecomptable = () => {
    if (registerActive) {
      return <AllEmployes />
    } else {
      return <PaiementEmployes />
    }
  }

  return (
    <IonContent>
      <IonGrid className="main-page">
        <IonRow>
          <IonCol sizeMd={5}>
            <IonSegment value={handleValue}>
              <IonSegmentButton
                value="allEmployes"
                onClick={() => {
                  setRegisterActive(true)
                }}
                className={`boutton-segment-stock ${
                  registerActive && "cstm-btn-stock"
                }`}
              >
                Tous les employés ( {count} )
              </IonSegmentButton>
              <IonSegmentButton
                value="paiementEmployes"
                className={`boutton-segment-stock ${
                  registerActive === null && "cstm-btn-stock"
                }`}
                onClick={() => {
                  setRegisterActive(null)
                }}
              >
                Paiement employés
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>{handleAffichagecomptable()}</IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default Employes
