import {
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
  IonIcon,
} from "@ionic/react"
import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Image } from "react-bootstrap"
import { ApiUrl, baseBackendUrl } from "../../utils/http/http"
import "./commande.css"
import moment from "moment"
import { createOutline } from "ionicons/icons"
import { COLORS } from "../../utils/constants"
import MesureClient from "./ModalMesureClient"

function DetailCommande() {
  const [data, setData] = useState({})
  const [detailCommand, setDetailCommand] = useState([])
  const params = useParams()
  const { id } = params
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  const fetchCommandes = () => {
    axios
      .get(`${ApiUrl}/commandes/` + id + "/", config)
      .then((response) => {
        console.log("chargement...:", response.data)
        setData(response.data)
        setDetailCommand(response.data.commande_detail_cmd)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    fetchCommandes()
  }, [id])

  const client = data?.client || {}
  const modele = data?.commande_modele || {}

  return (
    <div className="detailCommandeGrid">
      <IonGrid
        className="infoMesureGrid"
        style={{
          paddingTop: 20,
          margin: 20,
          borderRadius: 10,
          background: "#FEFEFE",
          boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
          alignItems: "center",
        }}
      >
        <IonItem
          style={{
            color: "#666666",
          }}
          className=" mb-3"
        >
          Détail commande
        </IonItem>
        <IonRow className="ion-align-items-center ;ion-justify-content-start">
          <IonCol>
            <IonRow className="ion-justify-content-between mb-3 align-items-center">
              <IonLabel>Nom client</IonLabel>
              <h2> {client.nom_complet} </h2>
              <MesureClient client={client} />
            </IonRow>
            <IonRow className="ion-justify-content-between mb-2 align-items-center">
              <IonLabel>Date commande</IonLabel>
              <h2> {moment(client.created_at).format("DD-MM-YYYY")}</h2>
              <div className="col-1 pl-0">
                <div className="cta-icon" style={{ cursor: "pointer" }}>
                  <IonIcon
                    icon={createOutline}
                    style={{
                      color: COLORS.grayD,
                      fontSize: "24px",
                    }}
                    className="ionIcon"
                  />
                </div>
                <span
                  className="tableLink"
                  style={{
                    color: COLORS.grayD,
                  }}
                >
                  Modifier
                </span>
              </div>
            </IonRow>
          </IonCol>
          <IonCol size={12} sizeSm={6} sizeMd={6} sizeLg={12} sizeXl={12}>
            <IonRow className="ion-justify-content-between mb-2 align-items-center">
              <IonLabel>Date livraison</IonLabel>
              <h2>{moment(client.date_livraison).format("DD-MM-YYYY")}</h2>
              <div className="col-1 pl-0">
                <div className="cta-icon" style={{ cursor: "pointer" }}>
                  <IonIcon
                    icon={createOutline}
                    style={{
                      color: COLORS.grayD,
                      fontSize: "24px",
                    }}
                    className="ionIcon"
                  />
                </div>
                <span
                  className="tableLink"
                  style={{
                    color: COLORS.grayD,
                  }}
                >
                  Modifier
                </span>
              </div>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
      {detailCommand?.map((item, index) => (
        <div
          key={"index-dc" + index}
          className="tissuCommande"
          style={{ display: "flex" }}
        >
          <IonGrid
            className="gridTissu"
            style={{
              position: "relative",
              margin: 20,
              borderRadius: 10,
              background: "#FFFFFF",
              boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
            }}
          >
            <IonGrid className="priceContent">
              <IonRow className="ion-justify-content-between ion-align-items-center">
                <IonCol>
                  <IonRow className="ion-justify-content-between mb-2 align-items-center">
                    <IonItem className="text-secondary">
                      <b> {item?.tissu}</b>
                    </IonItem>
                    <IonItem className="text-secondary">
                      <b>
                        {Intl.NumberFormat("fr-FR").format(
                          parseInt(`${item?.prix} f`)
                        )}
                        Fcfa
                      </b>
                    </IonItem>
                  </IonRow>
                </IonCol>
              </IonRow>
              <IonGrid className="ImageModeleGrid">
                <IonRow className="ion-justify-content-between ion-align-items-center">
                  <IonRow className="ion-justify-content-evenly mb-2 align-items-center">
                    {modele?.map(
                      (model, ind) =>
                        model?.detail_cmd === item.id && (
                          <Image
                            key={"index-dm" + ind}
                            style={{ width: "50%" }}
                            src={`${baseBackendUrl}${model?.image}`}
                          />
                        )
                    )}
                  </IonRow>
                </IonRow>
              </IonGrid>
            </IonGrid>
          </IonGrid>
        </div>
      ))}
    </div>
  )
}

export default DetailCommande
