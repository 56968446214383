import { set, get, remove } from "./ionicStorage";

export async function getItem(key, defaultValue = null) {
  try {
    const data = await get(key);
    if (!data) return defaultValue;
    return JSON.parse(data);
  } catch (ex) {
    return defaultValue;
  }
}

export async function setItem(key, value) {
  try {
    return await set(key, JSON.stringify(value));
  } catch (ex) {
    return false;
  }
}
export async function deleteItem(key) {
  try {
    await remove(key);
  } catch (ex) {}
}

export function logout() {
  deleteItem("auth");
}

export function getUser() {
  return getItem("auth", {});
}

export class AuthState {
  static getKey() {
    return "user";
  }
  static currentUser() {
    return getItem(AuthState.getKey()).then((d) => d?.user);
  }
  static token() {
    return getItem(AuthState.getKey()).then((d) => d?.token);
  }
  static async logout() {
    return setItem(AuthState.getKey(), { user: {}, token: null });
  }
  static async login(user, token) {
    const data = await getItem(AuthState.getKey());
    if (data?.token) {
      return setItem(AuthState.getKey(), {
        user: user || data.user,
        token: token || data.token,
      });
    } else {
      return setItem(AuthState.getKey(), { user, token });
    }
  }
}
