const initialState = {
    clients: [
        {
        nom_complet: "",
        phone: "",
        poitrine: "",
        autre: "",
        epaule: "",
        longueur_manche: "",
        cou: "",
        ceinture: "",
        tour_bras: "",
        hanche: "",
        cuisse: "",
        longueur_haut: "",
        longueur_pantalon: "",
        longueur_boubou: "",
        longueur_jupe: "",
        longueur_poitrine: "",
        taille: "",
        bretelle: "",
        longueur_robe: "",
        shop: 0,
      }
    ],
};

const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case "Add_Client" :
            return {
                ...state,
                clients: [...state.clients, action.payload],
            };
            default:
                return state;
    }
};
export default clientReducer