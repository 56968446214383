import {
  IonButton,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
} from "@ionic/react"
import { closeOutline, peopleOutline } from "ionicons/icons"
import React, { useState, useCallback, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { ApiUrl } from "../../utils/http/http"
import { useFormData } from "../../shared/Form"
import { useFormValidation } from "../../shared/Form"
import { useHistory } from "react-router-dom"

const initialState = {
  name: "",
  email: "",
  phone: "",
  password: "",
  confirmPassword: "",
}

function UtilisateurParam() {
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [number, setNumber] = useState("77 000 000")
  const [loading, setLoading] = React.useState(false)
  const [data, setData] = useFormData(initialState)
  const [users, setUsers] = useState([])
  const [errors, validate] = useFormValidation({}, (yup) => {
    return {
      name: yup.string().required("Nom Obligatoire").label("Nom Complet"),
      email: yup
        .string()
        .email("Email Invalide")
        .required("Email Obligatoire")
        .label("Email"),
      phone: yup
        .string()
        .min(9, "trop court!")
        .max(15, "trop long!")
        .required("Numero de Telephone Obligatoire")
        .label("Telephone"),
      password: yup
        .string()
        .min(8, "trop court!")
        .max(50, "trop long!")
        .required("Mot de Passe Obligatoire")
        .label("Mot de passe"),
      confirmPassword: yup
        .string()
        .required("Mot de Passe Obligatoire")
        .oneOf(
          [yup.ref("password"), null],
          "Le mot de passe de confirmation ne correspond pas"
        )
        .label("Confirmer le mot de passe"),
    }
  })

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  const addUser = React.useCallback(() => {
    axios(
      {
        method: "POST",
        url: ApiUrl + "/user/",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ phone, password }),
        data,
      },
      validate(data)
    )
      .then((res) => {
        setLoading(true)
        console.log(res.data)
        console.log("Inscription réussi !!!")
        alert("Bravo votre inscription est réussi")
        setTimeout(() => {
          setLoading(false)
          history.push("/dashboard/parametre-page")
        }, 2000)
      })
      .catch((ex) => {
        // console.log(ex)
        alert("Echec de l'inscription !!!")
      })
  }, [data, history, validate])

  const getUsers = async () => {
    axios({
      method: "GET",
      url: ApiUrl + "/user/",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      console.log(res.data.results, "Donnes chargee")
      const user = res.data.results
      setUsers(user)
    })
  }
  useEffect(() => {
    document.title = "Tayeur-Entreprise"
    getUsers()
  }, [])

  return (
    <IonRow>
      <IonCol sizeLg={4} pushLg={8} size={12} className="">
        <IonModal
          isOpen={showModal}
          cssClass="my-custom-class"
          className="add-user-modal"
        >
          <IonIcon
            icon={closeOutline}
            onClick={() => setShowModal(false)}
            className="icone-close"
            slot="start"
          />
          <IonRow className="m-auto h-auto w-100 ion-justify-content-center dfgh">
            <IonCol sizemd={12} size={12}>
              <IonRow className="">
                <IonCol size={12} className="label-input-text">
                  <div>
                    <IonLabel>Nom Complet:</IonLabel>
                  </div>
                  <div>
                    <IonInput
                      type="text"
                      id="name"
                      name="name"
                      error={errors.name}
                      value={data.name}
                      onIonChange={handleChange}
                      placeholder="Nom complet"
                      className="add-user-modal-input"
                    ></IonInput>
                  </div>
                </IonCol>
                <IonCol size={12} className="label-input-text">
                  <div>
                    <IonLabel>Email:</IonLabel>
                  </div>
                  <div>
                    <IonInput
                      type="text"
                      name="email"
                      id="email"
                      error={errors.email}
                      value={data.email}
                      onIonChange={handleChange}
                      placeholder="Email"
                      className="add-user-modal-input"
                    ></IonInput>
                  </div>
                </IonCol>
                <IonCol size={12} className="label-input-text">
                  <div>
                    <IonLabel>Téléphone:</IonLabel>
                  </div>
                  <div>
                    <IonInput
                      type="number"
                      id="phone"
                      name="phone"
                      error={errors.phone}
                      value={data.phone}
                      placeholder="telephone"
                      onIonChange={handleChange}
                      className="add-user-modal-input"
                    ></IonInput>
                  </div>
                </IonCol>
                <IonCol size={12} className="label-input-text">
                  <div>
                    <IonLabel>Mot de passe:</IonLabel>
                  </div>
                  <div>
                    <IonInput
                      type="password"
                      id="password"
                      name="password"
                      value={data.password}
                      error={errors.password}
                      onIonChange={handleChange}
                      show-hide-input
                      placeholder="password"
                      className="add-user-modal-input"
                    ></IonInput>
                  </div>
                </IonCol>
                <IonCol size={12} className="label-input-text">
                  <div>
                    <IonLabel>Confirmer mot de passe:</IonLabel>
                  </div>
                  <div>
                    <IonInput
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={data.confirmPassword}
                      error={errors.confirmPassword}
                      onIonChange={handleChange}
                      show-hide-input
                      placeholder=" confirmer password"
                      className="add-user-modal-input"
                    ></IonInput>
                  </div>
                </IonCol>
              </IonRow>
            </IonCol>
            <IonCol
              sizemd={12}
              size={12}
              className="d-flex justify-content-end"
            >
              <IonButton
                onClick={addUser}
                onLoad={loading}
                fill="clear"
                className="boutton-sauvegarder"
              >
                Sauvegarder
              </IonButton>
            </IonCol>
          </IonRow>
        </IonModal>
        <IonButton
          fill="clear"
          className="boutton-ajout-user"
          onClick={() => setShowModal(true)}
        >
          Ajouter un utilisateur
        </IonButton>
      </IonCol>
      <IonCol sizeLg={8} pullLg={4} size={12} className="">
        <IonItem className="entete-tableau" lines="full">
          <IonLabel className="">
            <IonRow className="tableau-user-title">
              <IonCol size={4} className="ion-text-start">
                Nom
              </IonCol>

              <IonCol size={4} className="ion-text-start">
                Téléhone
              </IonCol>
              <IonCol size={4} className="ion-text-start">
                Email
              </IonCol>
            </IonRow>
          </IonLabel>
        </IonItem>

        <IonList className="tableau-user-content tableau-user-content-list">
          <IonModal
            isOpen={showModal2}
            cssClass="my-custom-class"
            className="add-user-modal"
          >
            <IonIcon
              icon={closeOutline}
              onClick={() => setShowModal2(false)}
              className="icone-close"
              slot="start"
            />
            <IonRow className="m-auto h-auto w-100 ion-justify-content-center dfgh">
              <IonCol sizemd={12} size={12}>
                <IonRow className="">
                  <IonCol size={12} className="label-input-text">
                    <div>
                      <IonLabel>Nom Complet:</IonLabel>
                    </div>
                    <div>
                      <IonInput
                        placeholder="Fatou Sow"
                        className="add-user-modal-input"
                      ></IonInput>
                    </div>
                  </IonCol>
                  <IonCol size={12} className="label-input-text">
                    <div>
                      <IonLabel>Email:</IonLabel>
                    </div>
                    <div>
                      <IonInput
                        placeholder="sowfafot@gmail.com"
                        className="add-user-modal-input"
                      ></IonInput>
                    </div>
                  </IonCol>
                  <IonCol size={12} className="label-input-text">
                    <div>
                      <IonLabel>Téléphone:</IonLabel>
                    </div>
                    <div>
                      <IonInput
                        type="number"
                        value={number}
                        placeholder="77 000 00 00"
                        onIonChange={(e) =>
                          setNumber(parseInt(e.detail.value, 10))
                        }
                        className="add-user-modal-input"
                      ></IonInput>
                    </div>
                  </IonCol>
                  <IonCol className="d-flex justify-content-start">
                    <Link fill="clear" className="boutton-modifier-password2">
                      Modifier le mot de passe
                    </Link>
                  </IonCol>
                  <IonCol size={12} className="label-input-text">
                    <div>
                      <IonLabel>Mot de passe actuel:</IonLabel>
                    </div>
                    <div>
                      <IonInput
                        type="password"
                        show-hide-input
                        placeholder="password"
                        className="add-user-modal-input"
                      ></IonInput>
                    </div>
                  </IonCol>
                  <IonCol size={12} className="label-input-text">
                    <div>
                      <IonLabel>Nouveau mot de pass:</IonLabel>
                    </div>
                    <div>
                      <IonInput
                        type="password"
                        show-hide-input
                        placeholder="password"
                        className="add-user-modal-input"
                      ></IonInput>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCol>
              <IonCol
                sizemd={12}
                size={12}
                className="d-flex justify-content-end"
              >
                <IonButton
                  onClick={() => setShowModal2(false)}
                  fill="clear"
                  className="boutton-sauvegarder"
                >
                  Sauvegarder
                </IonButton>
              </IonCol>
            </IonRow>
          </IonModal>
          <table className="table table-responsive-sm">
            <tbody>
              {users.map((index) => (
                <IonItem
                  className="tableau-user-content"
                  lines="full"
                  button
                  onClick={() => setShowModal2(true)}
                  key={index.id}
                >
                  <IonCol size={4} className="ion-text-start">
                    {index.name}
                  </IonCol>

                  <IonCol size={4} className="ion-text-start">
                    {index.phone}
                  </IonCol>
                  <IonCol size={4} className="ion-text-start">
                    {index.email}
                  </IonCol>
                </IonItem>
              ))}
            </tbody>
          </table>
        </IonList>
      </IonCol>
    </IonRow>
  )
}

export default UtilisateurParam
