import {
  IonInput,
  IonItem,
  IonLabel,
  IonIcon,
  IonRow,
  IonCol,
} from "@ionic/react"

import "./Input.css"

const Input = ({
  label,
  value,
  icon,
  placeholder,
  onChange,
  error,
  uploadIcon,
  addIcon,
  leftSide,
  onClick,
  name,
  ...rest
}) => {
  return (
    <div style={{ marginBottom: 18 }}>
      {icon ? (
        <IonItem
          style={{
            backgroundColor: "#F4F8F7",
            height: "60px",
            "--border-width": 0,
          }}
          className="d-flex align-items-center justify-content-center mb-3 loginLeftContain"
        >
          <>
            <IonLabel>
              <IonIcon icon={icon} style={{ fontSize: "18px" }} />
            </IonLabel>
          </>

          <IonInput
            value={value}
            placeholder={placeholder}
            name={name}
            onIonChange={(e) => onChange(e.detail.value)}
            {...rest}
          />
        </IonItem>
      ) : (
        <IonItem lines="none" className="" style={{ padding: 0 }}>
          <IonRow className="" style={{ width: "100%", padding: 0 }}>
            {uploadIcon ? (
              <>
                <IonCol
                  size={12}
                  sizeSm={12}
                  sizeXs={12}
                  sizemd={12}
                  sizeLg={6}
                  sizeXl={3}
                  className="ion-align-self-center"
                >
                  <IonLabel
                    className="text-left p-0"
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontSize: "14px",
                      lineHeight: "17.07px",
                    }}
                  >
                    {label}
                  </IonLabel>
                </IonCol>
                <IonCol
                  size={12}
                  sizeSm={12}
                  sizemd={11}
                  sizeXs={11}
                  sizeLg={11}
                  sizeXl={8}
                  className="ion-align-self-center"
                >
                  <IonInput
                    className="flex-fill"
                    style={{
                      height: "35px",
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onIonChange={(e) => onChange(e.detail.value)}
                    {...rest}
                  />
                </IonCol>
                <IonCol
                  size={1}
                  className="ion-align-self-center ion-text-center"
                  onClick={(e) => {
                    e.preventDefault()
                    onClick()
                  }}
                  style={{
                    padding: 0,
                  }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                      stroke="black"
                      strokeOpacity="0.7"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M17 8L12 3L7 8"
                      stroke="black"
                      strokeOpacity="0.7"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 3V15"
                      stroke="black"
                      strokeOpacity="0.7"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </IonCol>
              </>
            ) : addIcon ? (
              <>
                <IonCol
                  size={12}
                  sizeSm={12}
                  sizeXs={12}
                  sizemd={12}
                  sizeLg={6}
                  sizeXl={4}
                  className="ion-align-self-center"
                >
                  <IonLabel
                    className="text-left p-0"
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontSize: "14px",
                      lineHeight: "17.07px",
                      whiteSpace: "normal",
                      marginLeft: 70,
                    }}
                  >
                    {label}
                  </IonLabel>
                </IonCol>
                <IonCol
                  size={12}
                  sizeSm={12}
                  sizeXs={12}
                  sizemd={12}
                  sizeLg={12}
                  sizeXl={8}
                  className="ion-align-self-center"
                >
                  <IonInput
                    size={12}
                    sizeSm={12}
                    sizeXs={12}
                    sizemd={12}
                    sizeLg={6}
                    sizeXl={7}
                    className="flex-fill"
                    style={{
                      height: "35px",
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onIonChange={onChange}
                    {...rest}
                  />
                </IonCol>
                <IonCol
                  size={1}
                  sizeSm={1}
                  sizeXs={1}
                  sizemd={1}
                  sizeLg={12}
                  sizeXl={12}
                  className="ion-align-self-center ion-text-center"
                  onClick={(e) => {
                    e.preventDefault()
                    onClick()
                  }}
                  style={{
                    order: 2,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ionicon"
                    viewBox="0 0 512 512"
                    width="24"
                    height="24"
                    style={{
                      border: "3px solid #C4C4C4",
                      borderRadius: 30,
                      cursor: "pointer",
                      display: "block ",
                    }}
                  >
                    <title>Ajout tissu</title>
                    <path
                      fill="none"
                      stroke="#C4C4C4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M256 112v288M400 256H112"
                    />
                  </svg>
                </IonCol>
              </>
            ) : leftSide ? (
              <>
                <IonCol
                  size={12}
                  sizeSm={12}
                  sizeXs={12}
                  sizemd={12}
                  sizeLg={6}
                  sizeXl={3}
                  className="ion-align-self-center"
                >
                  <IonLabel
                    className="text-left p-0"
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontSize: "14px",
                      lineHeight: "17.07px",
                    }}
                  >
                    {label}
                  </IonLabel>
                </IonCol>
                <IonCol
                  size={12}
                  sizeSm={12}
                  sizemd={12}
                  sizeXs={12}
                  sizeLg={12}
                  sizeXl={8}
                  className="ion-align-items-center"
                >
                  <IonInput
                    style={{
                      height: "35px",
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onIonChange={(e) => onChange(e.detail.value)}
                    {...rest}
                  />
                </IonCol>
              </>
            ) : (
              <>
                <IonCol
                  size={12}
                  sizeSm={12}
                  sizeXs={12}
                  sizemd={12}
                  sizeLg={6}
                  sizeXl={3}
                  offsetXl={1}
                  className="ion-align-self-center"
                >
                  <IonLabel
                    className="text-left p-0"
                    style={{
                      fontWeight: 500,
                      fontStyle: "normal",
                      fontSize: "14px",
                      lineHeight: "17.07px",
                      whiteSpace: "normal",
                    }}
                  >
                    {label}
                  </IonLabel>
                </IonCol>
                <IonCol
                  size={12}
                  sizeSm={12}
                  sizemd={12}
                  sizeXs={12}
                  sizeLg={12}
                  sizeXl={8}
                  className="ion-align-items-center"
                  style={{
                    display: "flex",
                  }}
                >
                  <IonInput
                    style={{
                      height: "55px",
                      border: "1px solid black",
                      borderRadius: "5px",
                    }}
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onIonChange={onChange}
                    {...rest}
                  />
                </IonCol>
              </>
            )}
          </IonRow>
        </IonItem>
      )}

      {error && <small className="text-warning mb-2">{error}</small>}
    </div>
  )
}

export default Input
