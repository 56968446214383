import ContentLoader from "react-content-loader"

const TableLoader = (props) => (
  <ContentLoader
    speed={2}
    width={1400}
    height={400}
    viewBox="0 0 1500 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="20" y="6" rx="4" ry="4" width="1100" height="38" />
    <rect x="20" y="55" rx="4" ry="4" width="1100" height="38" />
    <rect x="20" y="104" rx="4" ry="4" width="1100" height="38" />
    <rect x="20" y="153" rx="4" ry="4" width="1100" height="38" />
    <rect x="20" y="202" rx="4" ry="4" width="1100" height="38" />
    <rect x="20" y="251" rx="4" ry="4" width="1100" height="38" />
    <rect x="20" y="300" rx="4" ry="4" width="1100" height="38" />
    <rect x="20" y="349" rx="4" ry="4" width="1100" height="38" />
    <rect x="20" y="398" rx="4" ry="4" width="1100" height="38" />
    <rect x="20" y="447" rx="4" ry="4" width="1100" height="38" />
  </ContentLoader>
)

export default TableLoader
