import {
  IonButton,
  IonCard,
  IonCardContent,
  IonHeader,
  IonIcon,
} from "@ionic/react"
import axios from "axios"
import { arrowForwardOutline } from "ionicons/icons"
import moment from "moment"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { ApiUrl } from "../../utils/http/http"

function NextWeekCommande() {
  const History = useHistory()
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const [count, setCount] = useState(0)
  const [comNextWeek, setComNextWeek] = useState([])
  const [listData, setListData] = useState({})

  useEffect(() => {
    commandeNextWeek()
  }, [])

  const commandeNextWeek = () => {
    axios
      .get(`${ApiUrl}/commandes/` + userInfos.id_boutique + "/", config)
      .then((response) => {
        // console.log("datas", response.data.commande_next_week.datas)
        setComNextWeek(response.data.commande_next_week)
        const commandeNumber = response.data.commande_next_week.count
        setCount(commandeNumber)
        setListData(response.data.commande_next_week.datas)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <IonHeader
        style={{
          background: " #E82828",
          width: "100%",
          height: 80,
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <h6 style={{ color: "#FFF", fontWeight: "bold" }}>
          Semaine prochaine ( {count} )
        </h6>
      </IonHeader>
      <hr
        className="mb-0"
        style={{ borderBottom: "1px solid rgba(196, 196, 196, 0.5)" }}
      />

      {listData?.length ? (
        listData?.map((item) => (
          <IonCard
            className="contenu-de-carte ion-text-center"
            key={item?.id}
            onClick={() =>
              History.push("/dashboard/detail-commande/" + item?.id)
            }
          >
            <IonCardContent button className="ion-text-center ">
              <img
                className="avatar"
                src="https://ui-avatars.com/api/?name=Ngoné"
                style={{ width: 50 }}
              />
              <IonCardContent className="nom-carte">
                Commande de {item?.client.nom_complet}
              </IonCardContent>
              <IonCardContent className="date-carte">
                Date : {moment(item?.date_livraison).format("DD/MM/YYYY")}
              </IonCardContent>
            </IonCardContent>
          </IonCard>
        ))
      ) : (
        <p className="text-center text-info">
          <b> Aucune de commande pour le moment !</b>
        </p>
      )}
    </>
  )
}

export default NextWeekCommande
