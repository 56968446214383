import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonRow,
} from "@ionic/react"
import axios from "axios"
import { createOutline, eye, trashOutline } from "ionicons/icons"
import React, { useEffect, useState } from "react"
import Modals from "../components/Modal"
import ButtonI from "../shared/Button"
import { COLORS } from "../utils/constants"
import { ApiUrl } from "../utils/http/http"
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2"
import TableLoader from "../skeleton/ItemSkeleton"

const Clients = () => {
  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const history = useHistory()
  const [showModal, setShowModal] = React.useState(false)
  const [clients, setClients] = useState([])
  const [loading, setLoading] = React.useState(true)
  const[query,setQuery]=useState('')
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  useEffect(() => {
    onGetClients();
  }, []);

  const onGetClients = () => {
    setLoading(true);
    axios.get(`${ApiUrl}/clients/` + userInfos.id_boutique, config)
      .then((response) => {
        setLoading(false);
        // Ajouter une propriété de date de création à chaque client
        const clientsWithDate = response.data.data.map(client => ({
          ...client,
          created_at: Date.now(),
        }));
        // Trier les clients par date de création (les plus récents en premier)
        const sortedClients = clientsWithDate.sort((a, b) => b.created_at - a.created_at);
        setClients(sortedClients);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      })
  };

  const onDeleteClient = (id) => {
    axios
      .delete(`${ApiUrl}/clients/` + id + "/", config)
      .then((response) => {
        console.log(response)
      })
      .catch((error) => console.log(error))
  }

  const handleRemove = (id) => {
    Swal.fire({
      title: "Vous êtes sûr de vouloir supprimer cet élément ?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: `Non`,
      confirmButtonColor: "#2ECC71",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      iconColor: "#F1C40F",
      showConfirmButton: true,
      allowOutsideClick: false,
      timer: 4000,
    })
    .then((result) => {
      if (result.isConfirmed) {
        setClients(clients.filter((client) => client.id !== id));
        onDeleteClient(id);
      }
    })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          iconColor: "#df5656",
          showCancelButton: false,
          confirmButtonColor: "#df5656",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
          title: "Une erreur est survenue lors de la modification.",
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
    onDeleteClient(id)
  }

  const handleClose = () => {
    setShowModal(!showModal)
  }

  const handleSubmit = (client) => {
    handleClose()
  }

  const columns = ["Nom_complet", "Numéro de téléphone", "Adresse", "Actions"]

 const Searche = (datas)=>{
 return   datas.filter((item) =>
      item.nom_complet.toLowerCase().includes(query.toLowerCase()));
 }
 
 const dataclient = Searche(clients) 
//  console.log(dataclient)


  return (
    <IonContent
      style={{
        padding: 0,
        "--background": COLORS.gray,
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol
            size={12}
            style={{
              padding: "30px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h6 className="tableCaption">Liste des clients</h6>
            <ButtonI label="Ajouter un client" onClick={() => handleClose()} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <Modals
        onAddClient={onGetClients}
        setClient={setClients}
        showModal={showModal}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
      />
      <IonGrid
        style={{
          margin: "0 30px",
          background: "#FFFFFF",
        }}
      >
        <IonRow className="ion-align-self-end ion-justify-content-end">
          <IonCol size="3">
            <IonInput
              placeholder="Rechercher..."
              onIonChange={(e)=>setQuery(e.target.value)}
              style={{
                border: "1px solid #C4C4C4",
                boxSizing: "border-box",
                borderRadius: "5px",
                marginTop: "20px",
              }}
            ></IonInput>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid
        style={{
          margin: "0 30px",
          background: "#FFFFFF",
          position: "relative",
        }}
      >
        <IonRow
          style={{
            padding: "20px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
            boxSizing: "border-box",
          }}
        >
          {columns.map((column, index) => (
            <IonCol
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              key={index}
            >
              {column}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      {loading && <TableLoader />}
      {!loading && (
        <IonGrid
          style={{
            margin: "0 30px",
            background: "#FFFFFF",
          }}
          className="myContent"
        >
          {dataclient?.map((client, index) => (
            <IonRow key={index} style={{ padding: "10px 20px" }}>
              <IonCol>
                {client.nom_complet ? client.nom_complet : "Non communique"}
              </IonCol>
              <IonCol>{client.phone ? client.phone : "Non communique"}</IonCol>
              <IonCol>
                {client.adress ? client.adress : "Non communique"}
              </IonCol>
              <IonCol>
                <IonCol
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div className="col-3 pl-0">
                    <div
                      className="cta-icon"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push("/dashboard/detail-client/" + client?.id)
                      }

                    >
                      <IonIcon
                        icon={eye}
                        style={{
                          color: COLORS.redS,
                          fontSize: "24px",
                        }}
                        className="ionIcon"
                      />
                    </div>
                    <span
                      className="tableLink"
                      style={{
                        color: COLORS.redS,
                      }}
                    >
                      Details
                    </span>
                  </div>

                  <div className="col-3 pl-0">
                    <div
                      className="cta-icon"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push("/dashboard/edit-client/" + client?.id)
                      }
                    >
                      <IonIcon
                        icon={createOutline}
                        style={{
                          color: COLORS.grayD,
                          fontSize: "24px",
                        }}
                        className="ionIcon"
                      />
                    </div>
                    <span
                      className="tableLink"
                      style={{
                        color: COLORS.grayD,
                      }}
                    >
                      Modifier
                    </span>
                  </div>
                  <div className="col pl-0">
                    <div
                      className="cta-icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleRemove(client.id)}
                    >
                      <IonIcon
                        icon={trashOutline}
                        style={{
                          color: COLORS.redS,
                          fontSize: "24px",
                          opacity: 5,
                        }}
                        className="ionIcon"
                      />
                    </div>
                    <span
                      className="tableLink"
                      style={{
                        color: COLORS.redS,
                      }}
                    >
                      Supprimer
                    </span>
                  </div>
                </IonCol>
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      )}
    </IonContent>
  )
}

export default Clients
