import React from "react"
import bro from "../images/bro.png"
import amico from "../images/amico.png"
import { DIMENSIONS } from "../utils/constants"
import { Image } from "react-bootstrap"
export function useDimension() {
  const [dim, setDim] = React.useState({
    ...DIMENSIONS,
  })
  React.useEffect(() => {
    const lis = window.addEventListener("resize", (e) => {
      setDim({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    })
    return () => window.removeEventListener("resize", lis)
  }, [])
  return dim
}
const etap1 = {
  img: bro,
  title: "BIENVENUE SUR LA GESTION POS FEWNU",
}
const etap2 = {
  img: amico,
  title: "CLIQUER  ICI POUR COMMENCER",
}
const Intro = (props) => {
  const [etap, setEtap] = React.useState(etap1)
  const { width, height } = useDimension()
  const rounded = width * 0.3

  return (
    <div
      style={{
        width,
        height,
        flex: 1,
      }}
      className="text-center"
    >
      <div
        style={{
          width: rounded,
          height: rounded,
          position: "absolute",
          top: -(rounded / 3),
          right: -rounded / 3,
          borderRadius: rounded / 2,
          backgroundColor: "#00627C",
        }}
      />
      <Image
        src={etap.img}
        style={{
          // width: width * 0.3,
          // height: "auto",
          marginTop: height * 0.05,
        }}
      />
      <p
        onClick={() => {
          if (etap === etap2) {
            props.history.push("/login")
          }
        }}
        style={{
          cursor: "pointer",
          color: "#FF9800",
          fontWeight: "bold",
          fontSize: 28,
          marginTop: height * 0.07,
        }}
      >
        {etap.title}
      </p>
      <div className="row justify-content-center mt-5">
        <div
          onClick={() => setEtap(etap1)}
          style={{
            backgroundColor: etap === etap1 ? "#00627C" : "#ccc",
            width: "1.5rem",
            height: "1.5rem",
            cursor: "pointer",
            marginRight: 20,
            borderRadius: "50%",
          }}
        />
        <div
          onClick={() => setEtap(etap2)}
          style={{
            backgroundColor: etap === etap2 ? "#00627C" : "#ccc",
            cursor: "pointer",
            width: "1.5rem",
            height: "1.5rem",
            marginLeft: 20,
            borderRadius: "50%",
          }}
        />
      </div>
      <div
        style={{
          width: rounded,
          height: rounded,
          position: "absolute",
          cursor: "pointer",
          bottom: -(rounded / 3),
          borderRadius: rounded / 2,
          left: -rounded / 3,
          backgroundColor: "#00627C",
        }}
      />
    </div>
  )
}

export default Intro
