import React, { useEffect, useState } from "react"
import {
  IonCol,
  IonContent,
  IonGrid,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react"
import axios from "axios"
import { ApiUrl } from "../../utils/http/http"
import BilanPage from "./BilanPage"

import "./comptabilite.css"
import TableDepense from "./TableDepense"
import TableEntree from "./TableEntree"

function ComptabilitePage() {
  const [registerActive, setRegisterActive] = useState(true)
  const [depenses, setDepenses] = React.useState("")
  const token = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  const getDepenses = () => {
    axios
      .get(ApiUrl + "/expenses/", token)
      .then((response) => {
        setDepenses(response.data.data)
      })
      .catch((err) => {
        console.log("erreur", err)
      })
  }
  useEffect(() => {
    getDepenses()
  }, [])

  const handleValue = () => {
    if (registerActive) {
      return "entree"
    } else if (registerActive === false) {
      return "depenses"
    } else if (registerActive === null) {
      return "bilan"
    }
  }

  const handleAffichagecomptable = () => {
    if (registerActive) {
      return <TableEntree />
    } else if (registerActive === false) {
      return <TableDepense depenses={depenses} />
    } else {
      return <BilanPage />
    }
  }

  return (
    <IonContent>
      <IonGrid className="main-page">
        <IonRow>
          <IonCol sizeMd={5}>
            <IonSegment value={handleValue}>
              <IonSegmentButton
                value="entree"
                //   className="boutton-segment"
                onClick={() => {
                  setRegisterActive(true)
                }}
                className={`boutton-segment ${registerActive && "cstm-btn"}`}
              >
                Entrées
              </IonSegmentButton>
              <IonSegmentButton
                value="depenses"
                // className="boutton-segment"
                className={`boutton-segment ${
                  registerActive === false && "cstm-btn"
                }`}
                onClick={() => {
                  setRegisterActive(false)
                }}
              >
                Dépenses
              </IonSegmentButton>
              <IonSegmentButton
                value="bilan"
                // className="boutton-segment"
                className={`boutton-segment ${
                  registerActive === null && "cstm-btn"
                }`}
                onClick={() => {
                  setRegisterActive(null)
                }}
              >
                Bilan
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>{handleAffichagecomptable()}</IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default ComptabilitePage
