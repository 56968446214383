import { IonButton, IonIcon, IonLabel, IonSpinner } from "@ionic/react"
import { Spinner } from "react-bootstrap"
import { COLORS } from "../utils/constants"
import "./Input.css"

export const ValidateButton = ({ title, onClick, loading }) => {
  return (
    <IonButton
      disabled={loading}
      className={"app-button"}
      type="button"
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      style={{
        textTransform: "none",
        "--border-radius": "5px",
        "--backgroundColor": COLORS.redS,
        "--background-hover": "#fff",
        lineHeight: "17px",
        background: "#E82828",
        width: 200,
        fontSize: 16,
        fontWeight: "bold",
        height: 50,
        borderColor: "#E82828",
        borderRadius: 5,
      }}
    >
      {!loading && (
        <span
          style={{ fontWeight: 600, fontSize: "14px", lineHeight: "16.9px" }}
        >
          {title}
        </span>
      )}
      {loading && <Spinner animation="grow" />}
    </IonButton>
  )
}

const AppButton = ({ title, onClick, loading }) => {
  return (
    <IonButton
      disabled={loading}
      className={"app-button"}
      type="button"
      onClick={(e) => {
        e.preventDefault()
        onClick()
      }}
      style={{
        textTransform: "none",
        "--border-radius": "5px",
        "--backgroundColor": COLORS.redS,
        "--background-hover": "#fff",
        width: "420px",
        height: "50px",
        fontSize: "16px",
        lineHeight: "17px",
      }}
    >
      {!loading && (
        <span
          style={{ fontWeight: 600, fontSize: "14px", lineHeight: "16.9px" }}
        >
          {title}
        </span>
      )}
      {loading && <Spinner animation="grow" />}
    </IonButton>
  )
}

export function AppButton2({
  onClick,
  title,
  loading,
  iconRight,
  titleColor = "light",
  icon,
}) {
  return (
    <IonButton
      disabled={loading}
      onClick={onClick}
      size="large"
      className="w-full d-block mt-1"
      style={{
        Width: 370,
        height: 50,
        "--border-radius": 0,
        fontWeight: 700,
        fontSize: 14,
        lineHeight: 19.5,
        borderRadius: 5,
      }}
      expand="block"
    >
      {!loading && !iconRight && icon && (
        <IonIcon className="m-2" size="large" color={titleColor} icon={icon} />
      )}
      {loading && <IonSpinner color={titleColor} />}
      {!loading && <IonLabel color={titleColor}>{title}</IonLabel>}
      {!loading && iconRight && icon && (
        <IonIcon className="m-2" size="large" color={titleColor} icon={icon} />
      )}
    </IonButton>
  )
}

export default AppButton
