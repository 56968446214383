import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react"
import { arrowBackOutline } from "ionicons/icons"
import "./parametrepage.css"
import { Link } from "react-router-dom"
import ProfileParam from "./ProfileParam"
import { useEffect, useState } from "react"
import EntrepriseParam from "./EntrepriseParam"
import UtilisateurParam from "./UtilisateurParam"
import { ApiUrl } from "../../utils/http/http"
import axios from "axios"

function ParametrePage() {
  const [registerActive, setRegisterActive] = useState(1)

  const handleValue = () => {
    if (registerActive) {
      return "entree"
    } else if (registerActive === 1) {
      return "depenses"
    } else if (registerActive === null) {
      return "bilan"
    }
  }
  useEffect(() => {
    document.title = "Tayeur-Parametre"
    getUser()
  }, [])

  const userId = window.localStorage.getItem("id")

  const handleAffichage = () => {
    if (registerActive === 0) {
      return (
        <IonCol sizeMd={6}>
          <EntrepriseParam />
        </IonCol>
      )
    } else if (registerActive === 1) {
      return (
        <IonCol sizeMd={6}>
          <ProfileParam />
        </IonCol>
      )
    } else {
      return (
        <IonCol sizeMd={12}>
          <UtilisateurParam />
        </IonCol>
      )
    }
  }

  const getUser = async () => {
    axios({
      method: "GET",
      url: ApiUrl + "/user/",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      console.log(res.data.results, "Donnees chargees")
      const users = res.data.results
      // setUsers(allComptabilites);
    })
  }

  const updateUser = async () => {
    axios({
      method: "PUT",
      url: ApiUrl + "/user/{id}",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      console.log(res.data.results, "Donnees chargees")
      const user = res.data.results
    })
  }

  return (
    <IonContent>
      <IonGrid className="main-page">
        <IonRow>
          <IonCol>
            <Link to="/dashboard" className="lien-detail-commande">
              <IonButton fill="clear" color="dark" className="button-retour">
                <IonIcon slot="icon-only" icon={arrowBackOutline} />
              </IonButton>
            </Link>
            <IonCard className="carte-detail-commande">
              <IonCardContent>
                <IonCol sizemd={12} className="ion-text-start">
                  <IonSegment value={handleValue}>
                    <div className="val">
                      <IonSegmentButton
                        value="entree"
                        onClick={() => {
                          setRegisterActive(1)
                        }}
                        className={`boutton-segment ${
                          registerActive && "buttoni"
                        }`}
                      >
                        <IonLabel
                          style={{ color: "white", fontWeight: "bold" }}
                        >
                          Profil
                        </IonLabel>
                      </IonSegmentButton>
                    </div>
                    <div className="val1">
                      <IonSegmentButton
                        value="depenses"
                        className={`boutton-segment ${
                          registerActive === 0 && "buttoni"
                        }`}
                        onClick={() => {
                          setRegisterActive(0)
                        }}
                      >
                        <IonLabel
                          style={{ color: "white", fontWeight: "bold" }}
                        >
                          Entreprise
                        </IonLabel>
                      </IonSegmentButton>
                    </div>
                    <div className="val2">
                      <IonSegmentButton
                        value="bilan"
                        className={`boutton-segment ${
                          registerActive === null && "buttoni"
                        }`}
                        onClick={() => {
                          setRegisterActive(null)
                        }}
                      >
                        <IonLabel
                          style={{ color: "white", fontWeight: "bold" }}
                        >
                          Utilisateur
                        </IonLabel>
                      </IonSegmentButton>
                    </div>
                  </IonSegment>
                </IonCol>
                <IonRow>
                  <IonCol sizemd={12} className="ion-text-start">
                    <IonRow>{handleAffichage()}</IonRow>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  )
}

export default ParametrePage
