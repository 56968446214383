import React from "react";
import * as yup from "yup";
export const useFormData = (formData) => {
  const [data, setData] = React.useState(formData);
  const onDataChange = React.useCallback((fieldName, value) => {
    if (typeof fieldName === "object") {
      setData({ ...data, ...fieldName });
      return;
    }
    if (fieldName === null) {
      setData(value);
      return;
    }
    let keys = Object.keys(data);
    if (keys.includes(fieldName)) {
      let newdata = { ...data };
      newdata[fieldName] = value;
      setData(newdata);
    } else {
      throw new Error(fieldName + " is not a valid key");
    }
  });
  return [data, onDataChange];
};

export const useFormValidation = (initalErrors, cbRules) => {
  const [errors, setErrors] = React.useState(initalErrors);
  const Schema = yup.object().shape(cbRules(yup));
  const validate = React.useCallback((data) => {
    return new Promise((resolve, reject) => {
      setErrors({});
      Schema.validate(data, { abortEarly: false })
        .then(() => {
          resolve(data);
        })
        .catch(function (err) {
          let newErrors = {};
          for (let e of err.inner) {
            newErrors[e.path] = e.errors[0];
          }
          setErrors(newErrors);
          reject(newErrors);
        });
    });
  });
  return [errors, validate, setErrors];
};
