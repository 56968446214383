import React, { useEffect, useState } from "react"

import { Link, useHistory } from "react-router-dom"
import {
  callOutline,
  lockClosedOutline,
  mailOutline,
  personOutline,
} from "ionicons/icons"
import { IonCol, IonContent, IonRow } from "@ionic/react"
import axios from "axios"

import { COLORS } from "../../utils/constants"
import appName from "../../images/appName.png"
import Input from "../../shared/Input"
import { useFormData } from "../../shared/Form"
import { AppButton2 } from "../../shared/AppButton"
import { ApiUrl } from "../../utils/http/http"
import toast, { Toaster } from "react-hot-toast"
import "../../app.css"

const Register = (props) => {
  useEffect(() => {
    document.title = "Tayeur-Inscription"
  })
  const history = useHistory()
  const initialState = {
    nom_complet: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    user_emp_id: parseInt(0),
  }
  const [data, setData] = useFormData(initialState)
  const [loading, setLoading] = React.useState(false)

  let [errors, setErrors] = useState({})

  const [NomCompletHasError, setNomCompletHasError] = useState(false)
  const [EmailHasError, setEmailHasError] = useState(false)
  const [PhoneHasError, setPhoneHasError] = useState(false)
  const [PasswordHasError, setPasswordHasError] = useState(false)
  const [PasswordConfirmHasError, setPasswordConfirmHasError] = useState(false)

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  const onValidateForm = (inputData) => {
    var isValidForm = true
    let errors = {}

    if (!inputData["nom_complet"]) {
      isValidForm = false
      setNomCompletHasError(true)
      errors["nom_complet"] = "Ce champ est requis"
      setTimeout(() => {
        setNomCompletHasError(false)
      }, 1000)
    }

    if (!inputData["email"]) {
      isValidForm = false
      setEmailHasError(true)
      errors["email"] = "Veillez renseigner un email valide"
      setTimeout(() => {
        setEmailHasError(false)
      }, 1000)
    } 

    if (!inputData["phone"]) {
      isValidForm = false
      setPhoneHasError(true)
      errors["phone"] = "Ce champ est requis"
      setTimeout(() => {
        setPhoneHasError(false)
      }, 1000)
    }else if (inputData["phone"].length !== 9){
      isValidForm = false
      setPhoneHasError(true)
      errors["phone"] = "le numero de telephone doit contenir 9 chiffres"
      setTimeout(()  => {
        setPhoneHasError(false)
      },1000
      )
    }

    if (!inputData["password"]) {
      isValidForm = false
      setPasswordHasError(true)
      errors["password"] = "Veillez verifier votre mot de passe"
      setTimeout(() => {
        setPasswordHasError(false)
      }, 1000)
    }

    if (!inputData["confirmPassword"]) {
      isValidForm = false
      setPasswordConfirmHasError(true)
      errors["confirmPassword"] = "Veillez verifier votre mot de passe"
      setTimeout(() => {
        setPasswordConfirmHasError(false)
      }, 1000)
    }

    setErrors(errors)
    return isValidForm
  }

  const handleSubmit = () => {
    if (onValidateForm(data)) {
      setLoading(true)
      axios
        .post(ApiUrl + "/registration/", data)
        .then((response) => {
          console.log(response.data)
          setLoading(false)
          setTimeout(() => {
            setLoading(false)
            history.push("/login")
          }, 1000)
          toast.success(
            "Votre inscription a ete bien prise en compte. Vous serez redirige dans 5 secondes ..."
          )
        })
        .catch((error) => {
          console.log(error)
          if (
            error.response.data.email[0] &&
            error.response.data.email[0] ===
              "user with this email already exists."
          ) {
            toast.error(
              "Votre e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail pour créer un compte ou vous connecter."
            )
            this.setState({
              registerInProgress: false,
              isExistUser: true,
            })
            setTimeout(() => {
              this.setState({
                isExistUser: false,
              })
            }, 1000)
          }
          toast.error(
            "Votre e-mail est déjà associé à un compte! Veuillez utiliser une autre adresse e-mail pour créer un compte ou vous connecter."
          )
        })
    }
  }

  return (
    <IonContent
      style={{
        backGround: COLORS.app,
        padding: 0,
      }}
    >
      <IonRow>
        <IonCol
          size={12}
          sizeMd={6}
          className="loginLeft d-flex  align-items-center justify-content-center"
        >
          <img src={appName} alt="Tayeur" />
        </IonCol>
        <IonCol
          size={12}
          sizeMd={6}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <h1
            style={{
              size: "24px",
              lineHeight: "29.6px",
              fontWeight: 600,
              textAlign: "center",
            }}
            className="mb-3"
          >
            Inscription
          </h1>
          <div className="d-block mt-2">
            <Input
              icon={personOutline}
              placeholder="Nom Complet"
              type="text"
              value={data.nom_complet}
              onIonChange={handleChange}
              id="nom_complet"
            />
            {NomCompletHasError && (
              <div className="input-error-feedback">{errors.nom_complet}</div>
            )}
            <Input
              icon={mailOutline}
              placeholder="Email"
              type="email"
              value={data.email}
              onIonChange={handleChange}
              id="email"
            />
            {EmailHasError && (
              <div className="input-error-feedback">{errors.email}</div>
            )}

            <Input
              icon={callOutline}
              placeholder="Telephone"
              type="text"
              value={data.phone}
              onIonChange={handleChange}
              id="phone"
              
            />
            {PhoneHasError && (
              <div className="input-error-feedback">{errors.phone}</div>
            )}
            <Input
              icon={lockClosedOutline}
              placeholder="Mot de passe"
              type="password"
              value={data.password}
              onIonChange={handleChange}
              id="password"
            />
            {PasswordHasError && (
              <div className="input-error-feedback">{errors.password}</div>
            )}
            <Input
              icon={lockClosedOutline}
              placeholder="Confirmer le mot de passe"
              type="password"
              value={data.confirmPassword}
              onIonChange={handleChange}
              id="confirmPassword"
            />
            {PasswordConfirmHasError && (
              <div className="input-error-feedback">
                {errors.confirmPassword}
              </div>
            )}
          </div>
          <div className="loginLeftContain">
            <AppButton2
              title="S'inscrire"
              onClick={handleSubmit}
              // loading={loading}
            />
            <Toaster />
            <div
              className="d-flex justify-content-end mt-4"
              style={{
                fontWeight: 400,
                fontSize: "14px",
                lineHeight: "17.",
              }}
            >
              <span className="mr-1">Vous avez déjà un compte?</span>
              <Link to="login" className="" style={{ color: COLORS.orange }}>
                Se Connecter
              </Link>
            </div>
          </div>
        </IonCol>
      </IonRow>
    </IonContent>
  )
}

export default Register
