import {
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
  IonSearchbar,
} from "@ionic/react"
import { cartOutline } from "ionicons/icons"
import { Button } from "react-bootstrap"
import { COLORS } from "../../utils/constants"
import Panier from "./panierModal"

function Vente() {
  const columns = ["Modèles", "Prix ", "Quantité", "Total ", "Actions"]

  return (
    <>
      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "40px 0",
        }}
      >
        <IonRow className="ion-align-items-center">
          <IonCol
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <IonLabel
              style={{
                color: "rgba(0, 0, 0, 0.6)",
                margin: 10,
              }}
            >
              Filtre
            </IonLabel>
            <IonSearchbar
              value=""
              showCancelButton="never"
              showSearchIcon="never"
              placeholder="Produit"
              style={{
                "--border-radius": "27.67px",
                "--box-shadow": "none",
                "--background": "#F7F7F7",
                marginBottom: "5px",
                width: "50%",
              }}
            ></IonSearchbar>
          </IonCol>

          <IonCol className="d-flex ion-justify-content-around">
            <Panier />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          padding: "0",
        }}
      >
        <IonRow
          className="ion-align-items-center"
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
            position: "sticky",
            background: "#F8F8F8",
          }}
        >
          {columns.map((column, index) => (
            <IonCol
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              key={index}
            >
              {column}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
        }}
        className="myContent"
      >
        {/* {.map((entry, index) => ( */}
        <IonRow
          key={""}
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Soie duchesse</IonCol>
          <IonCol>3 000 f</IonCol>
          <IonCol>100</IonCol>
          <IonCol>300 000 f</IonCol>
          <IonCol>
            <Button className="bouttonStatut">Ajouter au panier</Button>
          </IonCol>
        </IonRow>{" "}
        <IonRow
          key={""}
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Soie duchesse</IonCol>
          <IonCol>3 000 f</IonCol>
          <IonCol>100</IonCol>
          <IonCol>300 000 f</IonCol>
          <IonCol>
            <Button className="bouttonStatut">Ajouter au panier</Button>
          </IonCol>
        </IonRow>{" "}
        <IonRow
          key={""}
          style={{
            padding: "15px 0 15px 30px",
            borderBottom: "1px solid #F5F5F5",
          }}
          className="ion-align-items-center"
        >
          <IonCol>Soie duchesse</IonCol>
          <IonCol>3 000 f</IonCol>
          <IonCol>100</IonCol>
          <IonCol>300 000 f</IonCol>
          <IonCol>
            <Button className="bouttonStatut">Ajouter au panier</Button>
          </IonCol>
        </IonRow>
        {/* ))} */}
      </IonGrid>
    </>
  )
}

export default Vente
