import {
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ApiUrl } from "../../utils/http/http"

const ClientCard = () => {
  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const [count, setCount] = useState(0)

  useEffect(() => {
    axios({
      method: "GET",
      url: ApiUrl + "/clients/" + userInfos.id_boutique,
      count,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const commands = res.data.count
      setCount(commands)
    })
  })

  return (
    <IonCard className="cartes-dashboard ion-justify-content-center ion-align-items-center">
      <Link to="/dashboard/clients" className="lien-detail-commande">
        <IonCardContent className="d-flex ion-justify-content-between ion-align-items-start data">
          <svg
            width="63"
            height="63"
            viewBox="0 0 63 63"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M44.625 55.125V49.875C44.625 47.0902 43.5188 44.4195 41.5496 42.4504C39.5805 40.4812 36.9098 39.375 34.125 39.375H13.125C10.3402 39.375 7.66951 40.4812 5.70038 42.4504C3.73125 44.4195 2.625 47.0902 2.625 49.875V55.125"
              stroke="#E82828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M23.625 28.875C29.424 28.875 34.125 24.174 34.125 18.375C34.125 12.576 29.424 7.875 23.625 7.875C17.826 7.875 13.125 12.576 13.125 18.375C13.125 24.174 17.826 28.875 23.625 28.875Z"
              stroke="#E82828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M60.375 55.1251V49.8751C60.3733 47.5486 59.5989 45.2886 58.1736 43.4499C56.7482 41.6112 54.7526 40.2979 52.5 39.7163"
              stroke="#E82828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M42 8.21631C44.2586 8.7946 46.2605 10.1081 47.6901 11.9499C49.1196 13.7916 49.8956 16.0567 49.8956 18.3882C49.8956 20.7196 49.1196 22.9848 47.6901 24.8265C46.2605 26.6682 44.2586 27.9818 42 28.5601"
              stroke="#E82828"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div>
            <IonCardSubtitle className="titre-carte">Clients</IonCardSubtitle>
            <IonCardTitle className="title-card">{count}</IonCardTitle>
          </div>
        </IonCardContent>
      </Link>
    </IonCard>
  )
}

export default ClientCard
