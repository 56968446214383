import React, { useEffect, useState } from "react"
import { Link, Redirect, Route, Switch, NavLink } from "react-router-dom"
import ProtectedRoute from "./routes/ProtectedRoutes"
import {
  IonApp,
  IonRouterOutlet,
  IonMenu,
  IonContent,
  IonItem,
  IonList,
  IonLabel,
  IonSplitPane,
  IonIcon,
  IonButtons,
  IonButton,
  IonMenuToggle,
  IonListHeader,
  IonSearchbar,
  useIonPopover,
} from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"
import Intro from "./pages/Intro"
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"
import "./app.css"
/* Theme variables */
import "./theme/variables.css"

// AUTH
import Login from "./pages/auth/Login"
import Register from "./pages/auth/Register"
import ForgotPasswword from "./pages/auth/ForgotPassword"
import { useHistory } from "react-router-dom"
// ICONS
import homeIcon from "./images/home.svg"
import cartIcon from "./images/cart.svg"
import userIcon from "./images/users.svg"
import documentIcon from "./images/document.svg"
import cropIcon from "./images/crop.svg"
import routes from "./routes/myRoutes"
import {
  menu,
  notificationsOutline,
  searchOutline,
  settingsOutline,
  logOutOutline,
} from "ionicons/icons"
import appLogo from "./images/appLogo.png"
import { CloseButton, Form, Modal } from "react-bootstrap"
// CONSTANTS
import { COLORS } from "./utils/constants"
import { AppButton2, ValidateButton } from "./shared/AppButton"
import DropzoneComponent from "./shared/Dropzone"
import toast, { Toaster } from "react-hot-toast"
import axios from "axios"
import { ApiUrl } from "./utils/http/http"
import store from "./store"
// import { Provider } from "react-redux"

const App = () => (
  // <Provider store={store}>
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Switch>
          <Route exact path="/welcome" component={Intro} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forgot-password" component={ForgotPasswword} />
          <Route path="/dashboard" component={AppNav} />
          <Redirect from="/" to="/login" />
          <Redirect to="/not-found" />
        </Switch>
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
  // </Provider>
)

export default App

const NotificationList = ({ onHide }) => (
  <IonList>
    <IonListHeader>Tailleur</IonListHeader>
    <IonItem button>Lorem Ipsum</IonItem>
    <IonItem button>Lorem Ipsum</IonItem>
    <IonItem button>Lorem Ipsum</IonItem>
    <IonItem button>Lorem Ipsum</IonItem>
    <IonItem lines="none" detail={false} button onClick={onHide}>
      Retour
    </IonItem>
  </IonList>
)

const doLogout = () => {
  window.localStorage.removeItem("token")
  window.location = "/"
}

function AppNav() {
  const [searchText, setSearchText] = useState("")
  const history = useHistory()
  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const [isAtelierExist, setIsAtelierExist] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const showAlert = useState(true)
  let [errors, setErrors] = useState({})
  const [NomBoutiqueHasError, setNomBoutiqueHasError] = useState(false)
  const [PhoneHasError, setPhoneHasError] = useState(false)
  const [AddressHasError, setAddressHasError] = useState(false)
  const [EmailHasError, setEmailHasError] = useState(false)

  const initialState = {
    nom_boutique: "",
    phone: "",
    email: "",
    web: "",
    pays: "",
    ville: "",
    adress: "",
    user: userInfos.id,
  }
   console.log("userInfo", userInfos)
  const [data, setState] = useState(initialState)
  const [loading, setLoading] = React.useState(false)
  // const [isAtelierExist, setIsAtelierExist] = useState(false)
  
  const [nomBoutiqueHasError] = useState(false)
  const [phoneHasError] = useState(false)
  const [emailHasError] = useState(false)
  const [addressHasError] = useState(false)
  
  const [] = useState({})
  
  const handleChange = (e) => {
    setState({ ...data, [e.target.name]: e.target.value })
  }
  
  const openSetting = () => {
    history.push("/dashboard/parametre-page")
  }
  
  const [present, dismiss] = useIonPopover(NotificationList, {
    onHide: () => dismiss(),
  })
  
  useEffect(() => {
    checkIfAtelierExist(userInfos)
  }, [userInfos])
  
  const checkIfAtelierExist = (userData) => {
    if (Number.isInteger(userData.id_boutique) || Number.isInteger(userData.id)) {
      setIsAtelierExist(false)
    }
  }
  
  const onOpenModal = (e) => {
    
    e.preventDefault()
    if (openModal === false) {
      setOpenModal(true)
    } else {
      setOpenModal(false)
    }
    // console(onOpenModal())
  }
  
  
  const onValidateForm = (inputData) => {
    let isValidForm = true
    let errors = {}
  
    if (!inputData.nom_boutique) {
      isValidForm = false
      setNomBoutiqueHasError(true)
      errors.nom_boutique = "Ce champ est requis"
    }
  
    if (!inputData.phone) {
      isValidForm = false
      setPhoneHasError(true)
      errors.phone = "Ce champ est requis"
    }
  
    if (!inputData.email) {
      isValidForm = false
      setEmailHasError(true)
      errors.email = "Veillez renseigner un email valide"
    }
  
    if (!inputData.adress) {
      isValidForm = false
      setAddressHasError(true)
      errors.adress = "Ce champ est requis"
    }
  
    setErrors(errors)
    return isValidForm
  }
  
  const handleSubmit = () => {
    if (onValidateForm(data)) {
      setLoading(true)
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
      if (isAtelierExist) { // Vérifie si l'ID de l'entreprise existe déjà
        axios
          .post(ApiUrl + "/entreprises/", data, config)
          .then((response) => {
            toast.success("Votre entreprise a été créée avec succès.")
            setLoading(false)
            userInfos.id_boutique = response.data.id
            localStorage.setItem("userInfos", JSON.stringify(userInfos))
            setIsAtelierExist(true)
          })
          .catch((error) => {
            console.log(error)
            setLoading(false)
          })
      } else {
        setIsAtelierExist(true)
      }
    }
  }
  

  return (
    <IonContent>
      {isAtelierExist && (
        <div
          className="dimmerContainer"
          style={{
            position: "absolute",
            background: "#00000090",
            width: "100%",
            zIndex: "9999",
            height: "100vh",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IonIcon
              icon={logOutOutline}
              expand="block"
              onClick={doLogout}
              style={{
                fontSize: "40px",
                color: "#fff",
                background: "#E82828",
                marginRight: "10%",
                marginTop: 30,
                alignItems: " center",
                cursor: "pointer",
              }}
            />
          </div>
          <div
            className="button"
            style={{
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              display: "flex",
              flexDirection: "column",
              marginTop: "20%",
            }}
          >
            {!openModal ? (
              <>
                <label
                  style={{
                    color: "#FFFFFF",
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  Vous n'avez pas encore créé votre entreprise !
                </label>
                <AppButton2
                  style={{
                    background: "#E82828",
                  }}
                  title="Créer votre entreprise "
                  onClick={(e) => onOpenModal(e)}
                />{" "}
                {/* console.log(onOpenModal) */}
              </>
            ) : (
              <Modal show={showAlert} centered>
                <Modal.Header
                  center
                  style={{
                    background: " #E82828",
                  }}
                >
                  <IonIcon
                    slot="start"
                    icon={homeIcon}
                    style={{
                      fontSize: "24px",
                      alignSelf: "center",
                      marginRight: 5,
                    }}
                    className="modalHomeIcon"
                  ></IonIcon>
                  <Modal.Title
                    style={{
                      fontSize: 18,
                      color: "#FFFFFF",
                    }}
                  >
                    Entreprise
                  </Modal.Title>
                  <CloseButton
                    style={{
                      color: "#FFF",
                      borderRadius: "100%",
                      border: "3px solid #FFF",
                      padding: 0,
                      width: 28,
                      marginRight: 5,
                      alignSelf: "center",
                      opacity: 5,
                    }}
                  />
                </Modal.Header>
                <Modal.Body>
                  <Form.Group
                    sizemd={12}
                    size={12}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <DropzoneComponent />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="labelModal">
                      Nom de l'entreprise
                    </Form.Label>
                    <Form.Control
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        border: "1px solid #DADADA",
                        marginBottom: 10,
                      }}
                      type="text"
                      name="nom_boutique"
                      placeholder="Tayeur"
                      onChange={handleChange}
                      error={errors.nom_boutique}
                    />
                    {NomBoutiqueHasError && (
                      <div className="input-error-feedback">
                        {errors.nom_boutique}
                      </div>
                    )}
                    <Form.Label> Téléphone </Form.Label>
                    <Form.Control
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        border: "1px solid #DADADA",
                        marginBottom: 10,
                      }}
                      type="number"
                      name="phone"
                      placeholder="Téléphone"
                      onChange={handleChange}
                      error={errors.phone}
                    />
                    {PhoneHasError && (
                      <div className="input-error-feedback">{errors.phone}</div>
                    )}
                    <Form.Label className="labelModal">
                      Courrier électronique
                    </Form.Label>
                    <Form.Control
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        border: "1px solid #DADADA",
                        marginBottom: 10,
                      }}
                      type="email"
                      placeholder="tayeur@gmail.com"
                      name="email"
                      onChange={handleChange}
                      error={errors.email}
                    />
                    {EmailHasError && (
                      <div className="input-error-feedback">{errors.email}</div>
                    )}
                    <Form.Label className="labelModal">Adresse </Form.Label>
                    <Form.Control
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        border: "1px solid #DADADA",
                        marginBottom: 10,
                      }}
                      type="text"
                      placeholder="Adresse"
                      name="adress"
                      onChange={handleChange}
                      error={errors.adress}
                    />
                    {AddressHasError && (
                      <div className="input-error-feedback">
                        {" "}
                        {errors.adress}{" "}
                      </div>
                    )}
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                  <ValidateButton
                    title="Enregistrer "
                    loading={loading}
                    onClick={handleSubmit}
                  />
                </Modal.Footer>
              </Modal>
            )}
          </div>
        </div>
      )}
      <IonSplitPane when="lg" contentId="main-content" className="en-tete">
        <IonMenu
          contentId="main-content"
          className="sidebar"
          style={{ "--side-width": "300px" }}
        >
          <IonContent>
            <IonList lines="none" className="menu-items">
              <div className="d-flex justify-content-center mt-4 mb-5">
                <Link to="/dashboard">
                  <img src={appLogo} className="img-logo" alt="tayeur" />
                </Link>
              </div>
              <IonMenuToggle autoHide={false}>
                <NavLink
                  exact
                  to="/dashboard"
                  style={{
                    textDecoration: "none",
                  }}
                  activeClassName="activated"
                  className="d-block links"
                >
                  <IonItem button className="menu-nav mb-4 my-item">
                    <IonIcon
                      slot="start"
                      icon={homeIcon}
                      style={{
                        fontSize: "24px",
                      }}
                      className="my-icon"
                    ></IonIcon>
                    <IonLabel className="label">Tableau de bord</IonLabel>
                  </IonItem>
                </NavLink>

                <NavLink
                  exact
                  to="/dashboard/commande"
                  style={{
                    textDecoration: "none",
                  }}
                  activeClassName="activated"
                  className="d-block"
                >
                  <IonItem button className="menu-nav mb-4 my-item">
                    <IonIcon
                      slot="start"
                      icon={cartIcon}
                      style={{
                        fontSize: "24px",
                      }}
                      className="my-icon"
                    ></IonIcon>
                    <IonLabel className="label">Commandes</IonLabel>
                  </IonItem>
                </NavLink>
                <NavLink
                  to="/dashboard/clients"
                  style={{
                    textDecoration: "none",
                  }}
                  activeClassName="activated"
                  className="d-block link"
                >
                  <IonItem button className="menu-nav mb-4 my-item">
                    <IonIcon
                      slot="start"
                      icon={userIcon}
                      style={{
                        fontSize: "24px",
                      }}
                      className="my-icon"
                    ></IonIcon>
                    <IonLabel className="label">Clients</IonLabel>
                  </IonItem>
                </NavLink>
                <NavLink
                  exact
                  to="/dashboard/comptabilite"
                  style={{
                    textDecoration: "none",
                  }}
                  activeClassName="activated"
                  className="d-block links"
                >
                  <IonItem button className="menu-nav mb-4 my-item">
                    <IonIcon
                      slot="start"
                      icon={documentIcon}
                      style={{
                        fontSize: "24px",
                      }}
                      className="my-icon"
                    ></IonIcon>
                    <IonLabel className="label">Comptabilité</IonLabel>
                  </IonItem>
                </NavLink>
                <NavLink
                  exact
                  to="/dashboard/stock"
                  style={{
                    textDecoration: "none",
                  }}
                  activeClassName="activated"
                  className="d-block links"
                >
                  <IonItem button className="menu-nav mb-4 my-item">
                    <IonIcon
                      slot="start"
                      icon={documentIcon}
                      style={{
                        fontSize: "24px",
                      }}
                      className="my-icon"
                    ></IonIcon>
                    <IonLabel className="label">Stock</IonLabel>
                  </IonItem>
                </NavLink>
                <NavLink
                  exact
                  to="/dashboard/employes"
                  style={{
                    textDecoration: "none",
                  }}
                  activeClassName="activated"
                  className="d-block links"
                >
                  <IonItem button className="menu-nav mb-4 my-item">
                    <IonIcon
                      slot="start"
                      icon={documentIcon}
                      style={{
                        fontSize: "24px",
                      }}
                      className="my-icon"
                    ></IonIcon>
                    <IonLabel className="label">Employés</IonLabel>
                  </IonItem>
                </NavLink>

                <NavLink
                  exact
                  to="/dashboard/modeles"
                  style={{
                    textDecoration: "none",
                  }}
                  activeClassName="activated"
                  className="d-block"
                >
                  <IonItem button className="menu-nav mb-4 my-item">
                    <IonIcon
                      slot="start"
                      icon={cropIcon}
                      style={{
                        fontSize: "24px",
                      }}
                      className="my-icon"
                    ></IonIcon>
                    <IonLabel className="label">Galerie</IonLabel>
                  </IonItem>
                </NavLink>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        </IonMenu>

        <div className="ion-page pt-4" id="main-content">
          <IonButtons
            slot="start"
            className="justify-content-between ion-padding-horizontal"
          >
            <IonMenuToggle>
              <IonButton>
                <IonIcon slot="icon-only" icon={menu}></IonIcon>
              </IonButton>
            </IonMenuToggle>
            <IonSearchbar
              value={searchText}
              onChange={(e) => setSearchText(e.detail.value)}
              showCancelButton="never"
              inputMode="search"
              placeholder="Rechercher"
              searchIcon={searchOutline}
              style={{
                width: "415px",
                height: "42px",
                "--border-radius": "27.67px",
                "--box-shadow": "none",
                marginBottom: "5px",
              }}
            ></IonSearchbar>
            <div className="d-flex align-items-center">
              <div
                className="d-flex justify-content-center align-items-center mr-3 position-relative"
                style={{
                  width: "42px",
                  height: "42px",
                  border: "solid 1px",
                  borderRadius: "50%",
                  borderColor: COLORS.grayS,
                  cursor: "pointer",
                }}
              >
                <IonIcon
                  icon={notificationsOutline}
                  expand="block"
                  onClick={(e) =>
                    present({
                      event: e.nativeEvent,
                    })
                  }
                  style={{
                    fontSize: "20px",
                    color: COLORS.grayS,
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: COLORS.redS,
                    top: 0,
                    right: 0,
                  }}
                ></div>
              </div>
              <div
                className="d-flex justify-content-center align-items-center mr-3"
                style={{
                  width: "42px",
                  height: "42px",
                  border: "solid 1px",
                  borderRadius: "50%",
                  borderColor: COLORS.grayS,
                  cursor: "pointer",
                }}
              >
                <IonIcon
                  icon={settingsOutline}
                  expand="block"
                  onClick={openSetting}
                  style={{
                    fontSize: "20px",
                    color: COLORS.grayS,
                  }}
                />
              </div>
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: "42px",
                  height: "42px",
                  border: "solid 1px",
                  borderRadius: "50%",
                  borderColor: COLORS.grayS,
                  cursor: "pointer",
                }}
              >
                <IonIcon
                  icon={logOutOutline}
                  expand="block"
                  onClick={doLogout}
                  style={{
                    fontSize: "20px",
                    color: COLORS.grayS,
                  }}
                />
              </div>
            </div>
          </IonButtons>
          <IonContent className="ion-padding">
            <IonRouterOutlet>
              <Switch>
                {/* My custom routes */}
                {routes.map(({ path, Component }, i) => (
                  <ProtectedRoute
                    key={i}
                    exact
                    path={path}
                    component={Component}
                  />
                ))}
              </Switch>
            </IonRouterOutlet>
          </IonContent>
        </div>
      </IonSplitPane>
      <Toaster />
    </IonContent>
  )
}
