import React, { useEffect, useState } from "react"
import {
  IonCol,
  IonRow,
  IonIcon,
  IonRadio,
  IonLabel,
  IonItem,
  IonListHeader,
  IonRadioGroup,
} from "@ionic/react"

import userIcon from "../images/users.svg"
import { CloseButton, Col, Form, Modal } from "react-bootstrap"
import { ValidateButton } from "../shared/AppButton"
import { useFormData } from "../shared/Form"
import Input from "../shared/Input"
import axios from "axios"
import { ApiUrl } from "../utils/http/http"
import toast, { Toaster } from "react-hot-toast"

const Modals = (props) => {
  const initialState = {
    nom_complet: "",
    phone: "",
    adress: "",
    email: "",
    pays: "",
    ville: "",
    poitrine: "",
    autre: "",
    epaule: "",
    longueur_manche: "",
    cou: "",
    ceinture: "",
    tour_bras: "",
    hanche: "",
    cuisse: "",
    longueur_haut: "",
    longueur_pantalon: "",
    longueur_boubou: "",
    longueur_jupe: "",
    longueur_poitrine: "",
    taille: "",
    bretelle: "",
    longueur_robe: "",
    sexe: "",
    shop: "",
  }

  let [errors, setErrors] = useState({})

  const [NomClientHasError, setNomClientHasError] = useState(false)

  let userInfos = JSON.parse(localStorage.getItem("userInfos"))

  const [data, setData] = useFormData(initialState)
  const [showModal, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [loading, setLoading] = React.useState(false)
  const [status, setStatus] = useState(0)
  const [disabled, setDisabled] = useState(0)
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  useEffect(() => {
    document.title = "Tayeur-Clients"
    localStorage.getItem("token")
    localStorage.getItem("id")
  }, [])

  const statusCheck = (status) => {
    setStatus(status)
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value })
    console.log(e.target.value)
  }

  const onValidateForm = (inputData) => {
    var isValidForm = true
    let errors = {}
    if (!inputData["nom_complet"]) {
      isValidForm = false
      setNomClientHasError(true)
      errors["nom_complet"] = "Ce champ est requis"
      setTimeout(() => {
        setNomClientHasError(false)
      }, 5000)
    }
    setErrors(errors)
    return isValidForm
  }

  const getClients = async () => {
    if (onValidateForm(data)) {
      setLoading(true)
      const shop = userInfos.id_boutique
      data.shop = shop
      axios
        .post(ApiUrl + "/clients/", data, config)
        .then((response) => {
          if (props.onAddClient) {
            props.onAddClient(response.data)
          }
          setLoading(false)
          toast.success("Votre client a ete ajoute avec succes.")
          props.handleClose()
        })
        .catch((error) => {
          console.log(error?.response)
          toast.error(
            "Votre procedure d'ajout client a echoue. Verifiez bien les informations dans le formulaire."
          )
        })
    }
  }

  return (
    <>
      <Modal
        size="lg"
        show={props.showModal}
        onHide={props.handleClose}
        onSubmit={props.handleSubmit}
      >
        <Modal.Header
          style={{
            background: " #E82828",
          }}
        >
          <IonIcon
            slot="start"
            icon={userIcon}
            style={{
              fontSize: "24px",
              alignSelf: "center",
              marginRight: 5,
            }}
            className="modalUserIcon"
          ></IonIcon>
          <Modal.Title
            style={{
              fontSize: 18,
              color: "#FFFFFF",
            }}
          >
            Ajouter un client
          </Modal.Title>
          <CloseButton
            style={{
              color: "#FFF",
              borderRadius: 100,
              border: "3px solid #FFF",
              padding: 0,
              width: 28,
              marginRight: 5,
              alignSelf: "center",
              opacity: 5,
            }}
            onClick={handleClose}
          />
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#F0F0F0",
          }}
        >
          <Form.Group
            sizemd={12}
            size={12}
            className="d-flex justify-content-between align-items-center"
          >
            <Form.Row>
              <Form.Group
                as={Col}
                style={{
                  background: "#FFFFFF",
                  margin: 10,
                  borderRadius: 5,
                  padding: 20,
                  height: "fit-content",
                }}
              >
                <h2
                  style={{
                    color: "#979797",
                    fontSize: 16,
                    fontWeight: "bold",
                    lineHeight: 2,
                  }}
                >
                  Informations du client
                </h2>
                <Form.Label className="labelModal">Nom Complet</Form.Label>
                <Form.Control
                  style={{
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    marginBottom: 10,
                    height: "50px",
                    border: "1px solid #797979",
                  }}
                  type="text"
                  id="nom_complet"
                  name="nom_complet"
                  placeholder="Tayeur"
                  onChange={handleChange}
                  error={errors.nom_complet}
                />
                {NomClientHasError && (
                  <div className="input-error-feedback">
                    {errors.nom_complet}
                  </div>
                )}

                <Form.Label> Téléphone </Form.Label>
                <Form.Control
                  style={{
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    marginBottom: 10,
                    height: "50px",
                    border: "1px solid #797979",
                  }}
                  type="number"
                  placeholder="Téléphone"
                  id="phone"
                  name="phone"
                  onChange={handleChange}
                  error={errors.phone}
                />

                <Form.Label className="labelModal">E-mail</Form.Label>
                <Form.Control
                  style={{
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    marginBottom: 10,
                    height: "50px",
                    border: "1px solid #797979",
                  }}
                  type="email"
                  placeholder="tayeur@gmail.com"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  error={errors.email}
                />

                <Form.Label className="labelModal">Adresse </Form.Label>
                <Form.Control
                  style={{
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    marginBottom: 10,
                    height: "50px",
                    border: "1px solid #797979",
                  }}
                  type="text"
                  placeholder="Adresse"
                  name="adress"
                  id="adress"
                  onChange={handleChange}
                  error={errors.adress}
                />

                <Form.Row className="row">
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Pays
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="text"
                      name="pays"
                      id="pays"
                      placeholder="Pays"
                      onChange={handleChange}
                      error={errors.pays}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label position="floating" className="labelModal">
                      Ville
                    </Form.Label>
                    <Form.Control
                      style={{
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        marginBottom: 10,
                        height: "50px",
                        border: "1px solid #797979",
                      }}
                      type="text"
                      name="ville"
                      id="ville"
                      placeholder="Ville"
                      onChange={handleChange}
                      error={errors.ville}
                    />
                  </Form.Group>
                </Form.Row>

                <IonRadioGroup
                  id="autre"
                  name="autre"
                  value={data.autre}
                  onIonChange={handleChange}
                  error={errors.sexe}
                >
                  <IonRow>
                    <IonCol size={12} sizeSm={3}>
                      <IonListHeader>
                        <IonLabel
                          style={{
                            fontWeight: 500,
                            fontStyle: "normal",
                            fontSize: "14px",
                            lineHeight: "17.07px",
                          }}
                        >
                          Sexe:
                        </IonLabel>
                      </IonListHeader>
                    </IonCol>
                    <IonCol size={6} sizeSm={4}>
                      <IonItem
                        lines="none"
                        style={{
                          padding: 0,
                        }}
                      >
                        <IonLabel
                          style={{
                            fontWeight: 500,
                            fontStyle: "normal",
                            fontSize: "14px",
                            lineHeight: "17.07px",
                          }}
                        >
                          Homme
                        </IonLabel>
                        <IonRadio
                          slot="start"
                          value="homme"
                          checked={status === 1}
                          onClick={(e) => {
                            statusCheck(1)
                            setDisabled(1)
                          }}
                        />
                      </IonItem>
                    </IonCol>

                    <IonCol size={6} sizeSm={4}>
                      <IonItem lines="none">
                        <IonLabel
                          style={{
                            fontWeight: 500,
                            fontStyle: "normal",
                            fontSize: "14px",
                            lineHeight: "17.07px",
                          }}
                        >
                          Femme
                        </IonLabel>
                        <IonRadio
                          slot="start"
                          value="femme"
                          checked={status === 2}
                          onClick={(e) => {
                            statusCheck(2)
                            setDisabled(1)
                          }}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </IonRadioGroup>
              </Form.Group>
              <Form.Group
                sizemd={12}
                size={12}
                className="justify-content-between align-start"
                as={Col}
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#FFFFFF",
                  margin: 10,
                  padding: 20,
                  borderRadius: 5,
                }}
              >
                <Input
                  label="Epaule:"
                  type="number"
                  min="0"
                  id="epaule"
                  name="epaule"
                  value={data.epaule}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    width: 100,
                    border: "1px solid #797979",
                  }}
                />
                <Input
                  label="Poitrine:"
                  type="number"
                  min="0"
                  id="poitrine"
                  name="poitrine"
                  value={data.poitrine}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    width: 100,
                    border: "1px solid #797979",
                  }}
                />
                <Input
                  label="Manche:"
                  type="number"
                  min="0"
                  id="longueur_manche"
                  name="longueur_manche"
                  value={data.longueur_manche}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    background: "#FFF",
                    width: 100,
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />
                <Input
                  label="Cou:"
                  type="number"
                  min="0"
                  id="cou"
                  name="cou"
                  value={data.cou}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    width: 100,
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />
                <Input
                  label="Ceinture:"
                  type="number"
                  min="0"
                  id="ceinture"
                  name="ceinture"
                  value={data.ceinture}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    width: 100,
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />
                <Input
                  label="Tours de bras:"
                  type="number"
                  min="0"
                  id="tour_bras"
                  name="tour_bras"
                  value={data.tour_bras}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    width: 100,
                    background: "#FFF",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />
                <Input
                  label="Tours de hanches:"
                  type="number"
                  min="0"
                  id="hanche"
                  name="hanche"
                  value={data.hanche}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    width: 100,
                    background: "#FFF",
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />

                <Input
                  label="Tours de cuisses"
                  type="number"
                  min="0"
                  id="cuisse"
                  name="cuisse"
                  value={data.cuisse}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    background: "#FFF",
                    width: 100,
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />
                <Input
                  label="Longueur haut:"
                  type="number"
                  min="0"
                  id="longueur_haut"
                  name="longueur_haut"
                  value={data.longueur_haut}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    background: "#FFF",
                    width: 100,
                    outline: "none",
                    border: "1px solid #797979",
                  }}
                />

                <Input
                  label="Longueur bas:"
                  type="number"
                  min="0"
                  id="longueur_pantalon"
                  name="longueur_pantalon"
                  value={data.longueur_pantalon}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    width: 100,
                    border: "1px solid #797979",
                  }}
                />
                <Input
                  label="Longueur boubou:"
                  type="number"
                  min="0"
                  id="longueur_boubou"
                  name="longueur_boubou"
                  value={data.longueur_boubou}
                  onIonChange={handleChange}
                  disabled={disabled === 0}
                  className={disabled === 0 ? "inputActive" : "flex-fill"}
                  style={{
                    height: "50px",
                    borderRadius: "5px",
                    background: "#FFF",
                    outline: "none",
                    width: 100,
                    border: "1px solid #797979",
                  }}
                />
                {status === 1 && null}
                {status === 2 && (
                  <div>
                    <Input
                      label="Longueur jupe:"
                      type="number"
                      min="0"
                      id="longueur_jupe"
                      name="longueur_jupe"
                      value={data.longueur_jupe}
                      onIonChange={handleChange}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        width: 100,
                        border: "1px solid #797979",
                      }}
                    />
                    <Input
                      label="Poitrine:"
                      type="number"
                      min="0"
                      id="longueur_poitrine"
                      name="longueur_poitrine"
                      value={data.longueur_poitrine}
                      onIonChange={handleChange}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        width: 100,
                        border: "1px solid #797979",
                      }}
                    />
                    <Input
                      label="Tour de taille:"
                      type="number"
                      min="0"
                      id="taille"
                      name="taille"
                      value={data.taille}
                      onIonChange={handleChange}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        width: 100,
                        border: "1px solid #797979",
                      }}
                    />
                    <Input
                      label="Longueur bras:"
                      type="number"
                      min="0"
                      id="bretelle"
                      name="bretelle"
                      value={data.bretelle}
                      onIonChange={handleChange}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        width: 100,
                        border: "1px solid #797979",
                      }}
                    />
                    <Input
                      label="Longueur robe:"
                      type="number"
                      min="0"
                      id="longueur_robe"
                      name="longueur_robe"
                      value={data.longueur_robe}
                      onIonChange={handleChange}
                      style={{
                        height: "50px",
                        borderRadius: "5px",
                        background: "#FFF",
                        outline: "none",
                        width: 100,
                        border: "1px solid #797979",
                      }}
                    />
                  </div>
                )}
              </Form.Group>
            </Form.Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#F0F0F0",
          }}
          className="d-flex justify-content-center"
        >
          <ValidateButton
            title="Enregistrer "
            loading={loading}
            onClick={() => getClients()}
          />
          <Toaster />
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Modals
