import { useEffect, useState } from "react"
import {
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonRow,
} from "@ionic/react"
import { createOutline, eye, trashOutline, walletOutline } from "ionicons/icons"
import { useHistory } from "react-router-dom"
import moment from "moment"
import ButtonI from "../shared/Button"
import { COLORS } from "../utils/constants"
import axios from "axios"
import { ApiUrl } from "../utils/http/http"
import { Modal } from "react-bootstrap"
import Swal from "sweetalert2"
import TableLoader from "../skeleton/ItemSkeleton"

const Commandes = () => {
  const History = useHistory()
  let userInfos = JSON.parse(localStorage.getItem("userInfos"))
  const [loading, setLoading] = useState(true)
  const [commandes, setCommandes] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [clients, setClients] = useState([])
  const [listecommandess,setListecommandess] = useState(false)
  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  const fetchCommandes = () => {
    axios
      .get(`${ApiUrl}/commandes/` + userInfos.id_boutique + "/", config)
      .then((response) => {
        setLoading(false)
        setCommandes(response.data.data)
        // console.log(commandes)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }
  useEffect(() => {
    document.title = "Tayeur-Commandes"
    fetchCommandes()
    fetchClients()
  }, [])

  const onDeleteCommande = (id) => {
    axios
      .delete(`${ApiUrl}/commandes/` + id + "/", config)
      .then((response) => {
        // console.log(response)
      })
      .catch((error) => console.log(error))
  }
  const fetchClients = () => {
    const config = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    }
    axios
      .get(`${ApiUrl}/clients/`, config)
      .then((response) => {
          // console.log(response.data.data)
        setClients(response.data.data)
        // console.log(clients)
        
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const afficheNomClient = (idclient)=>{
    //  console.log(idclient)
  let valeur = clients.filter((a)=> a.id==idclient)
   let nomclient= valeur.map((e)=>e.nom_complet)
   return nomclient;
  }

  const handleRemove = (id) => {
    Swal.fire({
      title: "Vous êtes sûr de vouloir supprimer cet élément ?",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: `Non`,
      confirmButtonColor: "#2ECC71",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      iconColor: "#F1C40F",
      showConfirmButton: true,
      allowOutsideClick: false,
      timer: 3000,
    }).then((result) => {
      if (result.isConfirmed) {
        setCommandes(commandes.filter((commande) => commande.id !== id))
      }
    })
    onDeleteCommande(id)
  }

  const handleClose = () => {
    setShowModal(!showModal)
  }

  const columns = [
    "Clients",
    "Date de Livraison",
    "Montant",
    "Restant",
    "Actions",
  ]

const[query,setQuery]=useState('')
 
 const Searche = (datas)=>{

 let nomclient = datas.filter((item) =>
  item.nom_complet.toLowerCase().includes(query.toLowerCase()));
   let idclient =  nomclient.map((item)=>item.id)
   return idclient;
 }

 const search=(dataclient,commandes)=>{
 if (dataclient.length>1) {
  return (commandes.filter((item) => item.client))
 }
 else if (dataclient.length<=2) {
  return (commandes.filter((item) => item.client==dataclient))
 } 
 }

 var dataclient = Searche(clients) 
 console.log(dataclient)
  var dataCommande = search(dataclient,commandes)
    console.log(dataCommande)

  const openAddCommande = () => {
    History.push("/dashboard/add-commande")
  }

  return (
    <IonContent
      style={{
        padding: 0,
        "--background": COLORS.gray,
        position: "relative",
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol
            size={12}
            style={{
              padding: "30px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h6 className="tableCaption">Liste des commandes</h6>
            <ButtonI label="Ajouter une commande" onClick={openAddCommande} />
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
        }}
      >
        <IonRow className="ion-align-self-end ion-justify-content-end">
          <IonCol size="3">
            <IonInput
              placeholder="Rechercher..."
              onIonChange={(e)=>setQuery(e.target.value)}
      
              style={{
                border: "1px solid #C4C4C4",
                boxSizing: "border-box",
                borderRadius: "5px",
                marginTop: "20px",              
              }}
            ></IonInput>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid
        style={{
          margin: "0 10px",
          background: "#FFFFFF",
          position: "relative",
        }}
      >
        <IonRow
          style={{
            padding: "10px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.5)",
            boxSizing: "border-box",
          }}
        >
          {columns.map((column, index) => (
            <IonCol
              style={{
                fontWeight: "600",
                fontSize: "14px",
                lineHeight: "17px",
              }}
              key={index}
            >
              {column}
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
      {loading && <TableLoader />}
      {dataCommande.map((commande, index) => (
        <IonRow key={index} style={{ padding: "10px 30px" }}>
          <IonCol>
          {afficheNomClient(commande.client)}
           </IonCol>
          <IonCol>
            {moment(commande.date_livraison).format("DD-MM-YYYY")}
          </IonCol>
          <IonCol>
            {Intl.NumberFormat("fr-FR").format(
              parseInt(`${commande.montant} f`)
            )}
          </IonCol>
          <IonCol>
            {Intl.NumberFormat("fr-FR").format(
              parseInt(`${commande.restant} f`)
            )}
          </IonCol>
          <IonCol>
            <IonCol
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div className="col-3 pl-0">
                <div
                  className="cta-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    History.push("/dashboard/detail-commande/" + commande?.id)
                  }
                >
                  <IonIcon
                    icon={eye}
                    style={{
                      color: COLORS.redS,
                      fontSize: "24px",
                    }}
                    className="ionIcon"
                  />
                </div>
                <span
                  className="tableLink"
                  style={{
                    color: COLORS.redS,
                  }}
                >
                  Details
                </span>
              </div>
              {/* <div className="col pl-0">
                <div className="cta-icon" style={{ cursor: "pointer" }}
                
                >
                  <IonIcon
                    icon={walletOutline}
                    style={{
                      color: COLORS.greenP,
                      fontSize: "24px",
                    }}
                    className="ionIcon"
                  />
                </div>
                <div
                  className="tableLink"
                  style={{
                    color: COLORS.greenP,
                  }}
                >
                  Paiement
                </div>
              </div> */}

              <div className="col pl-0">
                <div
                  className="cta-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    History.push("/dashboard/edit-commande/" + commande?.id)
                  }
                >
                  <IonIcon
                    icon={createOutline}
                    style={{
                      color: COLORS.grayD,
                      fontSize: "24px",
                    }}
                    className="ionIcon"
                  />
                </div>
                <span
                  className="tableLink"
                  style={{
                    color: COLORS.grayD,
                  }}
                >
                  Modifier
                </span>
                <Modal showModal={showModal} handleClose={handleClose} />
              </div>

              <div className="col pl-0">
                <div
                  className="cta-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleRemove(commande.id)}
                >
                  <IonIcon
                    icon={trashOutline}
                    style={{
                      color: COLORS.redS,
                      fontSize: "24px",
                      opacity: 5,
                    }}
                    className="ionIcon"
                  />
                </div>
                <span
                  className="tableLink"
                  style={{
                    color: COLORS.redS,
                  }}
                >
                  Supprimer
                </span>
              </div>
            </IonCol>
          </IonCol>
        </IonRow>
      ))}
    </IonContent>
  )
}

export default Commandes
