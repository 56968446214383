import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDropzone } from "react-dropzone"

const baseStyle = {
  width: 462,
  height: 149,
  border: "3.41667px dashed rgba(0, 0, 0, 0.2)",
  boxSizing: " border-box",
  fontFamily: "Roboto",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: 14,
  lineHeight: 24,
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  color: "#0087CA",
  marginBottom: 20,
  padding: 50,
}

const activeStyle = {
  borderColor: "#2196f3",
}

const acceptStyle = {
  borderColor: "#00e676",
}

const rejectStyle = {
  borderColor: "#ff1744",
}

function DropzoneComponent(props) {
  const [files, setFiles] = useState([])

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    )
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <img style={{ width: 500 }} src={file.preview} alt={file.name} />
    </div>
  ))

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    },
    [files]
  )

  return (
    <section>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div
          style={{
            cursor: "pointer",
            width: "inherit",
            height: "inherit",
            left: 0,
            position: "absolute",
            right: 0,
            margin: "auto",
            display: "contents",
          }}
        >
          Glissez et déposez le logo de votre entreprise ici.
        </div>
      </div>
      <aside>{thumbs}</aside>
    </section>
  )
}

export default DropzoneComponent
