import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from "@ionic/react"
import axios from "axios"
import React, { useEffect } from "react"
import { useParams } from "react-router-dom"
import { ApiUrl } from "../../utils/http/http"
import "./commande.css"

function DetailPaiment() {
  const [data, setData] = React.useState({})
  const [detailCommand, setDetailCommand] = React.useState([])
  const params = useParams()
  const { id } = params

  useEffect(() => {
    fetchCommandes()
    console.log("first", params)
  }, [id])

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  }

  const fetchCommandes = () => {
    axios
      .get(`${ApiUrl}/commande/` + id + "/", config)
      .then((response) => {
        setData(response.data)
        setDetailCommand(response.data.commande_detail_cmd)
        // console.log("detail", detailCommand)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <IonGrid
        className="paiementContent"
        style={{
          position: "relative",
          margin: 20,
          borderRadius: 10,
          background: "#FFFFFF",
          boxShadow: "0px 4px 50px rgba(116, 130, 145, 0.1)",
        }}
      >
        <IonItem
          style={{
            color: "#666666",
          }}
          className=" mb-3"
        >
          Paiement
        </IonItem>
        <IonRow className="ion-align-items-center">
          <IonCol>
            <IonRow className="ion-justify-content-between mb-2 align-items-center">
              <IonLabel className="text-secondary">
                <b>Montant global</b>
              </IonLabel>
              <h2>
                <b>
                  {Intl.NumberFormat("fr-FR").format(
                    parseInt(`${data.net_payer} f`)
                  )}
                  Fcfa
                </b>
              </h2>
            </IonRow>
            <IonRow className="ion-justify-content-between mb-2 align-items-center">
              <IonLabel className="text-secondary">
                <b>Montant payé </b>
              </IonLabel>
              <h2>
                <b>
                  {Intl.NumberFormat("fr-FR").format(
                    parseInt(`${data.net_financier} f`)
                  )}
                  Fcfa
                </b>
              </h2>
            </IonRow>
            <IonRow className="ion-justify-content-between mb-2 align-items-center">
              <IonLabel className="text-secondary">
                <b>Restant</b>
              </IonLabel>
              <h2>
                <b>
                  {Intl.NumberFormat("fr-FR").format(
                    parseInt(`${data.restant} f`)
                  )}
                  Fcfa
                </b>
              </h2>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  )
}

export default DetailPaiment
