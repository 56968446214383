import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRow,
} from "@ionic/react"
import { walletOutline } from "ionicons/icons"
import { useState } from "react"
import { CloseButton, Col, Form, Modal } from "react-bootstrap"
import { COLORS } from "../../utils/constants"
import userIcon from "../../images/users.svg"

const Paiement = () => {
  const [show, setShow] = useState(false)

  const onCloseModal = () => {
    setShow(false)
  }

  return (
    <>
      <div className="cart align-items-end ">
        <div
          className="cta-icon"
          style={{
            cursor: "pointer",
          }}
          onClick={() => setShow(true)}
        >
          <IonIcon
            icon={walletOutline}
            style={{
              color: COLORS.greenP,
              fontSize: "24px",
            }}
            className="ionIcon"
          />
        </div>
        <span
          className="tableLink"
          style={{
            color: COLORS.redS,
          }}
        >
          Paiement
        </span>
      </div>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          style={{
            background: " #E82828",
          }}
        >
          <IonIcon
            slot="start"
            icon={userIcon}
            style={{
              fontSize: "24px",
              alignSelf: "center",
              marginRight: 5,
            }}
            className="modalUserIcon"
          ></IonIcon>
          <Modal.Title
            style={{
              fontSize: 18,
              color: "#FFFFFF",
            }}
          >
            Paiement salaire
          </Modal.Title>
          <CloseButton
            style={{
              color: "#FFF",
              borderRadius: "100%",
              border: "3px solid #FFF",
              padding: 0,
              width: 28,
              marginRight: 5,
              alignSelf: "center",
              opacity: 5,
            }}
            onClick={onCloseModal}
          />
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: "#F0F0F0",
          }}
        >
          <Form.Group
            style={{
              background: "#FFFFFF",
              borderRadius: 5,
            }}
          >
            <IonGrid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IonRow
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                }}
              >
                <IonLabel>Date </IonLabel>
                <IonInput
                  placeholder="Nom modèle"
                  type="date"
                  style={{
                    height: 45,
                    width: 375,
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                    background: "#E9ECF3 ",
                  }}
                ></IonInput>
              </IonRow>
            </IonGrid>
            <IonGrid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IonCol>
                <IonLabel>Nom et Prenom</IonLabel>
                <IonInput
                  placeholder="Modou Fall"
                  style={{
                    height: 45,
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                    background: "#E9ECF3 ",
                  }}
                ></IonInput>
              </IonCol>{" "}
              <IonCol>
                <IonLabel>Mensualité</IonLabel>
                <IonInput
                  placeholder="Montant"
                  style={{
                    height: 45,
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                    background: "#E9ECF3 ",
                  }}
                ></IonInput>
              </IonCol>
            </IonGrid>{" "}
            <IonGrid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IonCol
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <IonLabel>Mois</IonLabel>
                <select
                  style={{
                    width: 365,
                    height: 45,
                    overflow: "auto",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid #C4C4C4",
                    borderRadius: 5,
                  }}
                  className="form-select"
                  aria-label="Default select example"
                >
                  <option selected>salaire</option>
                  <option value="1">salaire </option>
                  <option value="1">salaire </option>
                  <option value="1">salaire </option>
                  <option value="1">salaire </option>
                </select>
              </IonCol>
              <IonCol>
                <IonLabel>Montant du </IonLabel>
                <IonInput
                  placeholder="salaire"
                  style={{
                    height: 45,
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                    background: "#E9ECF3 ",
                  }}
                ></IonInput>
              </IonCol>
            </IonGrid>
            <IonGrid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IonCol>
                <IonLabel>Montant à versé</IonLabel>
              </IonCol>
            </IonGrid>
            <IonGrid
              className="inputQuantite"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IonRow
                className="QuatiteInput"
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <IonInput
                  placeholder="00 000 000 000"
                  style={{
                    height: 45,
                    width: "50%",
                    borderRadius: "5px",
                    outline: "none",
                    border: "1px solid #C4C4C4",
                  }}
                ></IonInput>
              </IonRow>
            </IonGrid>
            <IonGrid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IonCol>
                <IonLabel>Statut</IonLabel>
              </IonCol>
            </IonGrid>
            <IonGrid
              className="inputQuantite"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <IonRow
                className="QuatiteInput"
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <IonCol
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    flexDirection: "comlumn",
                  }}
                >
                  <select
                    style={{
                      width: 230,
                      height: 45,
                      overflow: "auto",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #C4C4C4",
                      borderRadius: 5,
                    }}
                    className="form-select"
                    aria-label="Default select example"
                  >
                    <option selected>Payé</option>
                    <option value="1">Payé </option>
                    <option value="1">Payé </option>
                    <option value="1">Payé </option>
                    <option value="1">Payé </option>
                  </select>
                </IonCol>
              </IonRow>
            </IonGrid>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer style={{ background: "#F0F0F0" }}>
          <IonGrid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IonButton>Enregistrer</IonButton>
          </IonGrid>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Paiement
